<!--
  @author yanzi
  @date 2023/5/26
  @Description: 创建IR题目，题目模板代码展示编辑器
-->
<template>
  <div class="tinymce-box">
    <textarea :id="tinymceId"></textarea>
  </div>
</template>

<script>
export default {
  name: 'TemplateEditor',
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      contentValue: this.value,
      editor: null,
      tinymceId: this.id,
    };
  },
  watch: {
    value(newValue) {
      this.contentValue = newValue;
      if (this.editor && this.editor.getContent() !== newValue) {
        this.editor.setContent(newValue);
      }
    },
    contentValue(newValue) {
      if (this.editor && this.editor.getContent() !== newValue) {
        this.editor.setContent(newValue);
      }
      this.$emit('input', newValue);
    }
  },
  mounted() {
    this.initializeEditor();
  },
  beforeDestroy() {
    this.destroyEditor();
  },
  methods:  {
    initializeEditor() {
      tinymce.init({
        selector: `#${this.tinymceId}`,
        height: this.height,
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        menubar: false,
        fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        plugins: [
          'advlist anchor autolink autosave code codesample paste'
        ],
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        setup: editor => {
          this.editor = editor;
          editor.on('Change', () => {
            this.contentValue = editor.getContent();
          });
          editor.on('init', () => {
            editor.setContent(this.contentValue);
          });
        }
      });
    },
    destroyEditor() {
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }
    }

  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
