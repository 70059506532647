<!--
  @author yanzi
  @date 2023/8/4
  @Description: Master用户列表
-->
<template>
  <div>
    <div>
      <div class="header-top1">
        <div class="header-left">
          <div class="option">
            搜索：
            <Input v-model="queryDataForm.keywords" placeholder="请输入内容" style="width: 240px" />
          </div>
        </div>
        <div style="display: flex">
          <Button type="primary" @click="search" style="margin-right: 10px">查询</Button>
          <Button type="default" @click="refresh">重置</Button>
        </div>
      </div>
      <div class="header-top2">
        <Button shape="circle" type="primary" @click="addUser" style="margin-right: 10px">新建用户</Button>
      </div>
    </div>

    <Table
        class="studentListTable"
        border style="margin-top: 15px;"
        highlight-row
        :loading="loading"
        :columns="columns"
        :data="dataObject">
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>


  </div>
</template>

<script>
export default {
  name: "MsUserList",
  data () {
    return {
      queryDataForm: {
        keywords: null, // 关键词
      },
      // 数据
      dataObject: [],
      loading: false,
      columns: [
        {
          title: '姓名',
          key: 'nickName',
          minWidth: 100,
          align: 'center',
        },
        {
          title: '手机号',
          key: 'phone',
          minWidth: 140,
          align: 'center',
        },
        {
          title: '邮箱',
          key: 'email',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '性别',
          key: 'gender',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '本科学校',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '本科专业',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '申请国家',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '申请专业',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: 'GPA',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '雅思/托福',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: 'GMAT/GRE',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 360,
          align: 'center',
          render: (h,params) => {
            let isFreeze = params.row.isFreeze;
            let show = isFreeze === 0 ? this.freezeArr[0].label : this.freezeArr[1].label;
            let freezeId =  isFreeze === 0 ? this.freezeArr[1].isFreeze : this.freezeArr[0].isFreeze;
            return h('div', [
                  h('Button', {
                    style: {
                      marginRight: '5px',
                      marginBottom: '5px'
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                      disabled: (params.row.isFreeze !== 0)
                    },
                    on: {
                      click: () => {
                        this.$router.push({
                          path: "/student/self/StudentInfo/StudentAddOrEdit",
                        });
                        this.$storage.set('StudentAddOrEdit', 'id', params.row.id);
                        this.$storage.set('StudentInfo', 'page', this.pageData.page);
                      }
                    }
                  }, '编辑'),
                  h('Button', {
                    style: {
                      marginRight: '5px',
                      marginBottom: '5px'
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                    },
                    on: {
                      click: () => {
                        this.$router.push({
                          path:`/student/self/StudentInfo/StudentDetail`,
                        });
                        this.$storage.set('StudentDetail', 'id', params.row.id);
                        this.$storage.set('StudentInfo', 'page', this.pageData.page);
                      }
                    }
                  }, '详情'),
                  h('Button', {
                    style: {
                      marginRight: '5px',
                      marginBottom: '5px'
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                      ghost: true,
                    },
                    on: {
                      click: () => {
                        this.handleFreeze(params.row.id, freezeId, show);
                      }
                    }
                  }, show),
                  h('Button', {
                    style: {
                      marginRight: '5px',
                      marginBottom: '5px'
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                    },
                    on: {
                      click: () => {
                        this.selectStdName = params.row.nickName;
                        this.currentStdId = params.row.id;
                        this.passwordModel = true;
                      },
                    }
                  }, '重置密码'),
                ],
            );
          }
        }
      ],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
    }
  },
  methods: {
    // 获取用户列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
        sort: this.pageData.prop + ',' + this.pageData.direction,
      }
    },
    // 新增用户
    addUser() {

    },
    // 查询
    search() {

      let queryDataForm = {
        keywords: this.queryDataForm.keywords,
      };
      console.log(queryDataForm);
      this.query(this.pageData.page, this.pageData.size, queryDataForm)
    },
    // 重置
    refresh() {
      this.dateTime = '';
      this.queryDataForm = {
        keywords: null
      }
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  }
}
</script>

<style scoped>
.header-top1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.header .header-top1 .header-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-left .option{
  margin-right: 10px;
  display: flex;
  align-items: center;
}
</style>
