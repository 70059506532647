import Vue from 'vue'
import App from './App'
import router from './router/index'
import ueboot from 'ueboot'
/* ueboot封装过的基于iview的自定义样式，也可以引入自己的自定义样式 */
import 'ueboot/lib/ueboot.css'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
// import {get, post} from "./config/AxiosConfig";
import AxiosNew from './config/AxiosNew'
import moment from 'moment';
import storage from './util/LocalStorage';
// import TEditor from './components/TEditor/TEditor';
import lodash from 'lodash'

Vue.use(ViewUI);
Vue.use(ueboot);

Vue.prototype.$axios = AxiosNew;

Vue.prototype.$moment = moment;
Vue.prototype.$storage = storage;
// Vue.component('TEditor', TEditor);
Vue.prototype.$lodash = lodash;


/* 设置登录后的主界面相关配置 */
ueboot.Config.setConfig({
    logoImage: 'https://xieyin.oss-cn-qingdao.aliyuncs.com/img/logo.png',
    sysTitle: '后台管理系统',
    page_login: {
        theme: 'theme2',
        logoStyle: 'width:100px;height:100px;margin-bottom:100px;',
        // 登录成功后的跳转路径
        successRouter: { path: '/' }
    },
    page_main: {
        logoStyle: {
            width: '300px'
        },
        logoImageStyle: {
            width: "50px",
            height: "50px"
        },
        menuWidth: 250,
        logoutSuccessRouter: { name: 'login' }
    },
    axios: {baseURL: '/backend', unauthorizedUrl: process.env.CONTEXT_HTML + '/#/login'}
    // axios: {baseURL: process.env.CONTEXT, unauthorizedUrl: process.env.CONTEXT_HTML + '/#/login'}
});
Vue.config.productionTip = false;


const app = new Vue({
    el: '#app',
    router,
    components: {App},
    template: '<App/>'
});
console.log(app !== null);
