<!--
  @author yanzi
  @date 2022/9/13
  @Description: 套题 选择题目
-->
<template>
  <div>
    <Form :model="formParams" ref="ruleValidate" :rules="ruleValidate">
      <FormItem prop="chooseTopic">
        <Button type="primary" @click="handleOpenModal">点击选择</Button>
        <Table
            class="checkListTable"
            style="margin: 20px 0;"
            :columns="columns"
            :loading="loading"
            :data="newDataList"
            draggable
            @on-drag-drop="changeOrder"
        >
        </Table>
      </FormItem>

      <FormItem>
        <Button type="primary" @click="handleSubmit">确定</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>

    <relevance-topic-modal
        :isShow="relevanceTopicModal"
        :allCheckData="newDataList"
        @on-refresh="refresh"
        @isShow="isShow"
        @check-data="handleCheckData"
    >
    </relevance-topic-modal>
  </div>
</template>

<script>
import RelevanceTopicModal from "./RelevanceTopicModal";
export default {
  name: "CheckTopic",
  components: {
    'relevance-topic-modal': RelevanceTopicModal
  },
  data() {
    return {
      // 套题id
      id: this.$storage.get('ExamAddOrEdit', 'id'),
      formParams: {},
      // 表单验证
      ruleValidate: {},
      loading: false,
      dataList: [],
      newDataList: [],
      newDataIds: [],
      relevanceTopicModal: false,
      columns: [
        {
          title: '编号',
          type: 'index',
          minWidth: 80,
          align: 'center',
        },
        {
          title: 'ID',
          key: 'id',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '题目',
          key: 'topicTitle',
          minWidth: 460,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            let content = params.row.topicTitle
            return h('Poptip', {
              props: {
                trigger: 'hover',
                placement: 'top',
                wordWrap: true,
              }
            }, [
              h('div', {
                domProps: {
                  innerHTML: content
                },
                style: {
                  minWidth: '400px',
                  maxHeight: '48px',
                  display: '-webkit-box',
                  webkitLineClamp: 1,
                  webkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  margin: '0 auto',
                }
              }),
              h('div', {
                slot: 'content',
              }, [
                h('div', {
                  domProps: {
                    innerHTML: content
                  },
                  style: {
                    width: '360px',
                    maxHeight: '500px',
                    wordWrap: 'break-word',
                    wordBreak: 'normal'
                  }
                })
              ])
            ])
          },

          props: {
            level: {
              type: Number,
              require: true
            }
          }
        },
        {
          title: '科目',
          key: 'subject',
          width: 80,
          align: 'center',
        },
        {
          title: '考点',
          key: 'examinationSite',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '来源',
          key: 'topicSource',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '难度',
          key: 'difficulty',
          minWidth: 160,
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 180,
          align: 'center',
          fixed: 'right',
          render: (h,params) => {
            return h('div',[
                  h('Button', {
                    style: {
                      margin: '5px 5px 5px 0',
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                      ghost: true,
                    },
                    on: {
                      click: () => {

                        this.$Modal.confirm({
                          title: '提示',
                          content: '<p>确定删除该道题目吗？</p>',
                          onOk: () => {
                            // // 删除已选择的题目 已有id走删除接口 无id不走接口，删除本地数据
                            // this.$lodash.remove(this.dataList, n => {
                            //   return n.id === params.row.id
                            // })

                            this.dataList.map(item => {
                              if (item.id === params.row.id) {
                                console.log('id存在')
                                this.$axios.get(`/api/book/topic/delete/${this.id}/${params.row.id}`).then(res => {
                                  if (res.code === '10000') {
                                    this.$Message.success('删除成功')
                                  }
                                }).catch(error => {
                                  console.log('error====>', error)
                                })
                              }
                            })

                            console.log('id不存在')
                            this.newDataList = this.newDataList.filter(item => {
                              return item.id !== params.row.id
                            })
                            this.newDataIds = this.newDataList.map(item => {
                              return item.id
                            })
                            console.log('this.newDataIds====>', this.newDataIds)


                          },

                          onCancel: () => {
                            this.$Message.warning("您已经取消了删除");
                          }
                        })

                      },
                    }
                  }, '删除')
                ],
            );
          }
        }
      ]
    }
  },
  mounted() {
    this.queryBindTopic()
  },
  methods: {

    queryBindTopic() {
      this.loading = true
      let url = `/api/book/topic/find/topic/${this.id}`;
      this.$axios.post(url, {}, {})
          .then(res => {
            console.log('res===>', res)
            if (res.code === '10000') {
              this.dataList = res.body
              this.newDataList = res.body
              this.newDataIds = this.newDataList.map(item => {
                return item.id
              })
              this.loading = false
            }
          })
          .catch(error => {
            console.log('error===>', error)
            if (error.code === '10000') {
              this.dataList = []
              this.newDataList = []
              this.loading = false
            }
          })

    },

    // 确定
    handleSubmit() {

      let url = '/api/book/topic/bind';
      console.log('this.formParams.category', this.formParams.category);
      let requestVo = {
        bookId: this.id,
        topicIds: this.newDataIds,// 题目id集合
      };
      console.log('requestVo===>', requestVo)

      this.$refs.ruleValidate.validate(valid => {
        if (valid) {

          this.$axios.post(url, requestVo, {})
              .then(res => {
                if (res.code === '10000') {
                  this.$Message.success('保存成功');
                  localStorage.setItem('isRemove', JSON.stringify(true));
                  this.$router.push({ path:`/content/exam/ExamList` });
                  this.$storage.remove('ExamAddOrEdit', 'id')
                }
              })
              .catch(error => {
                this.$Message.error('保存失败');
              })

        } else {
          return false;
        }

      })


    },
    // 取消
    handleReset() {
      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$storage.remove('ExamAddOrEdit', 'id')
      this.$router.push({ path:`/content/exam/ExamList` });
    },
    handleOpenModal() {
      this.relevanceTopicModal = true;
      console.log('newDataList====>', this.newDataList)
    },
    changeOrder(oldIndex, newIndex) {
      console.log('oldIndex ====>', oldIndex)
      console.log('newIndex ====>', newIndex)
      oldIndex = parseInt(oldIndex)
      newIndex = parseInt(newIndex)
      let oldData = this.newDataList[oldIndex]
      this.newDataList.splice(oldIndex, 1, this.newDataList[newIndex])
      this.newDataList.splice(newIndex, 1, oldData)
      console.log('this.dateList=====>', this.newDataList)

      this.newDataIds = this.newDataList.map(item => {
        return item.id
      })
      console.log('newDataIds======>', this.newDataIds)

      // let tmpData = []
      // let order = -1
      // let reqData = []
      //
      // this.dataList.forEach((item, index) => {
      //   order = index
      //   tmpData.push(item)
      // })
      // tmpData.splice(order + 1, 0)
      // tmpData.forEach((item, index) => {
      //   let obj = Object.assign({}, item)
      //   obj.distributionOrder = index + 1
      //   reqData.push(obj)
      // })
      // this.dataList = reqData
      // console.log('tmpData====>', tmpData)
      // console.log('reqData====>', reqData)
    },
    refresh() {
      this.queryDataForm = {
        keywords: null,
      };
      // this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    isShow(val) {
      this.relevanceTopicModal = val;
    },
    handleCheckData(data) {
      console.log('data=====>', data)
      // this.dataList.push(...data)
      this.newDataList = data
      this.newDataIds = this.newDataList.map(item => {
        return item.id
      })
      console.log('data====>new newDataIds====>', this.newDataList)
      console.log('data====>new newDataIds====>', this.newDataIds)
    },
  }
}
</script>

<style scoped>
.checkListTable{
  overflow: initial;
}
</style>
<style>
.checkListTable .ivu-poptip {
  display: block;
}

.checkListTable .ivu-poptip-rel {
  display: block;
}
</style>
