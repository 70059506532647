<template>
<!-- 官网学员角色管理 -->
  <div class="student-role-manage">
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div>
        <Button type="primary" @click="handleAdd">新建角色</Button>
        <!--      <Button style="margin-left: 10px" type="primary" ghost @click="handleDelete">删除</Button>-->
      </div>
      <div style="display: flex;">
        <Input style="width: 300px; margin-right: 10px" v-model="queryDataForm.keywords"
               search enter-button="搜索" placeholder="请输入角色名称"
               @on-search="handleSearch"
        />
        <Button type="default" @click="handleRefresh">重置</Button>
      </div>
    </div>
    <Table
        border style="margin-top: 15px;"
        class="studentRoleTable"
        highlight-row
        :loading="loading"
        ref="selection"
        :columns="columns"
        :data="roleTableData"
        @on-selection-change="onSelectionChangeTable"
    >
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

    <!--  添加学员角色的Modal  -->
    <Modal
        class-name="studentRoleModal"
        title="学员角色管理"
        v-model="studentRoleModal"
    >
      <Form ref="ruleValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
        <FormItem label="角色名称" prop="roleName">
          <Input v-model="formValidate.roleName" placeholder="请输入角色名称"></Input>
        </FormItem>
        <FormItem label="角色描述" prop="description">
          <Input v-model="formValidate.description" placeholder="请输入角色描述"></Input>
        </FormItem>
        <FormItem label="是否启用" prop="isDisable">
          <Select v-model="formValidate.isDisable.toString()" placeholder="请选择是否启用">
            <Option value="0">启用</Option>
            <Option value="1">禁用</Option>
          </Select>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="default" @click="cancelStudentRole">取消</Button>
        <Button type="primary" @click="okStudentRole('ruleValidate')">确定</Button>
      </div>
    </Modal>

    <!-- 权限Modal -->
    <Modal
        class-name="studentPermissionModal"
        v-model="studentPermissionModal"
    >
      <div>
        <p slot="header" style="font-size: 16px; color: #17233d; font-weight: bold;">
          请注意，您当前正在对<span style="color:red; vertical-align: top;">{{ selectRoleName }}</span>角色进行授权操作！
        </p>
        <div style="margin-top: 10px;">
          <CheckboxGroup
              v-model="selectListData"
              style="display: flex; flex-direction: column;"
              v-for="(item, index) in listData" :key="item.resourcesName"
              @on-change="handleChangeSelect"
          >
            <Checkbox :label="item.id">{{ item.resourcesName }}</Checkbox>
          </CheckboxGroup>

          <Spin v-if="loadingTree">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>加载中</div>
          </Spin>
        </div>
      </div>
      <div slot="footer">
        <Button type="default" @click="cancelStudentPermission">取消</Button>
        <Button type="primary" @click="okStudentPermission">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "StudentRoleManage",
  data() {
    return {
      formValidate: {
        roleName: '',
        description: '',
        isDisable: "0",
      },
      ruleValidate: {
        roleName: [
          { required: true, message: '角色名称为必填', trigger: 'blur' }
        ],
        isDisable: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      queryDataForm: {
        keywords: null
      },
      studentRoleModal: false,
      studentPermissionModal: false,
      selectRoleName: '',
      // 当前勾选的树数据
      selectTreeItem: null,
      loadingTree: false,
      // 树结构数据
      tree: [],
      treeOptions: {
        showCheckbox: true,
        multiple: true,
        allowBatch: true
      },
      loading: false,
      roleTableData: [],
      currentId: null,
      // 选择项
      selectArr: [],
      // 选中的项
      selectArrData: [],
      // 选中的角色ids 集合
      selectArrIds: [],
      columns: [
        // {
        //   title: '',
        //   type: 'selection',
        //   width: 60,
        //   align: 'center',
        // },
        {
          title: '编号',
          type: 'index',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '角色名称',
          key: 'roleName',
          minWidth: 160,
          align: 'center',
        },
        {
          title: '角色描述',
          key: 'description',
          minWidth: 160,
          align: 'center'
        },
        {
          title: '是否启用',
          key: 'available',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            return h('div', params.row.isDisable ? '禁用' : '启用');
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 260,
          align: 'center',
          render: (h, params) => {
            return h('div', [
                h('Button', {
                  style: {
                    marginRight: '5px'
                  },
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      let data = params.row.rolesIds ? params.row.rolesIds.split(',') : '';
                      let newData = [];
                      if (data.length > 0) {
                        data.map(item => {
                          newData.push(parseInt(item));
                        })
                      }
                      this.selectListData  = newData;
                      this.currentId = params.row.id;
                      this.studentPermissionModal = true;
                      this.queryPermissionTreeData();
                    }
                  }
                }, '授权'),
              h('Button', {
                style: {
                  marginRight: '5px'
                },
                props: {
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                },
                on: {
                  click: () => {
                    this.studentRoleModal = true;
                    this.currentId = params.row.id;
                    this.formValidate = params.row
                    this.formValidate.isDisable = params.row.isDisable.toString();
                  }
                }
              }, '编辑'),
              // h('Button', {
              //   style: {
              //     marginRight: '5px'
              //   },
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //     ghost: true,
              //   },
              //   on: {
              //     click: () => {
              //       let selectArrIds = [];
              //       selectArrIds.push(params.row.id)
              //       this.selectArrIds = selectArrIds;
              //       this.handleDelete();
              //     }
              //   }
              // }, '删除')
            ])
          }
        }
      ],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      listData: [],
      selectListData: []
    }
  },
  methods: {
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
      }
      this.$axios.post('/api/student/rolelist/page', requestVo, { params: pageObj }).then(res => {
        if (res.code === '10000') {
          console.log('res===>', res.body)
          this.roleTableData = res.body.content;
          this.pageData.total = res.body.totalElements;
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取所有tree数据 以及 每个角色所包含的权限数据
    queryPermissionTreeData() {

      this.$axios.post('/api/student/role/resources/list', {}).then(res => {
        if (res.code === '10000') {
          console.log('res ===>', res);
          this.listData = res.body;  // 后台获取到的数据
        }
      }).catch(() => {
        console.log('error===>')
      })

    },
    // 新建角色
    handleAdd() {
      this.studentRoleModal = true;
    },
    // 删除角色
    handleDelete() {

      //  没有选择角色时点击
      if(this.selectArrIds.length === 0) {
        this.$Message.warning('请选择学员');
      } else {

        // 删除角色
        this.$Modal.confirm({
          title: '提示',
          content: '确定要删除当前选择的角色吗',
          onOk: () => {
            console.log('select', this.selectArrIds);
            this.$axios.post(`/api/student/rolelist/delete?id=${this.selectArrIds}`, {}).then(res => {
              this.$Message.success('删除成功!');
              this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            }, (response) => {
              this.$Message.error('删除失败');
            }).catch(error => {
              console.log('error===>', error);
            });
          }
        });
      }

    },
    // 查询角色
    handleSearch() {
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },
    // 重置 清空搜索框的内容
    handleRefresh() {
      this.queryDataForm.keywords = null;
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },
    // 添加学员角色
    okStudentRole(name) {

      let formValidate = this.formValidate;
      let requestVo = {
        roleName: formValidate.roleName,
        description: formValidate.description,
        isDisable: parseInt(formValidate.isDisable),
      };
      let id = this.currentId;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      console.log('requestVo===>', requestVo);
      this.$refs[name].validate(valid => {
        if (valid) {
          this.$axios.post('/api/student/rolelist/handle', requestVo, {}).then(res => {
            if (res.code === '10000') {
              this.studentRoleModal = false;
              this.$Message.success('新建角色成功');
              this.formValidate = {
                roleName: "",
                description: "",
                isDisable: "0",
              }
              this.currentId = null;
              this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            }
            console.log('res===>', res)
          }).catch(() => {
            this.studentRoleModal = false;
            this.$Message.error('新建角色失败');
          })
        }
      })

    },
    // 学员角色弹出框 取消
    cancelStudentRole() {
      this.studentRoleModal = false;
      this.formValidate = {
        roleName: "",
        description: "",
        isDisable: "0",
      }
    },
    // 勾选的权限
    handleChangeSelect(data) {
      console.log('data===>', data)
      let rolesIds = data.toString();
      console.log('rolesIds===>', rolesIds);
    },
    // 确定分配此权限
    okStudentPermission() {

      let rolesIds = this.selectListData.toString();

      let requestVo = {
        id: this.currentId,
        rolesIds: rolesIds
      };
      console.log('rolesIds===>', rolesIds);

      this.$axios.post('/api/student/rolelist/authorization', requestVo, {}).then(res => {
        if (res.code === '10000') {
          this.$Message.success('授权成功！');
          this.selectListData = [];
          this.currentId = null;
          this.studentPermissionModal = false;
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
        }
      }).catch(error => {
        console.log('error===>', error)
      })

    },
    // 取消分配权限
    cancelStudentPermission() {
      this.studentPermissionModal = false;
      console.log(this.selectListData)
    },

    // 列表选择
    onSelectionChangeTable(selection) {

      let selectArrData = this.$refs.selection.getSelection();
      this.selectArrData = selectArrData;

      let selectStdId = [];
      selectArrData.forEach(item => {
        selectStdId.push(item.id)
      })
      this.selectArrIds = selectStdId;
      this.selectArr = selection
    },
    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  },
  mounted() {
    this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
  }

}
</script>

<style scoped>

</style>