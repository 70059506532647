import { render, staticRenderFns } from "./ExamRole.vue?vue&type=template&id=039bf15e&scoped=true&"
import script from "./ExamRole.vue?vue&type=script&lang=js&"
export * from "./ExamRole.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039bf15e",
  null
  
)

export default component.exports