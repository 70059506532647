<template>
<!-- 学员管理列表 -->
  <div>
    <header class="header">
      <div class="header-top1">
        <div class="header-left">
          <div class="option">
            搜索：
            <Input v-model="queryDataForm.keywords" placeholder="请输入内容" style="width: 200px" />
          </div>
          <div class="option">
            注册时间：
            <DatePicker type="datetimerange" format="yyyy-MM-dd HH:mm"
                        v-model="dateTime"
                        @on-change="dateChange"
                        placeholder="选择开始和结束日期时间" style="width: 300px"></DatePicker>
          </div>
          <div class="option">
            标签：
            <Select v-model="queryDataForm.ids" multiple style="width:200px" @on-change="selectLabel">
              <Option v-for="item in labelList" :value="item.id" :key="item.id">{{ item.labelName }}</Option>
            </Select>
          </div>
        </div>
        <div style="display: flex">
          <Button type="primary" @click="search" style="margin-right: 10px">查询</Button>
          <Button type="default" @click="refresh">重置</Button>
        </div>
      </div>
      <div class="header-top2">
        <Button shape="circle" type="primary" @click="addStudent" style="margin-right: 10px">新建学员</Button>
        <Button shape="circle" type="primary" @click="allotTeacher">分配老师</Button>
<!--        <Button type="default" @click="exportFn" style="float: right">导出</Button>-->
      </div>
    </header>

    <Table
        class="studentListTable"
        border style="margin-top: 15px;"
           highlight-row
           :loading="loading"
           @on-selection-change="onSelectionChangeTable"
            @on-sort-change="onSortChangeTable"
           ref="selection"
           :columns="columns"
           :data="dataObject">
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

    <!--    分配老师 Modal  -->
    <Modal
        class-name="allotModal"
        title="提示"
        v-model="allotModal"
        @on-ok="okAllotTeacher"
        @on-cancel="cancelAllotTeacher"
    >
      <div class="content">
        <p></p>
        <p>已选择 <span>{{ selectArrData.length }}</span>位学员 </p>
        <p>
          <span>老师：</span>
          <Select v-model="selectTeacherId" style="width:120px" @on-change="selectTeacherChange">
            <Option v-for="item in teacherList" :value="item.id" :key="item.id">{{ item.userName }}</Option>
          </Select>
        </p>
      </div>
    </Modal>

    <!--      打标签 Modal     -->
    <Modal
        class-name="checkModal"
        title="标签"
        v-model="stdAddLabel"
        @on-ok="okStdAddLabel"
        @on-cancel="cancelStdAddLabel">
      <CheckboxGroup ref="checkBoxGroup" v-model="checkLabelArr">
        <Tooltip v-for="item in labelList" :content="item.labelName" placement="top" :key="item.id">
          <Checkbox :label="item.id + ':' + item.labelName" border>{{ item.labelName }}</Checkbox>
        </Tooltip>
      </CheckboxGroup>
    </Modal>

    <!--  分配角色 Modal  -->
    <Modal
        width="600px"
        class-name="assignRoleModal"
        v-model="assignRoleModal"
    >
      <p slot="header" style="font-size: 16px; color: #17233d; font-weight: bold;">
        请注意，您当前正在对学员：<span style="color:red; vertical-align: top;">{{ selectStdName }}</span> 进行分配角色操作！
      </p>

      <Form :label-width="80" inline>
        <FormItem label="所属角色" prop="role" style="width: 220px">
          <Select v-model="roleId" placeholder="请选择所属角色" clearable :label-in-value="true" @on-change="handleSelectRole">
            <Option v-for="(item, index) in rolesList" :key="item.id" :value="item.id">{{ item.roleName }}</Option>
          </Select>
        </FormItem>
        <FormItem label="过期时间" prop="expiredTime">
          <DatePicker type="date" placeholder="请选择过期时间" v-model="expiredTime"></DatePicker>
        </FormItem>
        <Icon type="ios-add-circle" size="24" color="#2d8cf0" style="cursor: pointer;" @click="addRole" />
      </Form>

      <p v-for="item in selectRoleList" :key="item.roleName" class="selectRoles">
        <span>所属角色: {{ item.roleName }}</span>
        <span>过期时间: {{ item.expirationTime ? $moment(item.expirationTime).format('YYYY-MM-DD') : '无' }} </span>
        <Icon type="md-trash" size="24" color="#2d8cf0" style="cursor: pointer;" @click="delRole(item.id, item.roleName)" />
      </p>

      <div slot="footer">
        <Button type="default" @click="cancelStdAssignRole">取消</Button>
        <Button type="primary" @click="okStdAssignRole">确定</Button>
      </div>

    </Modal>

    <!--  重置密码 Modal  -->
    <Modal v-model="passwordModel" :mask-closable="false">
      <p slot="header" style="font-size: 16px; color: #17233d; font-weight: bold;">
        请注意，您当前正在对学员：<span style="color:red; vertical-align: top;">{{ selectStdName }}</span> 进行密码重置操作！
      </p>

      <Form ref="passwordForm" :model="pwdForm" :rules="pwdRule" :label-width="100">
        <Form-item label="新密码" prop="newPwd">
          <i-input type="password" v-model="pwdForm.newPwd" placeholder="请输入新密码"></i-input>
        </Form-item>
        <Form-item label="确认密码" prop="surePwd">
          <i-input type="password" v-model="pwdForm.surePwd" placeholder="确认密码"></i-input>
        </Form-item>
      </Form>
      <div slot="footer">
        <Button type="primary" @click="handlePasswordSubmit">提交</Button>
        <Button type="default" @click="passwordModel=false">取消</Button>
      </div>
    </Modal>

    <!--  分配套题  -->
    <Modal
        width="600px"
        class-name="assignRoleModal"
        v-model="assignExamModal"
    >
      <p slot="header" style="font-size: 16px; color: #17233d; font-weight: bold;">
        请注意，您当前正在对学员：<span style="color:red; vertical-align: top;">{{ selectStdName }}</span> 进行分配套题角色操作！
      </p>

      <Form :label-width="80" inline>
        <FormItem label="套题角色" prop="role" style="width: 220px">
          <Select v-model="examRoleId" placeholder="请选择所属套题角色" clearable :label-in-value="true" @on-change="handleSelectExamRole">
            <Option v-for="(item, index) in examRolesList" :key="item.bookIds + index" :value="item.id">{{ item.resourcesName }}</Option>
          </Select>
        </FormItem>
        <FormItem label="过期时间" prop="examExpiredTime">
          <DatePicker type="date" placeholder="请选择过期时间" v-model="examExpiredTime"></DatePicker>
        </FormItem>
        <Icon type="ios-add-circle" size="24" color="#2d8cf0" style="cursor: pointer;" @click="addExamRole" />
      </Form>

      <p v-for="item in selectExamRoleList" :key="item.roleName" class="selectRoles">
        <span>所属角色: {{ item.roleName }}</span>
        <span>过期时间: {{ item.expirationTime ? $moment(item.expirationTime).format('YYYY-MM-DD') : '无' }} </span>
        <Icon type="md-trash" size="24" color="#2d8cf0" style="cursor: pointer;" @click="delExamRole(item.id, item.roleName)" />
      </p>

      <div slot="footer">
        <Button type="default" @click="cancelStdAssignExamRole">取消</Button>
        <Button type="primary" @click="okStdAssignExamRole">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
    export default {
      name: "StudentInfo",
      data() {
        const validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          } else if (value.length < 6 || value.length >20 ) {
            callback(new Error('请设置6至20位密码'))
          } else {
            callback();
          }
        };
        const validatePassCheck = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入密码'));
          } else if (value !== this.pwdForm.newPwd) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        };
          return {
            // 注册时间
            dateTime: '',
            // 标签数据
            labelList: [],
            // 当前选择学员id
            currentStdId: 0,
            currentLabelVolist: [],
            // 选择的分配老师名称
            allotTeaName: '',

            stdAddLabel: false,
            checkLabelArr: [],

            freezeArr: [{
              isFreeze: 0,
              label: '冻结'
            }, {
              isFreeze: 1,
              label: '解冻'
            }],

            // 分配老师 （已选择学员的前提下）
            allotModal: false,
            // 给学员分配角色权限
            assignRoleModal: false,
            selectStdName: '',
            selectTeacherId: '',
            expiredTime: null,  // 过期时间
            roleId: '',  // 所选角色id
            roleName: '',  // 所选角色名称
            selectRoleList: [],  // 已选角色及过期时间 传给后台需要的数据
            teacherList: [],
            rolesList: [], // 学员角色数据

            examExpiredTime: null, // 套题角色过期时间
            examRoleId: '', // 所选套题角色id
            examRoleName: '', // 所选套题角色名称
            examRolesList: [], // 套题角色数据

            // 给学员分配套题权限
            assignExamModal: false,
            selectExamRoleList: [],  // 已选套题角色及过期时间 传给后台需要的数据

            passwordModel: false, // 弹出重置密码弹出层
            pwdForm: {
              newPwd: '',
              surePwd: ''
            },
            pwdRule: {
              newPwd: [
                {required: true, validator: validatePass, trigger: 'blur'}
              ],
              surePwd: [
                {required: true, validator: validatePassCheck, trigger: 'change'}
              ]
            },

            loading: true,
            queryDataForm: {
              keywords:null,
              ids: [], // 查询时 选择的标签id集合
              createdDateStart: null, // 查询时  选择的注册起始时间
              createdDateEnd: null, // 查询时  选择的注册结束时间
              project: 'GMAT'
            },
            // 选择项
            selectArr: [],
            // 选中的项
            selectArrData: [],
            // 选中的学员ids 集合
            selectArrIds: [],
            // 数据
            dataObject: [],
            //分页字段
            pageData: {
              page: 1,
              size: 10,
              total: 0,
              prop: 'id', //默认排序字段
              direction: 'DESC' //默认排序方向
            },
            columns: [
              {
                title: '',
                type: 'selection',
                width: 60,
                align: 'center',
                fixed: 'left'
              },
              {
                title: '姓名/昵称',
                key: 'nickName',
                minWidth: 100,
                align: 'center',
                fixed: 'left'
              },
              {
                title: '标签',
                key: 'labelVoList',
                minWidth: 200,
                align: 'center',
                ellipsis: true,
                render: (h, params) => {
                  let labelVoList = params.row.labelVoList;
                  let num = labelVoList.length - 1;
                  if (num >= 0) {
                    return h('Poptip', {
                      props: {
                        trigger: 'hover',
                        placement: 'top-start'
                      }
                    }, [
                      h('div', [
                        h('Button', {
                          style: {
                            margin: '2px 5px 2px 0',
                          },
                          props: {
                            type: 'default',
                            size: 'small'
                          }
                        }, labelVoList[0].labelName),

                        labelVoList.length > 1 ?
                            h('Button', {
                              style: {
                                margin: '2px 5px 2px 0',
                              },
                              props: {
                                type: 'default',
                                size: 'small'
                              }
                            }, '+' + num)
                            : '',
                      ]),
                      h('div', {
                        slot: 'content'
                      }, [
                        h('ul', this.dataObject[params.index].labelVoList.map(item => {
                          return h('li', {
                            style: {
                              textAlign: 'left',
                              padding: '2px'
                            }
                          }, item.labelName)
                        }))
                      ])
                    ])
                  }
                }
              },
              {
                title: '手机号',
                key: 'phone',
                minWidth: 140,
                align: 'center',
              },
              {
                title: '目标分数',
                key: 'targetScores',
                minWidth: 120,
                align: 'center',
              },
              {
                title: '目标考试日期',
                key: 'testTime',
                minWidth: 140,
                align: 'center',
                render: (h, params) => {
                  return h('div', params.row.testTime ? this.$moment(params.row.testTime).format('YYYY-MM-DD') : '')
                },
              },
              {
                title: '常驻地',
                key: 'address',
                minWidth: 120,
                align: 'center',
              },
              {
                title: '注册时间',
                key: 'createdDate',
                minWidth: 160,
                align: 'center',
                render: (h, params) => {
                  return h('div', this.$moment(params.row.createdDate).format('YYYY-MM-DD HH:mm'))
                },
              },
              {
                title: '分配老师',
                key: "userName",
                minWidth: 120,
                align: 'center',
              },
              {
                title: '备注',
                key: 'remarks',
                minWidth: 80,
                align: 'center',
                render: (h, params) => {
                  // console.log(params);
                  // console.log(params.row);
                  if (params.row.$initialValueEdit) {
                    return h('Input', {
                      props: {
                        type: 'text',
                        value: params.row.remarks
                      },
                      on: {
                        'on-blur': event => {
                          // debugger
                          params.row.remarks = event.target.value;
                          this.dataObject[params.index].remarks = event.target.value;
                          this.$set(params.row, '$initialValueEdit', false);
                          this.editRemarks(params.row.id, event.target.value);
                        },
                      }
                    });
                  } else {
                    return h('div', {
                      style: { color: '#0D4C8C' },
                      on: {
                        click: () => {
                          this.$set(params.row, '$initialValueEdit', true);
                        },
                      }
                    }, params.row.remarks);
                  }
                }
              },
              {
                title: '最后登录时间',
                key: 'lastLoginTime',
                width: 160,
                align: 'center',
                render: (h, params) => {
                  return h('div', params.row.lastLoginTime ? this.$moment(params.row.lastLoginTime).format('YYYY-MM-DD HH:mm') : null)
                },
                sortable: true,
                sortMethod: (a, b, type) => {
                  this.pageData.prop = 'lastLoginTime'
                  this.pageData.direction = type.toUpperCase();
                }
              },
              {
                title: '操作',
                key: 'action',
                width: 360,
                align: 'center',
                className: 'student-operation-column',
                render: (h,params) => {
                  let isFreeze = params.row.isFreeze;
                  let show = isFreeze === 0 ? this.freezeArr[0].label : this.freezeArr[1].label;
                  let freezeId =  isFreeze === 0 ? this.freezeArr[1].isFreeze : this.freezeArr[0].isFreeze;
                  // console.log('show ==== >', show);
                  return h('div', [
                    h('Button', {
                      style: {
                        marginRight: '5px',
                        marginBottom: '5px'
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                        disabled: (params.row.isFreeze !== 0)
                      },
                      on: {
                        click: () => {
                          this.$router.push({
                            path: "/student/self/StudentInfo/StudentAddOrEdit",
                          });
                          this.$storage.set('StudentAddOrEdit', 'id', params.row.id);
                          this.$storage.set('StudentInfo', 'page', this.pageData.page);
                        }
                      }
                    }, '编辑'),
                    h('Button', {
                      style: {
                        marginRight: '5px',
                        marginBottom: '5px'
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                      },
                      on: {
                        click: () => {
                          this.$router.push({
                            path:`/student/self/StudentInfo/StudentDetail`,
                          });
                          this.$storage.set('StudentDetail', 'id', params.row.id);
                          this.$storage.set('StudentInfo', 'page', this.pageData.page);
                        }
                      }
                    }, '详情'),
                    h('Button', {
                      style: {
                        marginRight: '5px',
                        marginBottom: '5px'
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                        disabled: (params.row.isFreeze !== 0)
                      },
                      on: {
                        click: () => {

                          this.stdAddLabel = true;
                          this.currentStdId = params.row.id;

                          let arr = params.row.labelVoList;
                          let checkArr = [];
                          arr.forEach(item => {
                            let str =  item.id + ':' + item.labelName;
                            checkArr.push(str);
                          });

                          this.checkLabelArr = checkArr;

                        }
                      }
                    }, '打标签'),
                    h('Button', {
                      style: {
                        marginRight: '5px',
                        marginBottom: '5px'
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                        ghost: true,
                      },
                      on: {
                        click: () => {
                          this.handleFreeze(params.row.id, freezeId, show);
                        }
                      }
                    }, show),
                    h('Button', {
                      style: {
                        marginRight: '5px',
                        marginBottom: '5px'
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                      },
                      on: {
                        click: () => {
                          this.selectStdName = params.row.nickName;
                          this.currentStdId = params.row.id;
                          this.passwordModel = true;
                        },
                      }
                    }, '重置密码'),
                    h('Button', {
                      style: {
                        marginRight: '5px',
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                        disabled: (params.row.isFreeze !== 0)
                      },
                      on: {
                        click: () => {
                          let currentArr = [];
                          currentArr.push(params.row);
                          this.selectArrData = currentArr;
                          this.allotTeaName = params.row.labelName;
                          this.allotModal = true;
                          let selectArrIds = [];
                          selectArrIds.push(params.row.id)
                          this.selectArrIds = selectArrIds;
                          this.selectTeacherId = '';
                        },
                      }
                    }, '分配老师'),
                    h('Button', {
                      style: {
                        marginRight: '5px',
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                      },
                      on: {
                        click: () => {
                          this.selectStdName = params.row.nickName
                          this.assignRoleModal = true;
                          this.currentStdId = params.row.id;
                          this.queryRoleList();
                          this.queryCheckRole(params.row.id);
                        },
                      }
                    }, '分配角色'),
                    h('Button', {
                      style: {
                        marginRight: '5px',
                      },
                      props: {
                        type: 'primary',
                        size: 'small',
                      },
                      on: {
                        click: () => {
                          this.assignExamModal = true
                          this.selectStdName = params.row.nickName
                          this.currentStdId = params.row.id;
                          this.queryExamRoleList();
                          this.queryCheckExamRole(params.row.id);
                        },
                      }
                    }, '分配套题'),
                      ],
                  );
                }
              }
            ]
          }
      },
      methods: {

        // 学员标签查询
        queryLabelList() {
          this.$axios.post('/api/student/label/list', {}, {})
              .then(res => {

                if (res.code === '10000') {
                  this.labelList = res.body;
                }
              })
              .catch(error => {
                console.log(error)
              })
        },

        // 老师列表查询
        queryTeacherList() {
          this.$axios.post('/api/dict/user', {}, {})
          .then(res => {
            if (res.code === '10000') {
              this.teacherList = res.body;
            }
          })
          .catch(error => {
            console.log(error)
          })
        },
        // 老师下拉框选择
        selectTeacherChange(id) {
          this.selectTeacherId = id;
        },

        // 学员列表查询
        query(page = 1, size = 10, requestVo = {}) {
          this.pageData.page = page;
          this.pageData.size = size;
          let pageObj = {
            page: page - 1,
            size: size,
            sort: this.pageData.prop + ',' + this.pageData.direction,
          }
          this.$axios.post("/api/student/page", requestVo, {params: pageObj})
              .then(res => {
                console.log('res===>', res)
                if (res.code === '10000') {
                  this.dataObject = []
                  this.dataObject = res.body.content

                  for (let i = 0; i < this.dataObject.length; i++) {
                    if (this.dataObject[i].isFreeze !== 0) {
                      this.dataObject[i]._disabled = true
                    }
                  }

                  this.pageData.total = res.body.totalElements
                  this.loading = false
                } else if (res.code === '40007') {
                  this.pageData.total = 0;
                  this.dataObject = []
                }
              })
              .catch(error => {
                console.log('error===>', error)
                if (error.code === '10000') {
                  this.dataObject = []
                  this.dataObject = error.body.content
                  this.pageData.total = error.body.totalElements
                  this.loading = false
                }
              })
        },

        // 修改学员备注信息
        editRemarks(id, value) {
          let requestVo = {
            id: id,
            remarks: value
          };
          this.$axios.post('/api/student/handle/remarks', requestVo).then(res => {
            if (res.code === '10000') {
              this.$Message.success('修改备注成功');
              this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
            }
          }).catch(error => {
            this.$Message.error('修改失败');
          })
        },

        // 新增学员
        addStudent() {
          this.$router.push({
            path:`/student/self/StudentInfo/StudentAddOrEdit`
          });
        },

        // 分配老师
        allotTeacher() {
          //  没有选择学员时点击
          if(this.selectArrData.length === 0) {
            this.$Message.warning('请选择学员');
          } else {
            this.allotModal = true;
          }
        },
        // 已选择学员的时候 确定 分配老师
        okAllotTeacher() {

          let requestVo = {
            ids: this.selectArrIds,
            userId: this.selectTeacherId,
          }
          console.log(requestVo);
          this.$axios.post('/api/student/distribution', requestVo, {})
              .then(res => {
                if(res.code === '10000') {
                  this.$Message.success('分配成功');
                  this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                  this.selectArrIds = [];
                  this.selectTeacherId = '';
                  this.selectArrData = [];
                }
              })
              .catch(error => {
                this.$Message.error('取消分配老师');
              })

        },
        // 取消  分配老师
        cancelAllotTeacher() {
          this.selectArrIds = [];
          this.selectTeacherId = '';
          this.selectArrData = [];
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
        },


        // 打标签
        okStdAddLabel() {

          let checkLabelArr = this.checkLabelArr;
          let checkArr = [];
          checkLabelArr.forEach(item => {
            let str = item.split(':');
            let checkObj = {
              id: str[0],
              labelName: str[1]
            }
            checkArr.push(checkObj)
          })

          console.log('checkArr ===> ', checkArr);

          this.currentLabelVolist = checkArr;

          let requestVo = {
            id: this.currentStdId,
            labelVolist: this.currentLabelVolist
          };

          console.log('requestVo===>', requestVo);
          this.$axios.post('/api/student/bind/label', requestVo, {})
          .then(res => {
            if(res.code === '10000') {
              this.$Message.success('打标签成功');
              this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            }
          })
          .catch(error => {
            this.$Message.error('打标签失败');
          })

        },
        cancelStdAddLabel() {
          this.$Message.warning("您已经取消了打标签");
        },

        // 冻结学员
        handleFreeze(id, freezeId, show) {

          let url = '/api/student/isfreeze/' + id + '/' + freezeId;
          let requestVo = {};

          this.$Modal.confirm({
            title: '提示',
            content: '<p>您确定' +  show + '该学员吗？</p>',
            onOk: () => {
              this.$axios.post(url, requestVo, {})
                  .then(res => {
                    console.log('res ==>',res)
                    if(res.code === '10000') {
                      this.$Message.success('冻结成功');
                      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                    }
                  })
                  .catch(error => {
                    this.$Message.error(show + '失败');
                  })
            },

            onCancel: () => {
              this.$Message.warning("您已经取消了" + show);
            }
          })

        },

        // 查询所有角色数据
        queryRoleList() {
          this.$axios.post('/api/student/rolelist/list', {}, {}).then(res => {
            if (res.code === '10000') {
              console.log('res===>', res.body)
              this.rolesList = res.body;
            }
          }).catch(error => {
            console.log(error)
          })
        },

        // 查询学员所属角色
        queryCheckRole(studentId) {

          this.$axios.post(`/api/student/role/list/${studentId}`).then(res => {
            if (res.code === '10000') {
              console.log('res===>', res.body);
              this.selectRoleList = res.body;
            }
          }).catch(error => {
            console.log('error===>', error)
          })
        },

        // 查询所有套题角色数据
        queryExamRoleList() {
          this.$axios.post('/api/book/roles/list', {}, {}).then(res => {
            if (res.code === '10000') {
              this.examRolesList = res.body
            }
          }).catch(error => {
            console.log(error)
          })
        },

        // 查询当前学员所属的套题角色
        queryCheckExamRole(studentId) {
          this.$axios.post(`/api/student/book/role/list/${studentId}`).then(res => {
            if (res.code === '10000') {
              this.selectExamRoleList = res.body;
            }
          }).catch(error => {
            console.log(error)
          })
        },


        // select 选择学员角色
        handleSelectRole(data) {
          console.log('data===>select===>', data)

          if (data) {
            this.roleId = data.value;
            this.roleName = data.label;
          } else {
            this.roleId = '';
            this.roleName = '';
          }

        },

        // 新增角色
        addRole() {

          if (this.roleId) {
            let isReplace = this.selectRoleList.some(item => {
              if (item.roleName === this.roleName) {
                return true;
              }
            });

            if (isReplace) {
              this.selectRoleList.forEach(item => {
                if (item.roleName === this.roleName) {
                  delete item.id
                  item.roleName = this.roleName;
                  item.expirationTime = this.expiredTime
                  item.studentId = this.currentStdId
                  item.rolesId = parseInt(this.roleId)
                }
              })
            } else {
              this.selectRoleList.push({
                roleName: this.roleName,
                expirationTime: this.expiredTime,
                studentId: this.currentStdId,
                rolesId: parseInt(this.roleId)
              });
            }
          } else {
            this.$Message.warning('请选择所属角色')
          }
          console.log('this.selectRoleList===>', this.selectRoleList)
          this.roleId = '';
          this.roleName = '';
          this.expiredTime = null;

        },

        // 删除所选角色
        delRole(id, name) {
          if (id) {
            this.selectRoleList.some((item, index) => {
              if (item.id === id) {
                this.selectRoleList.splice(index, 1);
              }
            })
            this.$axios.post(`/api/student/role/delete?id=${[id]}`).then(res => {
              if (res.code === '10000') {
                this.$Message.success('删除成功');
                // this.queryCheckRole(this.currentStdId);
              }
            }).catch(error => {
              console.log('error===>', error)
            })
          } else {
            this.selectRoleList.some((item, index) => {
              if (item.roleName === name) {
                this.selectRoleList.splice(index, 1);
              }
            })
          }
        },

        // 确定 给学员分配某角色
        okStdAssignRole() {
          let selectRoleData = this.selectRoleList;
          console.log('this.selectRoleList===>', this.selectRoleList);
          if (this.roleId && this.roleName) {
            this.$Message.warning('您已选择所属角色，请先点击右上角的加号图标后，再点击确定')
          } else {
            if (selectRoleData.length > 0) {
              this.$axios.post('/api/student/role/handle', selectRoleData).then(res => {
                if (res.code === '10000') {
                  this.roleId = '';
                  this.roleName = '';
                  this.expiredTime = null;
                  this.assignRoleModal = false;
                  this.$Message.success('分配角色成功');
                  // this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
                }
              }).catch(error => {
                console.log('error===>', error)
              })
            } else {
              this.roleId = '';
              this.roleName = '';
              this.expiredTime = null;
              this.assignRoleModal = false;
            }
          }

        },

        // 取消 给学员分配某角色
        cancelStdAssignRole() {
          this.roleId = '';
          this.roleName = '';
          this.expiredTime = null;
          this.assignRoleModal = false;
        },


        // 选择学员分配的套题角色
        handleSelectExamRole(data) {
          if (data) {
            this.examRoleId = data.value
            this.examRoleName = data.label
          } else {
            this.examRoleId = ''
            this.examRoleName = ''
          }
        },

        // 新增套题角色
        addExamRole() {
          if (this.examRoleId) {
            let isReplace = this.selectExamRoleList.some(item => {
              if (item.roleName === this.examRoleName) {
                return true;
              }
            });

            if (isReplace) {
              this.selectExamRoleList.forEach(item => {
                if (item.roleName === this.examRoleName) {
                  delete item.id
                  item.roleName = this.examRoleName;
                  item.expirationTime = this.examExpiredTime
                  item.studentId = this.currentStdId
                  item.rolesId = parseInt(this.examRoleId)
                }
              })
            } else {
              this.selectExamRoleList.push({
                roleName: this.examRoleName,
                expirationTime: this.examExpiredTime,
                studentId: this.currentStdId,
                rolesId: parseInt(this.examRoleId)
              });
            }
          } else {
            this.$Message.warning('请选择所属角色')
          }
          console.log('this.selectExamRoleList===>', this.selectExamRoleList)
          this.examRoleId = '';
          this.examRoleName = '';
          this.examExpiredTime = null;
        },

        // 删除所选的套题角色
        delExamRole(id, name) {
          if (id) {
            this.selectExamRoleList.some((item, index) => {
              if (item.id === id) {
                this.selectExamRoleList.splice(index, 1);
              }
            })
            this.$axios.post(`/api/student/book/role/delete?id=${[id]}`).then(res => {
              if (res.code === '10000') {
                this.$Message.success('删除成功');
                // this.queryCheckRole(this.currentStdId);
              }
            }).catch(error => {
              console.log('error===>', error)
            })
          } else {
            this.selectExamRoleList.some((item, index) => {
              if (item.roleName === name) {
                this.selectExamRoleList.splice(index, 1);
              }
            })
          }
        },

        // 取消 给学员分配套题角色
        cancelStdAssignExamRole() {
          this.examRoleId = '';
          this.examRoleName = '';
          this.examExpiredTime = null;
          this.assignExamModal = false
        },

        // 确定 给学员分配套题角色
        okStdAssignExamRole() {
          let selectExamRoleData = this.selectExamRoleList;
          console.log('this.selectExamRoleList===>', this.selectExamRoleList);
          if (this.examRoleId && this.examRoleName) {
            this.$Message.warning('您已选择所属套题角色，请先点击右上角的加号图标后，再点击确定')
          } else {
            if (selectExamRoleData.length > 0) {
              this.$axios.post('/api/student/book/role/handle', selectExamRoleData).then(res => {
                if (res.code === '10000') {
                  this.examRoleId = '';
                  this.examRoleName = '';
                  this.examExpiredTime = null;
                  this.assignExamModal = false
                  this.$Message.success('分配套题角色成功');
                  // this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
                }
              }).catch(error => {
                console.log('error===>', error)
              })
            } else {
              this.examRoleId = '';
              this.examRoleName = '';
              this.examExpiredTime = null;
              this.assignExamModal = false
            }
          }
        },

        // 选择 注册时间 进行查询
        dateChange(time) {
          console.log(this.dateTime);
          this.queryDataForm.createdDateStart = this.$moment(time[0]).format();
          this.queryDataForm.createdDateEnd = this.$moment(time[1]).format();
          if (time[0] === '' && time[1] === '') {
            this.queryDataForm.createdDateStart = null;
            this.queryDataForm.createdDateEnd = null;
          }
        },


        // 选择标签 进行查询
        selectLabel(arr) {
          this.ids = arr;
        },

        // 查询
        search() {

          let queryDataForm = {
            keywords: this.queryDataForm.keywords,
            labelIds: this.queryDataForm.ids,
            createdDateStart: this.queryDataForm.createdDateStart,
            createdDateEnd: this.queryDataForm.createdDateEnd,
            project: 'GMAT'
          };
          console.log(queryDataForm);
          this.query(this.pageData.page, this.pageData.size, queryDataForm)

        },
        // 重置
        refresh() {
          this.dateTime = '';
          this.queryDataForm = {
            keywords:null,
            ids: [],
            createdDateStart: null,
            createdDateEnd: null,
            project: 'GMAT'
          }
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
        },
        // 导出 暂时隐藏
        // exportFn() {
        //
        // },

        // 重置密码提交
        handlePasswordSubmit() {
          let params = {
            id: this.currentStdId,
            password: this.pwdForm.newPwd,
          };

          this.$refs.passwordForm.validate(valid => {
            if (valid) {
              this.$axios.post('/api/student/reset/password', params, {}).then(res => {
                console.log('res====>', res);
                this.$Message.success('重置密码成功');
                this.passwordModel = false;
                this.$refs.passwordForm.resetFields();  // 对整个表单进行重置，将所有字段值重置为空并移除校验结果
              }).catch(error => {
                console.log('error===>', error);
              })
            } else {
              return false;
            }
          })
        },


        // 列表选择
        onSelectionChangeTable(selection) {

          let selectArrData = this.$refs.selection.getSelection();
          this.selectArrData = selectArrData;

          let selectStdId = [];
          selectArrData.forEach(item => {
            selectStdId.push(item.id)
          })
          this.selectArrIds = selectStdId;


          this.selectArr = selection
        },

        // 排序
        onSortChangeTable() {
          this.query(1, this.pageData.size, this.queryDataForm)
        },
        /*分页相关函数*/
        pageOnChange(pageNumber) {
          this.pageData.page = pageNumber
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
        },
        pageSizeChange(pageSize) {
          this.pageData.size = pageSize;
          this.query(1, this.pageData.size, this.queryDataForm);
        },
      },
      mounted() {

        let page = this.$storage.get('StudentInfo', 'page') ? this.$storage.get('StudentInfo', 'page') : this.pageData.page;
        this.queryLabelList();
        this.query(page, this.pageData.size, { project: 'GMAT' })
        this.queryTeacherList();
        this.$storage.remove('StudentInfo', 'page')
      }

    }
</script>

<style>
.student-operation-column{
  padding: 5px!important;
  text-align: left!important;
}
.student-operation-column .ivu-table-cell {
  max-height: none!important;
}
</style>
<style lang="less" scoped>
  .header-top1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .header .header-top1 .header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-left .option{
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .studentListTable{
    overflow: initial;
  }

  .allotModal{
    .ivu-poptip{
      width: 100%;
    }
  }
  .allotModal .content {
    margin-left: 40px;
  }
  .allotModal .content p{
    margin-bottom: 20px;
  }

  .checkModal{
    //.singleLabel{
    //  display: inline-block;
    //  overflow: hidden;
    //  white-space: nowrap;
    //  text-overflow: ellipsis;
    //  width: 110px;
    //  margin-right: 0;
    //}
    .ivu-checkbox-group{
      text-align: left;
      .ivu-checkbox-group-item {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100px;
      }
    }

  }

  .selectRoles{
    display: flex;
    margin: 10px 0 0 13px;
    align-items: center;
    span{
      margin-right: 20px;
    }
  }
</style>
