<template>
<!-- 新建/编辑 题目 -->
  <div>
    <Form :model="formParams" ref="ruleValidate" :rules="ruleValidate" :label-width="120" class="topicForm">
      <FormItem label="题目科目" prop="subject" style="font-weight: 700">
          <dict-select
              style="width: 180px;display: inline-block"
              v-model="formParams.subject"
              :value="formParams.subject"
              dict-key="subject"
              select-type="NORMAL"
              @on-change="selectSubjectChange"
          ></dict-select>
      </FormItem>
      <FormItem label="考点" prop="examinationSite" style="font-weight: 700">
        <dict-multiple-select
            style="width: 380px;display: inline-block"
            v-model="examinationSite"
            :value="examinationSite"
            :dict-key="changeExaminationSite"
            :view-value="false"
            select-type="NORMAL"
            @on-change="selectExaminationSiteChange"
        ></dict-multiple-select>
        <p style="font-size: 12px; font-weight: normal;">注：科目请不要选择Verbal和Quant</p>
      </FormItem>
      <FormItem label="来源" prop="source" style="font-weight: 700">
        <dict-select
            style="width: 180px;display: inline-block"
            v-model="formParams.source"
            :value="formParams.source"
            :dict-key="source"
            select-type="NORMAL"
            @on-change="selectSourceChange"
        ></dict-select>
      </FormItem>

      <FormItem label="难度" prop="topicDifficulty" style="font-weight: 700">
        <dict-select
            style="width: 180px;display: inline-block"
            v-model="formParams.topicDifficulty"
            :value="formParams.topicDifficulty"
            dict-key="topicDifficulty"
            select-type="NORMAL"
            @on-change="selectTopicDifficultyChange"
        ></dict-select>
      </FormItem>

      <FormItem label="题目类型" prop="topicType" style="font-weight: 700">
        <Select v-model="formParams.topicType" placeholder="请选择题目类型" style="width:200px">
          <Option v-for="item in topicTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem>

      <FormItem label="题目序号" prop="topicNumber" style="font-weight: 700;">
        <Input v-model="formParams.topicNumber" placeholder="请输入题目序号" style="width: 180px" />
      </FormItem>

      <FormItem label="题目模板" style="font-weight: 700;">
        <Tabs value="1">
          <TabPane label="多tabs" name="1">
            <TemplateEditor id="t-editor-1" v-model="multiTabs" :height=300 />
          </TabPane>
          <TabPane label="表格排序" name="2">
            <TemplateEditor id="t-editor-2" v-model="tableSort" :height=300 />
          </TabPane>
          <TabPane label="纵向单个选择" name="3">
           <TemplateEditor id="t-editor-3" v-model="lengthwaysSingle" :height=300 />
          </TabPane>
          <TabPane label="横向多个选择" name="4">
            <TemplateEditor id="t-editor-4" v-model="crosswiseMulti" :height=300 />
          </TabPane>
          <TabPane label="图表纵向两个选择" name="5">
            <TemplateEditor id="t-editor-5" v-model="tableLengthwaysTwo" :height=300 />
          </TabPane>
          <TabPane label="下拉选择" name="6">
            <TemplateEditor id="t-editor-6" v-model="dropDownSelection" :height=300 />
          </TabPane>
        </Tabs>
      </FormItem>

      <FormItem label="题目内容" prop="topicTitle" style="font-weight: 700;">
        <TEditor id="gmatTopicEditor" v-model="formParams.context" :height=500 />
      </FormItem>

      <FormItem label="题目选项" style="font-weight: 700">
        <FormItem label="A" prop="topicOptionA" style="font-weight: 700" :label-width="40">
          <Input v-model="formParams.topicOptionA" placeholder="请输入..." style="width: 400px" />
        </FormItem>
        <FormItem label="B" prop="topicOptionB" style="font-weight: 700" :label-width="40">
          <Input v-model="formParams.topicOptionB" placeholder="请输入..." style="width: 400px" />
        </FormItem>
        <FormItem label="C" prop="topicOptionC" style="font-weight: 700" :label-width="40">
          <Input v-model="formParams.topicOptionC" placeholder="请输入..." style="width: 400px" />
        </FormItem>
        <FormItem label="D" prop="topicOptionD" style="font-weight: 700" :label-width="40">
          <Input v-model="formParams.topicOptionD" placeholder="请输入..." style="width: 400px" />
        </FormItem>
        <FormItem label="E" prop="topicOptionE" style="font-weight: 700" :label-width="40">
          <Input v-model="formParams.topicOptionE" placeholder="请输入..." style="width: 400px" />
        </FormItem>
      </FormItem>

      <FormItem label="题目答案" prop="topicAnswer" style="font-weight: 700">
        <Input v-model="formParams.topicAnswer" placeholder="请输入答案" style="width: 120px" />
      </FormItem>

      <FormItem label="题目解析" prop="topicAnalysis" style="font-weight: 700">
        <TEditor id="editorAnalysis" v-model="formParams.topicAnalysis" :height=400 />
      </FormItem>

      <FormItem>
        <Button type="primary" @click="handleSubmit('formValidate')">确定</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>

  </div>

</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';

import DictMultipleSelect from "../../../components/DictSelect/DictMultipleSelect";
import TEditor from '../../../components/TEditor/TEditor';
import TemplateEditor from '../../../components/TEditor/TemplateEditor'

export default {
  name: "TopicAddOrEdit",
  components: {
    DictMultipleSelect,
    DictSelect,
    TEditor,
    TemplateEditor
  },
  data() {
    return {
      multiple: true,
      // 题目id
      id: this.$storage.get('TopicAddOrEdit', 'id'),
      // 考点
      examinationSite: null,
      // 来源 根据 科目 联动的
      source: null,
      // 考点 根据 科目 联动的
      changeExaminationSite: null,
      topicTypeList: [{
        value: 1,
        label: '基础题型'
      }, {
        value: 2,
        label: '纵向单个选择'
      }, {
        value: 3,
        label: '横向多个选择'
      }, {
        value: 4,
        label: '图表纵向两个选择'
      }, {
        value: 5,
        label: '下拉选择'
      }],
      formParams: {
        subject: null,
        examinationSite: null,
        source: null,
        topicDifficulty: null,
        topicType: null,
        context: '',
        topicOptionA: null,
        topicOptionB: null,
        topicOptionC: null,
        topicOptionD: null,
        topicOptionE: null,
        topicAnswer: null,
        topicAnalysis: '',
        topicNumber: null,
      },

      topicAnswerList: ['A', 'B', 'C', 'D', 'E'],
      ruleValidate: {
        subject: [{
          required: true, message: '请选择题目来源', trigger: 'change',
        }],
        examinationSite: [{
          required: true, type: 'array', min: 1, message: '请选择考点', trigger: 'change',
        }],
        source: [{
          required: true, message: '请选择来源', trigger: 'change'
        }],
        topicDifficulty: [{
          required: true, message: '请选择难度', trigger: 'change'
        }],
        topicType: [{
          required: true, type: 'number', message: '请选择题目类型', trigger: 'change'
        }],
        // topicTitle: [{
        //   required: true, message: '请输入题目内容', trigger: 'change'
        // }],
        topicAnswer: [{
          required: true, message: '请输入正确答案', trigger: 'change'
        }],
        topicNumber: [{
          required: true, message: '请输入题目序号', trigger: 'change'
        }]
      },
      multiTabs: '<div class="tabs-group"><a class="tabs-btn" data-link="#first-tabs">材料一的标题</a><a class="tabs-btn" data-link="#second-tabs">材料二的标题</a><a class="tabs-btn" data-link="#third-tabs">材料三的标题(没有可以不填)</a></div> <div><div id="first-tabs" class="content-tab"><p>材料一的内容</p></div><div id="second-tabs" class="content-tab"><p>材料二的内容</p></div><div id="third-tabs" class="content-tab"><p>材料三的内容(没有可以不填)</p></div></div>',
      tableSort: '<table class="table-sorter" style="border-collapse: collapse; width: 99.9371%; height: 324.437px; background-color: #ffffff; border-color: #ced4d9; border-style: solid;" border="1" align="center">\n' +
          '<thead><tr style="height: 67.8438px;"><th class="fa-sort" style="width: 25%; height: 67.8438px;" scope="row" align="center">表头一</th><th class="fa-sort" style="width: 25%; height: 67.8438px;" scope="row" align="center">表头二</th><th class="fa-sort" style="width: 25%; height: 67.8438px;" scope="row" align="center">表头三</th><th class="fa-sort" style="width: 25%; height: 67.8438px;" scope="row" align="center">表头四(可以再增加列数和行数)</th></tr></thead>\n' +
          '\n' +
          '<tbody><tr style="height: 30.5625px;"><td style="width: 25%; height: 30.5625px;" align="center">Sauer</td><td style="width: 25%; height: 30.5625px;" align="center">6</td><td style="width: 25%; height: 30.5625px;" align="center">-59</td><td style="width: 25%; height: 30.5625px;" align="center">3481</td></tr>\n' +
          '<tr style="height: 30.5625px;"><td style="width: 25%; height: 30.5625px;" align="center">Fournier</td><td style="width: 25%; height: 30.5625px;" align="center">24</td><td style="width: 25%; height: 30.5625px;" align="center">-41</td><td style="width: 25%; height: 30.5625px;" align="center">1681</td></tr></tbody>\n' +
          '</table>',
      lengthwaysSingle: '<div><p>问答题目</p><ul class="ul-options" style="list-style: none;"><li><div class="li-choice" data-choice="A">&nbsp;</div><div class="choice-content">选项A的内容</div></li><li><div class="li-choice" data-choice="B">&nbsp;</div><div class="choice-content">选项B的内容</div></li><li><div class="li-choice" data-choice="C">&nbsp;</div><div class="choice-content">选项C的内容</div></li><li><div class="li-choice" data-choice="D">&nbsp;</div><div class="choice-content">选项D的内容</div></li><li><div class="li-choice" data-choice="E">&nbsp;</div><div class="choice-content">选项E的内容</div></li></ul></div>',
      crosswiseMulti: '<div><p>问答题目</p>\n' +
          '<ul class="ul-options" style="list-style: none;"><li data-index="0"><div class="li-multi-choice" data-choice="A">Yes(可以是别的，比如True等)</div><div class="li-multi-choice" data-choice="B">No(可以是别的，比如False等)</div><div class="choice-content">题干内容</div></li>\n' +
          '<li data-index="1"><div class="li-multi-choice" data-choice="A">Yes(可以是别的，比如True等)</div><div class="li-multi-choice" data-choice="B">No(可以是别的，比如False等)</div><div class="choice-content">题干内容</div></li>\n' +
          '<li data-index="2"><div class="li-multi-choice" data-choice="A">Yes(可以是别的，比如True等)</div><div class="li-multi-choice" data-choice="B">No(可以是别的，比如False等)</div><div class="choice-content">题干内容</div></li></ul>\n' +
          '</div>',
      tableLengthwaysTwo:  '<p>题目内容（下面的表格可以自己增加行）</p>\n' +
          '<table class="question-table" style="border-collapse: collapse; width: 100%;" border="1">\n' +
          '<thead><tr><td style="width: 15%;" align="center"><b>条件A</b></td><td style="width: 15%;" align="center"><span style="color: #3c3737; font-weight: bold;">条件B</span></td><td style="width: 70%;text-align: left;font-weight: bold;"><div class="choice-content">有介绍可以加上</div></td></tr>\n' +
          '</thead>\n' +
          '<tbody>\n' +
          '<tr><td style="width: 15%;"><div class="vertical-choice" data-choice="A" data-index="0">&nbsp;</div></td><td style="width: 15%;"><div class="vertical-choice" data-choice="A" data-index="1">&nbsp;</div></td><td style="width: 70%;"><div class="choice-content">选项A</div></td></tr>\n' +
          '<tr><td style="width: 15%;"><div class="vertical-choice" data-choice="B" data-index="0">&nbsp;</div></td><td style="width: 15%;"><div class="vertical-choice" data-choice="B" data-index="1">&nbsp;</div></td><td style="width: 70%;"><div class="choice-content">选项B</div></td></tr>\n' +
          '<tr><td style="width: 15%;"><div class="vertical-choice" data-choice="C" data-index="0">&nbsp;</div></td><td style="width: 15%;"><div class="vertical-choice" data-choice="C" data-index="1">&nbsp;</div></td><td style="width: 70%;"><div class="choice-content">选项C</div></td></tr>\n' +
          '<tr><td style="width: 15%;"><div class="vertical-choice" data-choice="D" data-index="0">&nbsp;</div></td><td style="width: 15%;"><div class="vertical-choice" data-choice="D" data-index="1">&nbsp;</div></td><td style="width: 70%;"><div class="choice-content">选项D</div></td></tr>\n' +
          '<tr><td style="width: 15%;"><div class="vertical-choice" data-choice="E" data-index="0">&nbsp;</div></td><td style="width: 15%;"><div class="vertical-choice" data-choice="E" data-index="1">&nbsp;</div></td><td style="width: 70%;"><div class="choice-content">选项E</div></td></tr>\n' +
          '<tr><td style="width: 15%;"><div class="vertical-choice" data-choice="F" data-index="0">&nbsp;</div></td><td style="width: 15%;"><div class="vertical-choice" data-choice="F" data-index="1">&nbsp;</div></td><td style="width: 70%;"><div class="choice-content">选项F</div></td></tr></tbody>\n' +
          '</table>',
      dropDownSelection: '<p>To the nearest 5 percent, the number of weeks the development team has at least one task scheduled is&nbsp;<select class="select-inline" data-index="0"> <option value="">Select...</option> <option value="A" data-choice="A">55</option> <option value="B" data-choice="B">35</option> <option value="C" data-choice="C">40</option> <option value="D" data-choice="D">50</option> <option value="E" data-choice="E">60</option> </select>&nbsp;percent of the total duration represented by the chart.</p> <p>Week&nbsp;<select class="select-inline" data-index="1"> <option value="">Select...</option> <option value="A" data-choice="A">8</option> <option value="B" data-choice="B">6</option> <option value="C" data-choice="C">7</option> <option value="D" data-choice="D">9</option> <option value="E" data-choice="E">10</option> </select>&nbsp;is the only week during which three different tasks are scheduled to begin.</p>',
    }
  },
  methods: {

    queryDetail(id) {
      this.$axios.get(`/api/topic/details/${id}`, {}, {})
          .then(res => {
            console.log('res====>', res);
            if(res.code === '10000') {
              let data = res.body;

              this.formParams.subject = data.subject;

              this.source = `${data.subject.toLowerCase()}Source`;
              this.changeExaminationSite = `${data.subject.toLowerCase()}ExaminationSite`;

              this.examinationSite = data.examinationSite.split(',');
              this.formParams.examinationSite = data.examinationSite.split(',');
              this.formParams.source = data.topicSource;
              this.formParams.topicDifficulty = data.difficulty;
              this.formParams.topicNumber = data.topicNumber ? data.topicNumber.toString() : null;
              this.formParams.topicType = data.topicType;

              let topicTitle = data.topicTitle;
              if (topicTitle && (topicTitle.indexOf("<p>") === -1 || topicTitle.indexOf("</p>") === -1)) {
                topicTitle = "<p>" + topicTitle + "</p>"
              }

              this.formParams.context = topicTitle;
              this.formParams.topicOptionA = data.topicOptionA;
              this.formParams.topicOptionB = data.topicOptionB;
              this.formParams.topicOptionC = data.topicOptionC;
              this.formParams.topicOptionD = data.topicOptionD;
              this.formParams.topicOptionE = data.topicOptionE;
              this.formParams.topicAnswer = data.topicAnswer;
              this.formParams.topicAnalysis = data.topicAnalysis ? data.topicAnalysis : '';

            }
          })
          .catch(error => {
            console.log(error);
          })

    },

    selectSubjectChange(param) {
      this.formParams.subject = param;

      this.source = `${param.toLowerCase()}Source`;
      this.changeExaminationSite = `${param.toLowerCase()}ExaminationSite`;

      this.formParams.examinationSite = null;
      this.formParams.source = null;
    },
    selectExaminationSiteChange(param) {
      console.log('topicAddOrEdit param===>', param)
      this.examinationSite = param;
      console.log('examinationSite show===>', param)
      this.formParams.examinationSite = param;
    },
    selectSourceChange(param) {
      this.formParams.source = param;
    },
    selectTopicDifficultyChange(param) {
      this.formParams.topicDifficulty = param;
    },
    selectTopicTypeChange(param) {
      this.formParams.topicType = param;
    },

    // 确定
    handleSubmit() {
      let url = '/api/topic/handle';
      let requestVo = {
        type: ['Verbal', 'SC', 'CR', 'RC'].indexOf(this.formParams.subject) !== -1 ? 1 : ['Quant', 'PS', 'JJ', 'DS'].indexOf(this.formParams.subject) !== -1 ? 2 : 3, // type 1:语文试题；2：数学试题；3：Data Insight
        subject: this.formParams.subject,
        examinationSite: this.formParams.examinationSite ? this.formParams.examinationSite.toString() : null,
        topicSource: this.formParams.source,
        difficulty: this.formParams.topicDifficulty,
        topicType: this.formParams.topicType,
        topicTitle: this.formParams.context,
        topicOptionA: this.formParams.topicOptionA,
        topicOptionB: this.formParams.topicOptionB,
        topicOptionC: this.formParams.topicOptionC,
        topicOptionD: this.formParams.topicOptionD,
        topicOptionE: this.formParams.topicOptionE,
        topicAnswer: this.formParams.topicAnswer,
        topicAnalysis: this.formParams.topicAnalysis,
        topicNumber: parseInt(this.formParams.topicNumber),
      }

      console.log('requestVo====>', requestVo)

      let id = this.id;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      this.$refs.ruleValidate.validate(valid => {
        if (valid) {
          this.$axios.post(url, requestVo, {})
              .then(res => {
                if (res.code === '10000') {
                  this.$Message.success('保存成功');
                  localStorage.setItem('isRemove', JSON.stringify(true));
                  this.$storage.remove('TopicAddOrEdit', 'id');
                  this.$router.push({ path:`/content/topic/TopicList` });
                }
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          return false;
        }
      })

    },
    // 取消
    handleReset() {
      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$storage.remove('TopicAddOrEdit', 'id');
      this.$router.push({ path:`/content/topic/TopicList` });
    },
  },

  watch:{
    "context":{
      handler(newVal,oldVal){
        this.formParams.context = newVal;
        console.log('newVal',newVal)
      }
    }
  },
  mounted() {
    let id = this.id;
    if (id) {
      this.queryDetail(id);
    }
  },
}
</script>

<style lang="less" scoped>

.topicForm{
  .ivu-form-item{
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
