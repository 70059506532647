<!--
  @author yanzi
  @date 2023/8/7
  @Description: 新增 | 编辑 商品
-->
<template>
  <div>
    <Form ref="goodsFormValidate" :model="goodsForm" :rules="goodsRule" :label-width="100">
      <FormItem label="商品名称" prop="name" style="font-weight: 700;">
        <Input v-model="goodsForm.name" placeholder="请输入商品名称" style="width: 280px;"></Input>
      </FormItem>
      <FormItem label="商品类别" prop="category" style="font-weight: 700;">
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="goodsForm.category"
            :value="goodsForm.category"
            dict-key="articleCategory"
            select-type="NORMAL"
        ></dict-select>
      </FormItem>
      <FormItem label="商品分类" prop="type" style="font-weight: 700;">
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="goodsForm.type"
            :value="goodsForm.type"
            dict-key="goodsType"
            select-type="NORMAL"
        ></dict-select>
      </FormItem>
      <FormItem label="文章封面" prop="coverImgUrl" style="font-weight: 700;">
        <p>
          <span style="color: #909399; font-size: 12px">只能上传jpg/png文件，图片大小不超过5M</span>
        </p>
        <upload-return-url
            :have-img-url="goodsForm.coverImgUrl"
            @uploadControl="handleUploadImg"
        ></upload-return-url>
      </FormItem>
      <FormItem label="价格" prop="price" style="font-weight: 700;">
        <InputNumber v-model="goodsForm.price" />
      </FormItem>
      <FormItem label="排序" prop="order" style="font-weight: 700;">
        <InputNumber v-model="goodsForm.order" />
      </FormItem>
      <FormItem label="有效期/天" prop="periodOfValidity" style="font-weight: 700;">
        <InputNumber v-model="goodsForm.periodOfValidity" />
      </FormItem>
      <FormItem label="详情" prop="detail" style="font-weight: 700;">
        <Input style="width: 420px;" :rows="4" v-model="goodsForm.detail" type="textarea" placeholder="请输入商品详情" />
      </FormItem>

      <FormItem>
        <Button type="primary" @click="handleSubmit('goodsFormValidate')">保存</Button>
        <Button @click="handleReset('goodsFormValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>

    </Form>
  </div>
</template>

<script>
import UploadReturnUrl from "../../../components/upload/uploadReturnUrl";
import DictSelect from "../../../components/DictSelect/DictSelect";
import TEditor from "../../../components/TEditor/TEditor";

export default {
  name: "MsGoodsAddOrEdit",
  components: { UploadReturnUrl, DictSelect, TEditor },
  data() {
    return {
      goodsForm: {
        name: '',
        category: '',
        type: '',
        coverImgUrl: '',
        price: null,
        order: null,
        periodOfValidity: null,
        detail: '',
      },
      goodsRule: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        category: [{ required: true, message: '请选择商品类别', trigger: 'change' }],
        type: [{ required: true, message: '请选择商品分类', trigger: 'change' }],
        coverImgUrl: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        price: [{ required: true, type: 'number', message: '请输入商品价格', trigger: 'blur' }],
        order: [{ required: true, type: 'number', message: '请输入商品排序', trigger: 'blur' }],
        periodOfValidity: [{ required: true, type: 'number', message: '请输入商品有效期', trigger: 'blur' }],
        detail: [{ required: true, message: '请输入商品详情', trigger: 'blur' }]
      },
    }
  },
  methods: {
    // 上传商品封面图
    handleUploadImg(file) {

      if (file) {
        this.fileId = file.uid;
        this.goodsForm.coverImgUrl = file.response.body;
      }

    },

    // 保存
    handleSubmit(name) {

      this.$refs[name].validate((valid) => {

        if (valid) {
          this.$Message.success('可以提交啦！')
          console.log('goodsForm=====>', this.goodsForm)
        } else {
          this.$Message.error('Fail!');
        }

      })
    },
    // 取消
    handleReset(name) {
      this.$refs[name].resetFields();
      this.$router.push({ path: '/ms/goods/list' })
    }
  }
}
</script>

<style scoped>

</style>
