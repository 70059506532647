<template>

  <i-select
      :clearable="item.clearable"
      :filterable="item.filterable"
      :multiple="item.multiple"
      :disabled="isDisabled"
      v-model="selectValue"
      :placeholder="item.placeholder"
      @on-change="onChange"
  >
    <Option v-for="(option,index) in item.items" :value="option.value" :key="option.value">
      {{ option.value }}
    </Option>
  </i-select>

</template>

<script type="application/javascript">

export default {
  props: {
    viewValue: {
      type: [Boolean],
      default: false
    },
    value: {
      type: [String, Number, Array],
      default: null
    },
    dictKey: {
      type: String,
      default: null
    },
    selectType: {
      type: String,
      default: "NORMAL"
    },
    defaultValue: {
      type: String,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {
          clearable: true,
          filterable: false,
          multiple: true,
          placeholder: '',
        }
      }
    }
  },
  data() {
    return {
      selectValue: [],
      item: {
        clearable: true,
        filterable: false,
        multiple: true,
        placeholder: '请选择',
        items: []
      },
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue, oldValue) {
        console.log('newValue===>', newValue)
        this.selectValue = newValue;
      }
    },
    dictKey: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.item.items = []
          console.log('dictkey===> items==>', this.item.items)
          return
        }
        console.log('dictkey===> items==>', this.item.items)

        if (this.dictKey != 'source' && this.dictKey != 'subject') {
          if (oldVal === null) {
            this.queryDict();
          } else if ((newVal !== oldVal)) {
            this.queryDict();
            this.selectValue = [];
            this.$emit('on-change', this.selectValue);
          }
        } else {
          if (newVal != oldVal) {
            // console.log('dictKey===>')
            this.queryDict()
          }
        }

      }
    },

  },
  methods: {

    queryDict() {
      console.log('this.dictKey===>', this.dictKey)
      console.log('this.selectType===>', this.selectType)
      let requestVo = {
        typeName: this.dictKey,
        selectType: this.selectType
      }
      if (this.dictKey) {
        this.$axios({
          url: '/api/codetype/typeName',
          method: 'post',
          data: requestVo
        }).then(res => {
          console.log('res ==>', res)
          this.item.items = res.body
          console.log('this.item.items==>', this.item.items)

          this.selectValue = this.value;

        })
      } else {
        window.location.reload();
      }

    },

    onChange(value) {
      console.log('open open open====>');
      console.log('multiple value ===>', value)

      this.$emit("on-change", value)

    },

  },

  mounted() {
    console.log(this.isDisabled);
  }
}
</script>
