<template>
  <i-select
      :clearable="item.clearable"
      :filterable="isFilterable"
      :multiple="item.multiple"
      :disabled="isDisabled"
      v-model="selectValue"
      :placeholder="item.placeholder"
      @on-change="onChange"
      @on-clear="onClearClick"
      @on-open-change="onOpenChange"
  >
    <Option v-if="!viewValue" v-for="(option,index) in item.items" :value="option.value" :key="'o'+index">
      {{ option.name }}
    </Option>
    <Option v-if="viewValue" v-for="(option,index) in item.items" :value="option.name" :key="'o'+index">
      {{ option.name }}
    </Option>
  </i-select>

</template>

<script type="application/javascript">

  export default {
    props: {
      viewValue: {
        type: [Boolean],
        default: false
      },
      value: {
        type: [String, Number, Array],
        default: null
      },
      dictKey: {
        type: String,
        default: null
      },
      multiple: {
        type: [Boolean],
        default: false
      },
      selectType: {
        type: String,
        default: "NORMAL"
      },
      defaultValue: {
        type: String,
        default: ""
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isFilterable: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: function () {
          return {
            clearable: true,
            filterable: false,
            multiple: false,
            placeholder: '',
          }
        }
      }
    },
    data() {
      return {
        selectValue: this.value,
        item: {
          name: 'test',
          clearable: true,
          filterable: false,
          multiple: false,
          placeholder: '请选择',
          items: []
        },
      }
    },
    watch:{
      value: {
        immediate:true,
        handler: function (newValue, oldValue) {
          this.selectValue= newValue
        }
      },
      dictKey:{
        immediate:true,
        handler: function (newVal, oldVal) {
          if (!newVal) {
            this.item.items = []
            return
          }
          if (newVal != oldVal) {
            this.queryDict()
          }
        }
      },
    },
    methods: {
      onClearClick() {
        this.$emit("input", '');
        this.$emit('on-change', '');
        this.$emit("on-clear");
      },
      onOpenChange() {
        if (this.item.items.length <= 0) {
          this.queryDict()
        }
      },
      queryDict() {
        let requestVo = {
          typeName: this.dictKey,
          selectType: this.selectType
        }
        if (this.dictKey) {
          this.$axios({
            url: '/api/codetype/typeName',
            method: 'post',
            data: requestVo
          }).then(res => {
            this.item.items = res.body
            if(this.value){
              // console.log('this.value ===>', this.value)
              this.selectValue = this.value
            } else {
              // console.log('this.defaultValue===>', this.defaultValue)
              if (this.defaultValue === 'show') {

                this.selectValue = this.item.items[0].name
                // console.log('this.selectValue===>', this.selectValue)
                this.$emit('on-change', this.item.items[0].name)
              }
            }
          })
        } else {
          // 不应该刷新呀，为啥之前需要reload页面啊
          // window.location.reload();
        }
      },
      onChange(value) {
        console.log('value ====>', value)
        this.item.items.forEach(item => {
          if (item.value === value) {
            this.$emit("on-item", item)
            this.$emit("on-change", value)
            this.$emit("input", value)
          }
        })

        console.log('this.viewValue ====>', this.viewValue)
        if (this.viewValue) {
          this.item.items.forEach(item => {
            if (item.name === value) {
              this.$emit("on-item", item)
              this.$emit("on-change", value)
              this.$emit("input", value)
            }
          })
        }

      }
    },
  }
</script>
