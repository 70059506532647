<template>
<!-- 权限资源列表 -->
  <div class="rules-resource">
    <div>
      <Button type="primary" @click="handleAddPermission">新建权限</Button>
    </div>
    <Table
        border style="margin-top: 15px;"
        class="studentRoleTable"
        highlight-row
        :loading="loading"
        ref="selection"
        :columns="columns"
        :data="rulesTableData"
    >
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>


    <!--  添加权限资源的Modal  -->
    <Modal
        class-name="studentRoleModal"
        title="官网权限资源管理"
        v-model="permissionModal"
    >
      <Form ref="permissionModal" :model="formValidate" :rules="ruleValidate" :label-width="80">
        <FormItem label="权限名称" prop="resourcesName">
          <Input v-model="formValidate.resourcesName" placeholder="请输入权限名称"></Input>
        </FormItem>
        <FormItem label="权限描述" prop="description">
          <Input v-model="formValidate.description" placeholder="请输入权限描述"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="default" @click="cancelPermission">取消</Button>
        <Button type="primary" @click="okPermission('permissionModal')">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "PermissionResource",
  data () {
   return {
     loading: false,
     rulesTableData: [],
     permissionModal: false,
     formValidate: {
       resourcesName: '',
       description: '',
     },
     ruleValidate: {
       resourcesName: [
         { required: true, message: '权限名称为必填', trigger: 'blur' }
       ],
     },
     currentId: null,

     //分页字段
     pageData: {
       page: 1,
       size: 10,
       total: 0,
       prop: 'id', //默认排序字段
       direction: 'DESC' //默认排序方向
     },
     columns: [
       {
         title: '编号',
         type: 'index',
         minWidth: 80,
         align: 'center',
       },
       {
         title: '权限名称',
         key: 'resourcesName',
         minWidth: 160,
         align: 'center',
       },
       {
         title: '权限描述',
         key: 'description',
         minWidth: 160,
         align: 'center'
       },
       {
         title: '操作',
         key: 'action',
         minWidth: 260,
         align: 'center',
         render: (h, params) => {
           return h('Button', {
             style: {
               marginRight: '5px'
             },
             props: {
               type: 'primary',
               size: 'small',
               ghost: true,
             },
             on: {
               click: () => {
                 this.permissionModal = true;
                 this.currentId = params.row.id;
                 this.formValidate = params.row
               }
             }
           }, '编辑')
         }
       }
     ],
   }
  },
  methods: {
    query(page = 1, size = 10) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
      }
      this.$axios.post('/api/student/role/resources/page', {}, { params: pageObj }).then(res => {
        if (res.code === '10000') {
          console.log('res===>', res.body)
          this.rulesTableData = res.body.content;
          this.pageData.total = res.body.totalElements;
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handleAddPermission() {
      this.permissionModal = true;
    },
    cancelPermission() {
      this.permissionModal = false;
      this.formValidate = {
        resourcesName: "",
        description: "",
      }
    },
    okPermission(name) {
      let formValidate = this.formValidate;
      let requestVo = {
        resourcesName: formValidate.resourcesName,
        description: formValidate.description,
      };
      let id = this.currentId;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      this.$refs[name].validate(valid => {
        if (valid) {
          this.$axios.post('/api/student/role/resources/handle', requestVo, {}).then(res => {
            if (res.code === '10000') {
              this.permissionModal = false;
              this.$Message.success('新建权限资源成功');
              this.formValidate = {
                resourcesName: "",
                description: "",
              }
              this.currentId = null;
              this.query(this.pageData.page, this.pageData.size);
            }
            console.log('res===>', res)
          }).catch(() => {
            this.permissionModal = false;
            this.$Message.error('新建权限资源失败');
          })
        }
      })

    },
    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  },
  mounted() {
    this.query(this.pageData.page, this.pageData.size);
  }
}
</script>

<style scoped>

</style>