<template>
<!-- 套题管理列表 -->
  <div>
    <div class="books-list-top">
      <div style="display: flex;align-items: center">
        <Button type="primary" @click="addBooks" shape="circle" style="margin-right: 10px; width: 100px;">新建套题</Button>
      </div>
      <div style="display: flex; align-items: flex-start">
        <Input style="width: 300px; margin-right: 10px" v-model="queryDataForm.keywords"
               search enter-button="搜索" placeholder="请输入名称"
               @on-search="search"
        />
        <Button type="default" @click="handleReset">重置</Button>
      </div>
    </div>

    <Table border style="margin-top: 15px;"
           highlight-row
           :loading="loading"
           ref="selection"
           :columns="columns"
           :data="dataObject">
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

    <relevance-topic-modal
        :isShow="relevanceTopicModal"
        :currentId="currentId"
        @on-refresh="refresh"
        @isShow="isShow"
    >
    </relevance-topic-modal>

  </div>

</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';
import RelevanceTopicModal from "./RelevanceTopicModal";

export default {
  name: "ExamList",
  components: {
    'dict-select': DictSelect,
    'relevance-topic-modal': RelevanceTopicModal,
  },
  watch: {
    currentId: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.bookId = newValue;
        this.currentId = newValue;
      }
    },
  },
  data() {
    return {
      loading: true,
      // 搜索参数
      queryDataForm:{
        keywords: null,
      },
      currentId: null,
      publishArr: [{
        isPublish: 0,
        label: '上架'
      }, {
        isPublish: 1,
        label: '下架'
      }],
      relevanceTopicModal: false,
      // 数据
      dataObject: [],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      // 列表数据
      columns: [
        {
          title: '编号',
          type: 'index',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '名称',
          key: 'bookName',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '分类',
          key: 'bookCategory',
          minWidth: 120,
          align: 'center',
        },
        // {
        //   title: '题目数量',
        //   key: 'topicNumber',
        //   minWidth: 120,
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('div', {
        //
        //     }, params.row.topicNumber ? params.row.topicNumber : 0)
        //   }
        // },
        {
          title: '封面图片',
          key: 'fileUrl',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            return h('img', {
              attrs: {
                src: params.row.fileUrl,
                style: 'width: 100px;height:calc(48px - 4px);object-fit:contain;margin:2px 0;'
              },
            })
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 260,
          align: 'center',
          render: (h,params) => {
            let isPublish = params.row.isPublish;
            let show = isPublish === 0 ? this.publishArr[0].label : this.publishArr[1].label;
            let publishId =  isPublish === 0 ? this.publishArr[1].isPublish : this.publishArr[0].isPublish;
            return h('div',[
                  h('Button', {
                    style: {
                      margin: '5px 5px 5px 0',
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                    },
                    on: {
                      click: () => {
                        this.$router.push({
                          path:`/content/exam/ExamAddOrEdit`
                        });
                        this.$storage.set('ExamAddOrEdit', 'id', params.row.id);
                      }
                    }
                  }, '编辑'),
                  h('Button', {
                    style: {
                      margin: '5px 5px 5px 0',
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                    },
                    on: {
                      click: () => {
                        this.$router.push({
                          path:`/content/exam/CheckTopic`
                        });
                        this.$storage.set('ExamAddOrEdit', 'id', params.row.id);
                        this.$storage.set('ExamList', 'page', this.pageData.page);
                      }
                    }
                  }, '查看题目'),
                  h('Button', {
                    style: {
                      margin: '5px 5px 5px 0',
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                      ghost: !!isPublish
                    },
                    on: {
                      click: () => {
                        let url = '/api/book/publish/' + params.row.id + '/' + publishId;
                        let requestVo = {};

                        this.$Modal.confirm({
                          title: '提示',
                          content: '<p>您确定' +  show + '该套题吗？</p>',
                          onOk: () => {
                            this.$axios.post(url, requestVo, {})
                                .then(res => {
                                  console.log('res ==>',res)
                                  if(res.code === '10000') {
                                    this.$Message.success('发布成功');
                                    this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                                  }
                                })
                                .catch(error => {
                                  this.$Message.error(show + '失败');
                                })
                          },

                          onCancel: () => {
                            this.$Message.warning("您已经取消了" + show);
                          }
                        })

                      }
                    }
                  }, show),
                  h('Button', {
                    style: {
                      margin: '5px 5px 5px 0',
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                      ghost: true,
                    },
                    on: {
                      click: () => {

                        let url = '/api/book/delete/' + params.row.id;
                        let requestVo = {};

                        this.$Modal.confirm({
                          title: '提示',
                          content: '<p>确定删除该套题？</p>',
                          onOk: () => {
                            this.$axios.get(url, requestVo, {})
                                .then(res => {
                                  console.log('res ==>',res)
                                  if(res.code === '10000') {
                                    this.$Message.success('删除成功');

                                    this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                                  }
                                })
                                .catch(error => {
                                  this.$Message.error('删除失败');
                                })
                          },

                          onCancel: () => {
                            this.$Message.warning("您已经取消了删除");
                          }
                        })

                      },
                    }
                  }, '删除')
                ],
            );
          }
        }
      ]
    }
  },
  methods: {

    // 查询列表
    query(page = 1, size = 10, requestVo = {}) {
      this.loading = true
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
      }
      this.$axios.post("/api/book/page", requestVo, {params: pageObj})
          .then(res => {
            console.log('res===>', res)
            if (res.code === '10000') {
              this.dataObject = res.body.content
              this.pageData.total = res.body.totalElements
              this.loading = false
            }
          })
          .catch(error => {
            console.log('error===>', error)
            if (error.code === '10000') {
              this.dataObject = []
              this.pageData.total = 0
              this.loading = false
            }
          })
    },

    refresh() {
      this.queryDataForm = {
        keywords: null,
        subject: null,
        category: null,
      };
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    isShow(val) {
      this.relevanceTopicModal = val;
    },


    addBooks() {
      this.$router.push({
        path:'/content/exam/ExamAddOrEdit'
      });
    },
    search(val) {
      this.queryDataForm.keywords = val;
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    selectSubjectChange(param) {

      this.queryDataForm.subject = param;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
    selectCategoryChange(param) {
      if (param) {
        this.queryDataForm.category = param;
        this.query(1, this.pageData.size, this.queryDataForm);
      }
    },
    selectCategoryClearChange(param) {
      this.queryDataForm.category = param;
      this.query(1, this.pageData.size, this.queryDataForm);
    },

    // 重置 清空搜索条件
    handleReset() {
      this.queryDataForm = {
        keywords: null,
        subject: null,
        category: null,
      };
      this.query(1, this.pageData.size, this.queryDataForm);
    },


    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  },
  mounted() {

    let page = this.$storage.get('ExamList', 'page') ? this.$storage.get('ExamList', 'page') : this.pageData.page;

    this.query(page, this.pageData.size, {})

    this.$storage.remove('ExamList', 'page')
  }
}
</script>

<style lang="less" scoped>
  .books-list-top{
    display: flex;
    justify-content: space-between;
  }
</style>
