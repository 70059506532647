import Vue from 'vue'
import Router from 'vue-router'

// ueboot提供的权限管理UI界面
import ueboot from 'ueboot'

// import HelloWorld from '../views/HelloWorld'
import StudentInfo from "../views/student/self/StudentInfo";
import TopicList from "../views/content/topic/TopicList";
import CodeType from "../views/system/code/CodeType"
import StudentLabel from "../views/student/label/StudentLabel";
import StudentAddOrEdit from "../views/student/self/StudentAddOrEdit";
import StudentDetail from "../views/student/self/StudentDetail";
import TopicAddOrEdit from "../views/content/topic/TopicAddOrEdit";
import ExamList from "../views/content/exam/ExamList";
import ExamAddOrEdit from "../views/content/exam/ExamAddOrEdit";
import MessageCenter from "../views/notice/message/MessageCenter";
import CommentManage from "../views/notice/comment/CommentManage";
import StudentRoleManage from "../views/student/role/StudentRoleManage";
import PermissionResource from "../views/student/permission/PermissionResource";
import FeedbackList from "../views/notice/feedback/FeedbackList";
import ArticleList from "../views/content/article/ArticleList";
import ArticleLabel from "../views/content/article/ArticleLabel";
import ArticleAddOrEdit from "../views/content/article/ArticleAddOrEdit";
import BannerList from "../views/content/banner/BannerList";
import BannerAddOrEdit from "../views/content/banner/BannerAddOrEdit";
import CheckTopic from "../views/content/exam/CheckTopic";
import ExamRole from '../views/student/examRole/ExamRole';
import MsArticleList from '../views/master/msFAQ/MsArticleList';
import MsArticleAddOrEdit from '../views/master/msFAQ/MsArticleAddOrEdit';
import MsArticleLabel from '../views/master/msFAQ/MsArticleLabel';
import MsUserList from '../views/master/msUser/MsUserList';
import MsGoodsList from '../views/master/msGoods/MsGoodsList';
import MsOrderList from '../views/master/msOrder/MsOrderList';
import MsGoodsAddOrEdit from '../views/master/msGoods/MsGoodsAddOrEdit';
import InquiryFormList from '../views/form/InquiryFormList';
import TaskList from '../views/studyPlan/taskList/TaskList';
import SubtaskList from '../views/studyPlan/subtask/SubtaskList';
import SubtaskAddOrEdit from '../views/studyPlan/subtask/SubtaskAddOrEdit';
import SubtaskCheckTopic from '../views/studyPlan/subtask/SubtaskCheckTopic';
import GreTopicManage from '../views/gre/topic/TopicManage';
import GreTopicAddOrEdit from '../views/gre/topic/TopicAddOrEdit';
import GreStudentManage from '../views/gre/student/StudentManage';
import GreStudentAddOrEdit from '../views/gre/student/StudentAddOrEdit';
import GreFeedback from '../views/gre/feedback/GreFeedback';

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


/**
 * 登录路由
 */
const LoginRouter = {
    path: '/login',
    name: 'login',
    component: ueboot.PageLogin
}

// /**
//  * 自定义业务页面
//  */
// const CustomerRouter = {
//     path: '/helloWorld',
//     name: 'HelloWorld',
//     component: HelloWorld
// }

const StudentRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            // 学员管理
            path: '/student/self/StudentInfo',
            name: 'StudentInfo',
            component: StudentInfo
        },
        {
            // 新建 / 编辑 学员
            path: '/student/self/StudentInfo/StudentAddOrEdit',
            name: 'StudentAddOrEdit',
            component: StudentAddOrEdit
        },
        {
            // 学员详情
            path: '/student/self/StudentInfo/StudentDetail',
            name: 'StudentDetail',
            component: StudentDetail
        },
        {
            // 学员标签管理
            path: '/student/label/StudentLabel',
            name: 'StudentLabel',
            component: StudentLabel
        },
        {
            // 官网角色管理
            path: '/student/role/StudentRoleManage',
            name: 'StudentRoleManage',
            component: StudentRoleManage
        },
        {
            // 权限资源
            path: '/student/permission/PermissionResource',
            name: 'PermissionResource',
            component: PermissionResource
        },
        {
            // 套题角色
            path: '/student/examRole/ExamRole',
            name: 'ExamRole',
            component: ExamRole
        }
    ]
}

const ContentRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            // 套题管理
            path: '/content/exam/ExamList',
            name: 'ExamList',
            component: ExamList
        },
        {
            // 新建 / 编辑套题
            path: '/content/exam/ExamAddOrEdit',
            name: 'ExamAddOrEdit',
            component: ExamAddOrEdit
        },
        {
            // 选择 / 查看 套题下面的题目
            path: '/content/exam/CheckTopic',
            name: 'CheckTopic',
            component: CheckTopic
        },
        {
            // 题目管理
            path: '/content/topic/TopicList',
            name: 'TopicList',
            component: TopicList
        },
        {
            // 新建 / 编辑题目
            path: '/content/topic/TopicAddOrEdit',
            name: 'TopicAddOrEdit',
            component: TopicAddOrEdit
        },
        {
            // 文章管理
            path: '/content/article/ArticleManage',
            name: 'ArticleList',
            component: ArticleList
        },
        {
            // 新建|编辑 文章
            path: '/content/article/ArticleAddOrEdit',
            name: 'ArticleAddOrEdit',
            component: ArticleAddOrEdit,
        },
        {
            // 文章标签
            path: '/content/article/ArticleLabel',
            name: 'ArticleLabel',
            component: ArticleLabel
        },
        {
            // 轮播管理
            path: '/content/banner/BannerManage',
            name: 'BannerList',
            component: BannerList
        },
        {
            // 新建|编辑 轮播图
            path: '/content/banner/BannerAddOrEdit',
            name: 'BannerAddOrEdit',
            component: BannerAddOrEdit
        }
    ]
}

const NoticeRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            //  消息管理
            path: '/notice/message/MessageCenter',
            name: 'MessageCenter',
            component: MessageCenter
        },
        {
            //    留言管理
            path: '/notice/comment/CommentManage',
            name: 'CommentManage',
            component: CommentManage
        },
        {
            // 反馈列表
            path: '/notice/feedback/FeedbackList',
            name: 'FeedbackList',
            component: FeedbackList
        }
    ]
}

const MasterRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            // Master文章列表
            path: '/ms/article/list',
            name: 'MsArticleList',
            component: MsArticleList
        },
        {
            // Master新建|编辑 文章
            path: '/ms/article/addOrEdit',
            name: 'MsArticleAddOrEdit',
            component: MsArticleAddOrEdit
        },
        {
            // Master文章标签
            path: '/ms/article/label',
            name: 'MsArticleLabel',
            component: MsArticleLabel
        },
        {
            // Master用户列表
            path: '/ms/user/list',
            name: 'MsUserList',
            component: MsUserList
        },
        {
            // Master商品列表
            path: '/ms/goods/list',
            name: 'MsGoodsList',
            component: MsGoodsList
        },
        {
            // Master 新增商品
            path: '/ms/goods/addOrEdit',
            name: 'MsGoodsAddOrEdit',
            component: MsGoodsAddOrEdit
        },
        {
            // Master订单列表
            path: '/ms/order/list',
            name: 'MsOrderList',
            component: MsOrderList
        }
    ]
}

const FormRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            // 咨询表单列表
            path: '/form/list',
            name: 'InquiryFormList',
            component: InquiryFormList
        },
    ]
}

const StudyPlan = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            // 任务列表
            path: '/study/task/list',
            name: 'TaskList',
            component: TaskList
        },
        {
            // 子任务列表
            path: '/study/subtask/list',
            name: 'SubtaskList',
            component: SubtaskList
        },
        {
            // 新增|修改子任务
            path: '/study/subtask/addOrEdit',
            name: 'SubtaskAddOrEdit',
            component: SubtaskAddOrEdit
        },
        {
            // 子任务为题目的时候 绑定题目
            path: '/study/subtask/checkTopic',
            name: 'SubtaskCheckTopic',
            component: SubtaskCheckTopic
        }
    ]
}

const GreLearnRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            path: '/gre/topic/manage',
            name: 'GreTopicManage',
            component: GreTopicManage
        },
        {
            path: '/gre/topic/addOrEdit',
            name: 'GreTopicAddOrEdit',
            component: GreTopicAddOrEdit
        },
        {
            path: '/gre/student/manage',
            name: 'GreStudentManage',
            component: GreStudentManage
        },
        {
            path: '/gre/student/addOrEdit',
            name: 'GreStudentAddOrEdit',
            component: GreStudentAddOrEdit
        },
        {
            path: '/gre/feedback/manage',
            name: 'GreFeedback',
            component: GreFeedback
        }
    ]
}

const SystemRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            path: '/system/code/CodeType',
            name: 'CodeType',
            component:CodeType
        }
    ]
}

/**
 * 权限管理
 */
const UebootShiroRouter = {
    path: '/',
    component: ueboot.PageMain,
    children: [
        {
            path: '/ueboot/shiro/User',
            name: 'User',
            component: ueboot.PageShiroUser
        },
        {
            path: '/ueboot/shiro/Role',
            name: 'Role',
            component: ueboot.PageShiroRole
        },
        {
            path: '/ueboot/shiro/Resources',
            name: 'Resources',
            component: ueboot.PageShiroResources
        }
    ]
}

export default new Router({
    routes: [
        // 登录路由
        LoginRouter,
        // 权限相关路由
        UebootShiroRouter,
        SystemRouter,
        StudentRouter,
        ContentRouter,
        NoticeRouter,
        MasterRouter,
        FormRouter,
        StudyPlan,
        GreLearnRouter
    ]
})
