<!--
  @author yanzi
  @date 2024/2/27
  @Description: 上传文件组件
-->
<template>
  <div class="upload-container">
    <div class="upload-btn">
      <Upload
          type="drag"
          v-model="fileUrl"
          action="/backend/api/file/upload"
          :show-upload-list="true"
          :on-success="successUpload"
      >
        <div class="uploadBtn">
          <Icon type="md-add" size="24" />
        </div>
      </Upload>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFile",
  data() {
    return {
      fileUrl: '',
    }
  },
  methods: {
    successUpload(response, file, fileList){

      console.log('response',response)
      console.log('file',file)
      console.log('fileList',fileList)

      this.fileUrl = response.body;
      console.log('fileUrl===>', this.fileUrl)
      if(response){
        this.$emit('uploadControl', file)
      }

    }
  }
}
</script>

<style lang="less" scoped>
.upload-container{
  display: flex;
}
.show-img{
  width: 160px;
  height: 90px;
  margin-right: 10px;
  img, video{
    width: 160px;
    height: 90px;
    object-fit: cover;
  }
}
.upload-btn{
  height: 120px;
  .ivu-upload{
    width: 160px;
    height: 90px;
  }
  .uploadBtn{
    width: 160px;
    height: 90px;
    text-align: center;
    line-height: 90px;
    border: 1px dashed #c5c8ce;
  }
  img{
    width: 160px;
    height: 90px;
    object-fit: cover;
  }
}

</style>
