<!--
  @author yanzi
  @date 2023/8/4
  @Description: Master订单列表
-->
<template>
  <div>

    <Table
        border style="margin-top: 15px;"
        highlight-row
        :loading="loading"
        :columns="columns"
        :data="dataObject">
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>
  </div>
</template>

<script>
export default {
  name: "MsOrderList",
  data() {
    return {
      dataObject: [], // 数据
      loading: false,
      columns: [
        {
          title: '订单号',
          key: 'orderNumber',
          minWidth: 100,
          align: 'center',
        },
        {
          title: '手机号',
          key: 'phone',
          minWidth: 140,
          align: 'center',
        },
        {
          title: '金额',
          key: 'money',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '购买商品',
          key: 'goods',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '支付时间',
          key: '',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '创建时间',
          key: '',
          minWidth: 120,
          align: 'center'
        }
      ],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
    }
  },
  methods: {
    // 获取订单列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
        sort: this.pageData.prop + ',' + this.pageData.direction,
      }

    },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  }
}
</script>

<style scoped>

</style>
