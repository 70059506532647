<template>
<!-- 新增|编辑 轮播图 -->
 <div>
   <Form ref="formValidate" :model="bannerForm" :rules="bannerForm.isSkip === '1' ? bannerRule : bannerRule2" :label-width="100" >
     <FormItem label="文件名称" prop="fileName" style="font-weight: 700;">
       <Input v-model="bannerForm.fileName" placeholder="请输入文件名称" style="width: 280px;"></Input>
     </FormItem>

     <FormItem label="上传文件" prop="fileUrl" style="font-weight: 700;">
       <UploadFile
           @uploadControl="handleUploadFile"
       ></UploadFile>
<!--       <upload-return-url-->
<!--           :have-img-url="bannerForm.fileUrl"-->
<!--           @uploadControl="handleUploadImg"-->
<!--       ></upload-return-url>-->
     </FormItem>

     <FormItem label="文件链接" prop="fileUrl" style="font-weight: 700;">
       <Input v-model="bannerForm.fileUrl" placeholder="请输入文件链接" style="width: 280px;"></Input>
     </FormItem>

     <FormItem label="类别ID" prop="fileType" style="font-weight: 700;">
       <Input v-model="bannerForm.fileType" placeholder="请输入类别ID" style="width: 280px; margin-right: 20px;"></Input>
       <Button type="primary" @click="handleGenerateRandomNumbers">生成</Button>
     </FormItem>

     <FormItem label="是否跳转" prop="isSkip" style="font-weight: 700;">
       <RadioGroup v-model="bannerForm.isSkip">
         <Radio label="1">是</Radio>
         <Radio label="0">否</Radio>
       </RadioGroup>
     </FormItem>

     <FormItem label="跳转链接" prop="linkUrl" style="font-weight: 700;" v-show="bannerForm.isSkip === '1'">
       <Input v-model="bannerForm.linkUrl" placeholder="请输入跳转链接" style="width: 280px;"></Input>
     </FormItem>


     <FormItem label="轮播排序" style="font-weight: 700;" v-show="id">
       <Input v-model="bannerForm.dataSort" style="width: 200px;" type="number"></Input>
     </FormItem>

     <FormItem label="是否发布" prop="isPublish" style="font-weight: 700;">
       <RadioGroup v-model="bannerForm.isPublish">
         <Radio label="1">是</Radio>
         <Radio label="0">否</Radio>
       </RadioGroup>
     </FormItem>

     <FormItem>
       <Button type="primary" @click="handleSubmit('formValidate')">保存</Button>
       <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
     </FormItem>
   </Form>
 </div>
</template>

<script>
import UploadReturnUrl from "../../../components/upload/uploadReturnUrl";
import UploadFile from "../../../components/upload/UploadFile"
export default {
  name: "BannerAddOrEdit",
  components: { UploadReturnUrl, UploadFile },
  data() {
    let isTrueUrl = (rule, value, callback) => {
      console.log('this.bannerForm.isSkip====>', this.bannerForm.isSkip);
      let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/
      if (!reg.test(value)) {
        callback(new Error('请输入正确的链接'))
      } else {
        callback()
      }
    };
    let isPositiveNum = (rule, value, callback) => {
      if (value) {
        let val = value.toString()
        if (val == '') {
          callback(new Error('不能为空'));
        } else if (!/(^[1-9]\d*$)|(^\d+\.[0-9]{1,2}$)/.test(value)) {
          callback(new Error('请输入正数，最多输入两位小数'));
        } else callback();
      } else callback(new Error('不能为空'));
    };

    return {
      // 轮播id
      id: this.$storage.get('BannerAddOrEdit', 'id'),
      bannerForm: {
        fileName: '',
        fileUrl: '',
        fileType: '',
        isSkip: '',
        linkUrl: '',
        dataSort: null,
        isPublish: '',
      },
      bannerRule: {
        fileUrl: [
          { required: true, message: '请上传文件', trigger: 'blur' }
        ],
        fileType: [
          { required: true, type: 'string', message: '请输入或者生成类别ID', trigger: 'blur' },
        ],
        linkUrl: [
          { required: true, message: '请输入跳转链接地址', trigger: 'blur' },
          { validator: isTrueUrl, trigger: 'blur' }
        ],
        // dataSort: [
        //   { required: true, message: '请输入轮播排序' },
        //   { validator: isPositiveNum }
        // ],
        isPublish: [
          { required: true, message: '请选择是否发布', trigger: 'change' }
        ]
      },
      bannerRule2: {
        fileUrl: [
          { required: true, message: '请上传文件', trigger: 'blur' }
        ],
        fileType: [
          { required: true, type: 'string', message: '请输入或者生成类别ID', trigger: 'blur' },
        ],
        // linkUrl: [
        //   { required: true, message: '请输入跳转链接地址', trigger: 'blur' },
        //   { validator: isTrueUrl, trigger: 'blur' }
        // ],
        // dataSort: [
        //   { required: true, message: '请输入轮播排序' },
        //   { validator: isPositiveNum }
        // ],
        isPublish: [
          { required: true, message: '请选择是否发布', trigger: 'change' }
        ]
      }
    }
  },
  methods: {

    queryDetail(id) {
      this.$axios.get(`/api/banner/details/${id}`).then(res => {
        if (res.code === '10000') {
          let data = res.body;

          this.bannerForm = {
            fileName: data.fileName,
            fileUrl: data.imgUrl,
            fileType: data.fileType,
            linkUrl: data.skipLinks,
            isSkip: data.skipLinks ? '1' : '0',
            dataSort: data.dataSort,
            isPublish: data.isPublish.toString()
          };
        }
      }).catch(error => {
        console.log('error===>', error)
      })
    },

    // 上传文件
    handleUploadFile(file) {
      this.fileId = file.uid;
      this.fileUrl = file.response.body;
      this.bannerForm.fileUrl = file.response.body;
    },

    handleGenerateRandomNumbers() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let uuid = '';

      for (let i = 0; i < 16; i++) {
        uuid += chars[Math.floor(Math.random() * chars.length)];
      }
      console.log('uuid====>', uuid)

      this.bannerForm.fileType = uuid

    },

    // 上传轮播图
    handleUploadImg(file) {

      if(file){
        this.fileId = file.uid;
        this.fileUrl = file.response.body;
        this.bannerForm.fileUrl = file.response.body;
      }

    },

    // 保存
    handleSubmit(name) {
      let requestVo = {
        fileName: this.bannerForm.fileName,
        imgUrl: this.bannerForm.fileUrl,
        fileType: this.bannerForm.fileType,
        skipLinks: this.bannerForm.linkUrl,
        isPublish: this.bannerForm.isPublish,
      }
      let url = '/api/banner/handle';

      let id = this.id;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id }, { dataSort: parseInt(this.bannerForm.dataSort) });
      }

      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$axios.post(url, requestVo, {}).then(res => {

            if (res.code === '10000') {
              this.$Message.success('保存成功');
              localStorage.setItem('isRemove', JSON.stringify(true));
              this.$storage.remove('BannerAddOrEdit', 'id');
              this.$router.push({ path: '/content/banner/BannerManage' });
            }

          }).catch(error => {
            console.log('error ===>', error);
          })
        } else {
          this.$Message.error('Fail!');
        }
      })


      console.log('requestVo====>', requestVo);
    },

    // 取消
    handleReset(name) {
      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$storage.remove('BannerAddOrEdit', 'id');
      this.$refs[name].resetFields();
      this.$router.push({ path: '/content/banner/BannerManage' })
    }

  },
  mounted() {

    let id = this.id;
    if (id) {
      this.queryDetail(id);
    }
  }
}
</script>

<style scoped>

</style>
