<!--
  @author yanzi
  @date 2024/2/27
  @Description: 「轮播管理」修改为文件资源管理
-->
<template>
  <div class="banner-container">
    <div class="banner-list-top">
      <div style="display: flex;align-items: center">
        <Button type="primary" shape="circle" @click="handleNewBanner">新增文件</Button>
      </div>
      <div style="display: flex; align-items: flex-start">
        <Input style="width: 300px; margin-right: 10px" v-model="queryDataForm.fileType"
               search enter-button="搜索" placeholder="请输入类型ID"
               @on-search="search"
        />
        <Button type="default" @click="handleReset">重置</Button>
      </div>
    </div>


    <Table border style="margin-top: 15px;"
           highlight-row
           :loading="loading"
           @on-selection-change="onSelectionChangeTable"
           ref="selection"
           :row-class-name="setRowClassName"
           :columns="columns"
           :data="dataObject">
    </Table>

    <Row style="margin-top:15px; float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>
  </div>
</template>

<script>
export default {
 name: "BannerList",
  data() {
   return {
      // 列表加载
     loading: true,
     // 选择项
     selectArr: [],
     publishArr: [{
       isPublish: 0,
       label: '上架'
     }, {
       isPublish: 1,
       label: '下架'
     }],
     // 搜索参数: 根据类型来查询
     queryDataForm:{
       fileType: null,
     },
     // 数据
     dataObject: [],
     //分页字段
     pageData: {
       page: 1,
       size: 10,
       total: 0,
       prop: 'id', //默认排序字段
       direction: 'DESC' //默认排序方向
     },
     // table列表数据
     columns: [
       {
         title: '排序',
         key: 'dataSort',
         minWidth: 20,
         align: 'center',
       },
       {
         title: '文件名称',
         key: 'fileName',
         minWidth: 20,
         align: 'center',
       },
       {
         title: '资源文件',
         key: 'src',
         minWidth: 120,
         align: 'center',
         render: (h, params) => {
           let urlType = this.getFileType(params.row.imgUrl)
           if (urlType === 'image'){
             return h('img', {
               style: {
                 width: '120px',
                 height: '80px',
                 margin: '10px 0'
               },
               attrs: {//设置属性
                 src: params.row.imgUrl
               }
             })
           } else if (urlType === 'video') {
             return h('video', {
               attrs: {
                 src: params.row.imgUrl,
                 controls: true,
                 style: 'width: 100%; height: 120px;'
               }
             });
           } else if (urlType === 'audio') {
             return h('audio', {
               attrs: {
                 src: params.row.imgUrl,
                 controls: true,
                 style: 'width: 100%;',
               }
             });
           } else {
             return h('span', params.row.imgUrl)
           }
         }
       },
       {
         title: '跳转链接',
         key: 'skipLinks',
         minWidth: 180,
         align: 'center',
       },
       {
         title: '类型ID',
         key: 'fileType',
         minWidth: 120,
         align: 'center'
       },
       {
         title: '操作',
         key: 'action',
         minWidth: 180,
         align: 'center',
         render: (h, params) => {
           let isPublish = params.row.isPublish;
           let show = isPublish === 0 ? this.publishArr[0].label : this.publishArr[1].label;
           let publishId = isPublish === 0 ? this.publishArr[1].isPublish : this.publishArr[0].isPublish;
           return h('div', [
             h('Button', {
               style: {
                 margin: '5px 5px 5px 0'
               },
               props: {
                 type: 'primary',
                 size: 'small'
               },
               on: {
                 click: () => {
                   this.$router.push({
                     path: '/content/banner/BannerAddOrEdit'
                   });
                   this.$storage.set('BannerAddOrEdit', 'id', params.row.id);
                   this.$storage.set('BannerList', 'page', this.pageData.page);
                 }
               }
             }, '编辑'),
             h('Button', {
               style: {
                 margin: '5px 5px 5px 0',
               },
               props: {
                 type: 'primary',
                 size: 'small',
               },
               on: {
                 click: () => {
                   let url = `/api/banner/ispublish/${params.row.id}/${publishId}`;


                   this.$Modal.confirm({
                     title: '提示',
                     content: '<p>您确定' + show + '该文件资源吗？</p>',
                     onOk: () => {
                       this.$axios.get(url)
                           .then(res => {
                             console.log('res ==>', res)
                             if (res.code === '10000') {
                               this.$Message.success(show + '成功');
                               this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                             }
                           })
                           .catch(error => {
                             this.$Message.error(show + '失败');
                           })
                     },

                     onCancel: () => {
                       this.$Message.warning("您已经取消了" + show);
                     }
                   })

                 }
               }
             }, show),
             h('Button', {
               style: {
                 margin: '5px 5px 5px 0'
               },
               props: {
                 type: 'primary',
                 size: 'small',
                 ghost: true,
               },
               on: {
                 click: () => {
                   let url = '/api/banner/delete/' + params.row.id;
                   this.$Modal.confirm({
                     title: '提示',
                     content: '<p>确定删除该文件吗？</p>',
                     onOk: () => {
                       this.$axios.get(url)
                           .then(res => {
                             console.log('res ==>', res)
                             if (res.code === '10000') {
                               this.$Message.success('删除成功');
                               this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
                             }
                           })
                           .catch(error => {
                             this.$Message.error('删除失败');
                           })
                     },

                     onCancel: () => {
                       this.$Message.warning("您已经取消了删除");
                     }
                   })
                 }
               }
             }, '删除')
           ])
         }
       }
     ]
   }
  },
  methods: {
   // 获取文件资源列表数据
   query(page = 1, size = 10, requestVo = {}) {
     this.pageData.page = page;
     this.pageData.size = size;
     let pageObj = {
       page: page - 1,
       size: size,
       sort: this.pageData.prop + ',' + this.pageData.direction,
     };
     this.$axios.post('/api/banner/page', requestVo, {params: pageObj}).then(res => {
       if (res.code === '10000') {
         this.dataObject = res.body.content
         this.pageData.total = res.body.totalElements
         this.loading = false
       }
     }).catch(error => {
       console.log('error ===>', error);
     })
   },

    // 根据类型ID搜索
    search() {
      this.query(1, this.pageData.size, this.queryDataForm);
    },

    // 重置 清空搜索条件
    handleReset() {
      this.queryDataForm = {
        fileType: null,
      };
      this.query(1, this.pageData.size, this.queryDataForm);
    },

    setRowClassName(row, index) {
      if (this.getFileType(row.imgUrl) === 'video' || this.getFileType(row.imgUrl) === 'audio' || this.getFileType(row.imgUrl) === 'image') {
        return 'tall-row';
      } else {
        return '';
      }
    },

    // 判断文件是否是图片、视频等
    getFileType(url) {
     if (/\.(jpg|jpeg|png|gif)$/i.test(url)) {
       return 'image'
     } else if (/\.(mp4|avi|mov)$/i.test(url)) {
       return 'video'
     } else if (/\.(mp3|wav|ogg)$/i.test(url)) {
       return 'audio'
     } else {
       return 'document'
     }
    },

    handleNewBanner() {
      this.$router.push({
        path: '/content/banner/BannerAddOrEdit',
      });
    },

    /*分页相关函数*/
    onSelectionChangeTable(selection) {
      this.selectArr = selection
    },
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  },
  mounted() {
    this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
  }
}
</script>

<style>
.tall-row{
  height: 120px;
}
.tall-row .ivu-table-cell{
  width: 100%!important;
  height: 100%!important;
  max-height: 100%!important;
}
.ivu-table .tall-row td, .ivu-table .tall-row th{
  height: 100%!important;
}
</style>

<style scoped>
.banner-list-top{
  display: flex;
  justify-content: space-between;
}
</style>
