<template>
<!-- 新建|编辑 文章 -->
  <div>
    <Form ref="formValidate" :model="articleForm" :rules="articleRule" :label-width="100" >
      <FormItem label="文章标题" prop="title" style="font-weight: 700;">
        <Input v-model="articleForm.title" placeholder="标题内容" style="width: 280px;"></Input>
      </FormItem>

      <FormItem label="文章分类" prop="category" style="font-weight: 700;">
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="articleForm.category"
            :value="articleForm.category"
            dict-key="articleCategory"
            select-type="NORMAL"
        ></dict-select>
      </FormItem>

      <FormItem label="作者" prop="author" style="font-weight: 700;">
        <Input v-model="articleForm.author" placeholder="请输入作者" style="width: 280px;"></Input>
      </FormItem>

      <FormItem label="文章摘要" prop="abstract" style="font-weight: 700;">
        <t-editor ref="editor" v-model="articleForm.abstract" height="200" />
      </FormItem>

      <FormItem label="文章详情" prop="detail" style="font-weight: 700;">
        <t-editor ref="editor" v-model="articleForm.detail" height="500" />
      </FormItem>

      <FormItem label="文章封面" prop="coverImgUrl" style="font-weight: 700;">
        <p>
          <span style="color: #909399; font-size: 12px">只能上传jpg/png文件，图片大小200*134px，且不超过5M</span>
        </p>
        <upload-return-url
            :have-img-url="articleForm.coverImgUrl"
            @uploadControl="handleUploadImg"
        ></upload-return-url>
      </FormItem>

      <FormItem label="浏览量" prop="pageViews" style="font-weight: 700;">
        <Input v-model="articleForm.pageViews" style="width: 280px;" type="number"></Input>
      </FormItem>

      <FormItem label="标签" prop="label" style="font-weight: 700;">
        <CheckboxGroup v-model="labelArr" @on-change="handleCheckLabel">
          <Checkbox :label="item.id" v-for="item in labelData" :key="item.id">{{ item.labelName }}</Checkbox>
        </CheckboxGroup>
      </FormItem>

      <FormItem>
        <Button type="primary" @click="handleSubmit('formValidate')">保存</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>

  </div>
</template>

<script>
import UploadReturnUrl from "../../../components/upload/uploadReturnUrl";
import DictSelect from "../../../components/DictSelect/DictSelect";

export default {
  name: "ArticleAddOrEdit",
  components: { UploadReturnUrl, DictSelect },
  data() {
    let isPositiveNum = (rule, value, callback) => {
      if (value) {
        let val = value.toString()
        if (val == '') {
          callback(new Error('不能为空'));
        } else if (!/(^[1-9]\d*$)|(^\d+\.[0-9]{1,2}$)/.test(value)) {
          callback(new Error('请输入正数，最多输入两位小数'));
        } else callback();
      } else callback(new Error('不能为空'));
    };

    return {
      // 文章id
      id: this.$storage.get('ArticleAddOrEdit', 'id'),
      articleForm: {
        title: '',  // 文章标题
        category: '',  // 文章分类
        author: '',  // 作者
        abstract: '',  // 文章摘要
        detail: '', // 文章详情
        coverImgUrl: '',  // 文章封面图片
        pageViews: '',  // 浏览量
        label: [], // 标签
      },
      articleRule: {
        title: [{ required: true, message: '请输入标题内容', trigger: 'blur' }],
        category: [
          { required: true, message: '请选择文章分类', trigger: 'change' }
        ],
        coverImgUrl: [
          { required: true, message: '请上传封面图片', trigger: 'blur' }
        ],
        pageViews: [
          { required: true, message: '请输入浏览量' },
          { validator: isPositiveNum }
        ],
        label: [
          { required: true, type: 'array', min: 1, message: '请至少勾选一个标签', trigger: 'change' },
        ],
      },
      // 已勾选标签数据
      labelArr: [],
      // 所有标签数据
      labelData: [],
      // 编辑时保存的已勾选标签数据
      alreadyLabel: [],
    }
  },
  methods: {

    // 获取文章标签列表
    queryArticleLabelList() {
      this.$axios.post("/api/article/label/list")
          .then(res => {
            if (res.code === '10000') {
              this.labelData = res.body
            }
          })
          .catch(error => {
            console.log('error===>', error)
          })
    },

    //  编辑时 获取文章详情接口
    queryDetail(id) {
      this.$axios.get(`/api/article/details/${id}`).then( res=> {
        if (res.code === '10000') {
          let data = res.body;

          this.articleForm = {
            title: data.articleTitle,
            category: data.articleCategory,
            author: data.author,
            abstract: data.articleBasedOn,
            detail: data.articleContent,
            coverImgUrl: data.articleCoverImgUrl,
            pageViews: data.pageViews,
            label: data.articleLabelRelationVoList,
          };

          this.alreadyLabel = data.articleLabelRelationVoList;
          let arr = [];
          data.articleLabelRelationVoList.forEach(item => {
            arr.push(item.articleLabelId)
          })
          this.labelArr = arr;
        }
      }).catch(error => {
        console.log('error ===>', error);
      })
    },

    // 上传文章封面
    handleUploadImg(file) {

      if(file){
        this.fileId = file.uid;
        this.fileUrl = file.response.body;
        this.articleForm.coverImgUrl = file.response.body;
      }

    },

    // 选择文章标签
    handleCheckLabel(data) {
      console.log('data====>', data);

      let newArr = [];

      // let label = this.alreadyLabel;
      // if(label.length > 0) {
      //
      //   let obj = {};
      //
      //   label.forEach(item => {
      //     console.log('11111')
      //
      //     if (data.indexOf(item.articleLabelId) !== -1 ) {
      //       data.forEach(i => {
      //         if (item.articleLabelId === i) {
      //           obj = item
      //           newArr.push(obj)
      //           console.log('newArr 1=====>', newArr);
      //         } else {
      //           newArr.push({
      //             id: null,
      //             articleId: this.id ? this.id : null,
      //             articleLabelId: i,
      //           });
      //           console.log('newArr 2=====>', newArr);
      //         }
      //       })
      //     } else if (data.indexOf(item.articleLabelId) === -1) {
      //       newArr.forEach(i => {
      //         if (item.articleLabelId === i.articleLabelId) {
      //           newArr.splice(i, 1);
      //         }
      //       })
      //       console.log('newArr 3=====>', newArr);
      //     }
      //   })
      //
      //   this.articleForm.label = newArr
      //
      //   console.log('newArr 编辑时=====>', newArr);
      // } else {
      //   console.log('22222')
      //   data.forEach(item => {
      //     newArr.push({
      //       id: null,
      //       articleId: this.id ? this.id : null,
      //       articleLabelId: item,
      //     });
      //   })
      //   this.articleForm.label = newArr
      //   console.log('newArr 新建时=====>', newArr);
      // }



      data.forEach(item => {
        newArr.push({
          id: null,
          articleId: this.id ? this.id : null,
          articleLabelId: item,
        });
      })
      this.articleForm.label = newArr
      console.log('newArr =====>', newArr);
    },

    // 保存
    handleSubmit (name) {


      let url = '/api/article/handle';
      let requestVo = {
        articleTitle: this.articleForm.title,
        articleCategory: this.articleForm.category,
        author: this.articleForm.author,
        articleBasedOn: this.articleForm.abstract,
        articleContent: this.articleForm.detail,
        articleCoverImgUrl: this.articleForm.coverImgUrl,
        pageViews: this.articleForm.pageViews,
        articleLabelRelationVoList: this.articleForm.label
      }

      console.log('requestVo====>', requestVo)

      let id = this.id;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      this.$refs[name].validate((valid) => {
        if (valid) {

          this.$axios.post(url, requestVo, {}).then(res => {
            if (res.code === '10000') {
              this.$Message.success('保存成功');
              localStorage.setItem('isRemove', JSON.stringify(true));
              this.$storage.remove('ArticleAddOrEdit', 'id');
              this.$router.push({ path: '/content/article/ArticleManage' });
            }
          }).catch(error => {
            console.log('error ===>', error);
          })

        } else {
          this.$Message.error('Fail!');
        }
      })
    },

    // 取消
    handleReset (name) {

      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$storage.remove('ArticleAddOrEdit', 'id');
      this.$refs[name].resetFields();
      this.$router.push({ path: '/content/article/ArticleManage' })
    }
  },
  mounted() {

    this.queryArticleLabelList();

    let id = this.id;
    if (id) {
      this.queryDetail(id);
    }
  }
}
</script>

<style scoped>

</style>
