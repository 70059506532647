<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

html,
body {
  font-size: 12px!important;
  height: 100%;
}
/* Modal样式修改 */
.ivu-modal .ivu-modal-content .ivu-modal-header{
  border-bottom: none!important;
}
.ivu-modal .ivu-modal-content .ivu-modal-footer{
  border-top: none!important;
}

/* 分割线的样式修改 */
.ivu-divider, .ivu-divider-vertical{
  width: 2px!important;
}
.ivu-divider{
  font-size: 20px!important;
  background: #2d8cf0!important;
}

.ivu-checkbox-wrapper{
  margin-bottom: 8px;
}

.ivu-upload-list{
  width: 300px;
}

/* 题目管理列表的关联Modal */
.relevanceTopicModal .ivu-modal .ivu-modal-content .ivu-modal-footer{
  margin-top: 50px!important;
}

/* 题目管理列表 正确率的Modal */
#accuracyForm .ivu-form-item{
  margin-bottom: 14px;
}

@font-face {
  font-family: 'FontAwesome';
  src: url("../static/font-awesome-4.7.0/fonts/fontawesome-webfont.woff");
}
.fontIcon {
  font-family: FontAwesome;
}

:root {
  --green: #6cefcc;
  --green-10: #EDFDF9;
  --green-20: #C8F9ED;
  --green-30: #A3F5E0;
  --green-40: #91F3DA;
  --green-50: #6cefcc;
  --green-60: #16CA9D;
  --green-70: #109372;
  --green-80: #0C6E56;
  --green-90: #06372B;
  --red: #f28874;
  --red-10: #FDEFEC;
  --red-20: #FCDFDA;
  --red-30: #F8C0B4;
  --red-40: #F5A08F;
  --red-50: #f28874;
  --red-60: #EF7057;
  --red-70: #CD3213;
  --red-80: #83200C;
  --red-90: #4B1207;

}

</style>
