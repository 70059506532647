<!--
  @author yanzi
  @date 2023/11/17
  @Description: 学习计划任务列表
-->
<template>
  <div>
    <Row>
      <Col span="16" style="display: flex;">
        <div>
          <span>分类：</span>
          <dict-select
              style="width: 240px; display: inline-block; margin-right: 10px"
              v-model="queryDataForm.category"
              :value="queryDataForm.category"
              dict-key="taskType"
              select-type="NORMAL"
              @on-change="selectTypeChange"
          ></dict-select>
          <span>标签：</span>
          <dict-select
              style="width: 180px; display: inline-block; margin-right: 10px"
              v-model="queryDataForm.label"
              :value="queryDataForm.label"
              dict-key="taskLabel"
              select-type="NORMAL"
              @on-change="selectLabelChange"
          ></dict-select>
        </div>
      </Col>
      <Col span="8">
        <div style="display: flex; justify-content: flex-end;">
          <label>
            <Input style="width: 300px; margin-right: 10px" v-model="queryDataForm.keywords"
                   search enter-button="搜索" placeholder="请输入名称"
                   @on-search="handleSearch"
            />
          </label>
          <Button type="default" @click="handleReset">重置</Button>
        </div>
      </Col>
    </Row>
    <div style="display: flex; align-items: center; margin-top: 10px;">
      <Button type="primary" @click="handleAddTask" shape="circle" style="margin-right: 10px; width: 100px;">新建任务</Button>
    </div>

    <Table border style="margin-top: 15px;"
           highlight-row
           :loading="loading"
           ref="selection"
           :columns="columns"
           :data="dataObject"
           draggable
           @on-drag-drop="handleOrder"
    >
    </Table>
    <Row style="margin-top: 15px; float: right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            :page-size-opts="[10,20,40,60,80,100]"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

    <!--  新建任务  -->
    <Modal :loading="loadingModalBtn" :mask-closable="false" :title="taskId ? '编辑任务' : '新建任务' " v-model="addOrEditTaskModal">
      <Form :model="taskFormParams" ref="taskRuleValidate" :rules="taskRuleValidate" :label-width="120">
        <FormItem label="任务名称" prop="name" style="font-weight: 700;">
          <Input v-model="taskFormParams.name" placeholder="请输入任务名称" style="width: 260px" />
        </FormItem>
        <FormItem label="任务分类" prop="category" style="font-weight: 700">
          <dict-select
              style="width: 260px;display: inline-block"
              v-model="taskFormParams.category"
              :value="taskFormParams.category"
              dict-key="taskType"
              select-type="NORMAL"
              @on-change="handleSelectTypeChange"
          ></dict-select>
        </FormItem>
        <FormItem label="任务标签" prop="label" style="font-weight: 700">
          <dict-select
              style="width: 260px;display: inline-block"
              v-model="taskFormParams.label"
              :value="taskFormParams.label"
              dict-key="taskLabel"
              select-type="NORMAL"
              @on-change="handleSelectLabelChange"
          ></dict-select>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="default" @click="handleCancelTask">取消</Button>
        <Button type="primary" :loading="taskLoading" @click="handleConfirmTask">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';
export default {
  name: "TaskList",
  components: {
    DictSelect
  },
  data () {
    return {
      queryDataForm: {
        label: null,
        keywords: '',
        category: null,
      },
      loading: false,
      taskLoading: false, // 新建任务
      // 数据
      dataObject: [],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        totalPages: 0,
        prop: 'sorted', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      // 列表数据
      columns: [
        {
          title: '编号',
          type: 'index',
          width: 80,
          align: 'center',
        },
        {
          title: 'ID',
          key: 'id',
          width: 80,
          align: 'center',
        },
        {
          title: '名称',
          key: 'name',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '排序',
          key: 'sorted',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '分类',
          key: 'category',
          minWidth: 160,
          align: 'center',
        },
        {
          title: '标签',
          key: 'label',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          minWidth: 160,
          align: 'center',
          render: (h, params) => {
            return h('div', this.$moment(params.row.createdDate).format('YYYY-MM-DD HH:mm'))
          },
        },
        {
          title: '是否发布',
          key: 'published',
          minWidth: 160,
          align: 'center',
          render: (h, params) => {
            return h('i-switch', {
              props: {
                value: params.row.published,
                size: 'small',
              },
              on: {
                'on-change': value => {
                  console.log('value=====>', value)
                  this.$axios.get(`/api/mission/publish/${params.row.id}/${value}`).then(res => {
                    if (res.code === '10000') {
                      this.$Message.success('修改成功')
                      this.queryTaskList(this.pageData.page, this.pageData.size, this.queryDataForm)
                    }
                  }).catch(error => {
                    console.log('error', error)
                  })
                }
              }
            })
          },
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 260,
          align: 'center',
          render: (h, params) => {
            return h('div',[
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    this.addOrEditTaskModal = true
                    this.taskId = params.row.id
                    this.queryDetail(params.row.id)
                  }
                }
              }, '编辑'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    this.$storage.set('task', 'id', params.row.id)
                    this.$router.push('/study/subtask/list')
                  },
                }
              }, '绑定子任务')
            ])
          }
        }
      ],
      taskId: null,
      loadingModalBtn: true,
      taskFormParams: {
        name: '',
        category: null,
        label: null,
      },
      taskRuleValidate: {
        name: [{
          required: true, message: '请输入任务名称', trigger: 'change'
        }],
        category: [{
          required: true, message: '请选择任务分类', trigger: 'change',
        }],
        label: [{
          required: true, message: '请选择任务标签', trigger: 'change',
        }]
      },
      addOrEditTaskModal: false,
    }
  },
  mounted() {
    this.queryTaskList(1, this.pageData.size, this.queryDataForm);
  },
  methods: {
    // 获取任务列表
    queryTaskList(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
        sort: this.pageData.prop + ',' + this.pageData.direction,
      }
      this.$axios.post('/api/mission/page', requestVo, { params: pageObj }).then(res => {
        if (res.code === '10000') {
          this.dataObject = res.body.content
          this.pageData.total = res.body.totalElements
          this.pageData.totalPages = res.body.totalPages
        }
      }).catch(error => {
        console.log('error ====>', error)
      })
    },
    handleAddTask() {
      this.addOrEditTaskModal = true
    },
    selectLabelChange(param) {
      this.queryDataForm.label = param;
      this.queryTaskList(1, this.pageData.size, this.queryDataForm)
    },
    selectTypeChange(param) {
      this.queryDataForm.category = param;
      this.queryTaskList(1, this.pageData.size, this.queryDataForm)
    },
    handleSearch(val) {
      this.queryDataForm.keywords = val;
      this.queryTaskList(1, this.pageData.size, this.queryDataForm)
    },
    handleReset() {
      this.queryDataForm = {
        keywords: '',
        label: null,
        category: null,
      };
      this.queryTaskList(1, this.pageData.size, this.queryDataForm);
    },
    // 拖动排序
    handleOrder(oldIndex1, newIndex1) {
      let oldIndex = parseInt(oldIndex1)
      let newIndex = parseInt(newIndex1)
      if(oldIndex  === newIndex){
        return
      }
      // 取出拖动区间数据最大的sorted值
      // let sorted = newIndex < oldIndex ? this.dataObject[newIndex].sorted : this.dataObject[oldIndex].sorted
      // 拖动之后重新排序
      const movedItem = this.dataObject.splice(oldIndex1, 1)[0];
      this.dataObject.splice(newIndex1, 0, movedItem);
      //取出变化的区间的列表数据
      let startIndex = newIndex < oldIndex ? newIndex : oldIndex
      let endIndex = (newIndex < oldIndex ? oldIndex : newIndex) + 1

      const newArr = this.dataObject.slice(startIndex,endIndex)
      // 取出拖动区间数据的sorted值，组成新的数组
      const sortedArr = newArr.map(obj => obj.sorted)
      // 给sortedArr按照从大到小的顺序排列
      const newSortedArr = sortedArr.sort((a, b) => b - a)
      // 给newArr的sorted值重新赋值
      newArr.forEach((item,index)=>{
        item.sorted = newSortedArr[index]
      })
      let newDataArr = newArr.map(obj => ({ id: obj.id, sorted: obj.sorted }));
      // 对接拖动排序接口
      this.$axios.post('/api/mission/sorted', newDataArr).then(res => {
        if (res.code === '10000') {
          this.$Message.success('拖动成功')
          this.queryTaskList(this.pageData.page, this.pageData.size, this.queryDataForm);
        }
      }).catch(error => {
        console.log('error===>', error)
      })

    },

    queryDetail(id) {
      this.$axios.get(`/api/mission/details/${id}`, {}, {})
          .then(res => {
            console.log('res====>', res);
            if(res.code === '10000') {
              let data = res.body;
              this.taskFormParams.name = data.name;
              this.taskFormParams.category = data.category;
              this.taskFormParams.label = data.label;
              console.log('this.taskFormParams.name====>', this.taskFormParams.name)
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    handleConfirmTask() {
      console.log('this.taskFormParams=====>', this.taskFormParams)
      this.taskLoading = true

      let requestVo = {
        name: this.taskFormParams.name,
        category: this.taskFormParams.category,
        label: this.taskFormParams.label,
        // published: this.taskFormParams.published,
      };

      let id = this.taskId;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      console.log('requestVo===>', requestVo)

      this.$refs.taskRuleValidate.validate(valid => {
        if (valid) {
          this.$axios.post('/api/mission/handle', requestVo, {})
              .then(res => {
                if (res.code === '10000') {
                  this.addOrEditTaskModal = false
                  this.$refs['taskRuleValidate'].resetFields(); // 重置表单数据
                  this.taskId = null
                  this.queryTaskList(this.pageData.page, this.pageData.size, this.queryDataForm);
                  this.$Message.success('保存成功');
                  this.taskLoading = false
                }
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          return false;
        }
      })

    },
    handleCancelTask() {
      console.log('cancel=====>cancel===>')
      this.$refs['taskRuleValidate'].resetFields(); // 重置表单数据
      this.taskId = null

      this.addOrEditTaskModal = false
    },
    handleSelectTypeChange(param) {
      this.taskFormParams.catagory = param
    },
    handleSelectLabelChange(param) {
      this.taskFormParams.label = param
    },
    // handleChangePublished(status) {
    //   this.taskFormParams.published = status
    // },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.queryTaskList(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.queryTaskList(1, this.pageData.size, this.queryDataForm);
    },
  }
}
</script>

<style scoped>

</style>
