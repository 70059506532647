import { render, staticRenderFns } from "./CommentManage.vue?vue&type=template&id=65a6148e&scoped=true&"
import script from "./CommentManage.vue?vue&type=script&lang=js&"
export * from "./CommentManage.vue?vue&type=script&lang=js&"
import style0 from "./CommentManage.vue?vue&type=style&index=0&id=65a6148e&lang=less&scoped=true&"
import style1 from "./CommentManage.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a6148e",
  null
  
)

export default component.exports