<!--
  @author yanzi
  @date 2023/8/4
  @Description: Master商品列表
-->
<template>
  <div>
    <div>
      <div class="header-top2">
        <Button shape="circle" type="primary" @click="addGoods" style="margin-right: 10px">新建商品</Button>
      </div>
    </div>
    <Table
        border style="margin-top: 15px;"
        highlight-row
        :loading="loading"
        :columns="columns"
        :data="dataObject">
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>
  </div>
</template>

<script>
export default {
  name: "MsGoodsList",
  data () {
    return {
      dataObject: [], // 数据
      loading: false,
      columns: [
        {
          title: '商品名称',
          key: 'productName',
          minWidth: 100,
          align: 'center',
        },
        {
          title: '封面图',
          key: '',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '类别',
          key: '',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '分类',
          key: '',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '价格',
          key: 'price',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '排序值',
          key: '',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '有效期',
          key: '',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '详情',
          key: '',
          minWidth: 100,
          align: 'center'
        }
      ],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
    }
  },
  methods: {
    // 获取商品列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
        sort: this.pageData.prop + ',' + this.pageData.direction,
      }

    },

    // 新建商品
    addGoods() {
      this.$router.push('/ms/goods/addOrEdit')
    },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  }
}
</script>

<style scoped>

</style>
