const Validation = {

    trim(obj) {
        return (`${obj}`).trim();
    },

    required(obj) {
        obj = this.trim(obj);
        if (obj.length == 0) {
            return false;
        }
        return true;
    },
};
module.exports = Validation;
