/**
 * Created by wangsui on 2021/3/28.
 */

/**
 * 便于统一处理请求和返回
 */

// import axios, {AxiosInterceptorManager, AxiosPromise, AxiosRequestConfig, AxiosResponse} from './axios';
import axios from 'axios'
import iView from 'view-design';
import config from './Config'

//标识一下是否显示了未登录提示，防止弹出多次
let showNotLogin = false

// 请求 基础默认配置
axios.defaults.timeout = 50000;
axios.defaults.baseURL = '/backend';

export default class AxiosConfig {

    static init(config) {
        axios.defaults = config;
        // 添加一个请求拦截器
        AxiosConfig.interceptors.request.use(function (config) {
            console.log('config',config)
            // 显示加载框
            if (config.showLoading === undefined || config.showLoading === true) {
                iView.LoadingBar.start();
            }
            // 标识为ajax异步请求
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            if (conf && conf.baseURL) {
                config.baseURL = conf.baseURL;
            }
            return config;
        }, function (error) {
            iView.LoadingBar.error();
            return Promise.reject(error);
        });

        // 添加一个响应拦截器
        AxiosConfig.interceptors.response.use(function (response) {
            iView.LoadingBar.finish();
            if (response.status === 200 && (response.data.code === 'OK' || response.data.code === '10000')) {
                // if (response.status === 200) {
                console.log('response.data ===>', response.data)
                return response.data.data;
            } else if (response.data.code === '401') {
                toLogin(conf)
            } else if (response.data.code === '500' || response.data.code === '700') {
                iView.Message.error({
                    content: response.data.message,
                    duration: 10,
                    closable: true
                });
            }
            iView.LoadingBar.error();
            return Promise.reject(response.data);
        }, function (error) {
            iView.LoadingBar.error();

            // 403 状态执行页面跳转，其余状态不跳转
            if (error.response.status === 403) {
                toLogin(conf)
                return Promise.reject(error.response.data)
            } else {
                iView.Message.error({
                    content: error.response.data.message ? error.response.data.message : '系统异常！', duration: 10,
                    closable: true
                });
                return Promise.reject(error.response.data);
            }
        });

        function toLogin(conf) {
            if (showNotLogin) {
                return
            }
            showNotLogin = true
            let cnf = config.getConfig()
            iView.Modal.info({
                closable: false,
                'mask-closable': false,
                title: '提示',
                content: cnf.axios.notLoginMsg,
                loading: true,
                onOk: () => {
                    setTimeout(() => {
                        showNotLogin = false
                        iView.Modal.remove();
                        if (conf !== undefined && conf.unauthorizedUrl !== undefined) {
                            window.location.href = conf.unauthorizedUrl;
                            //IE下有可能不刷新
                            if (isIE()) {
                                window.location.reload();
                            }
                        }
                    }, 200);
                }
            });
        }

        function isIE() {
            let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
            let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
            let isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
            let isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
            return (isIE || isEdge || isIE11)
        }

    }

}


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, params)
            .then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, requestVo, param = {pages: {}}) {
    return new Promise((resolve, reject) => {
        axios.post(url, requestVo, param)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {

                reject(err.data)
            })
    });
}


