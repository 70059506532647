<template>
  <div class="upload-container">
    <div class="show-img" v-if="imgUrl">
      <img v-if="isImageFile(imgUrl)" :src=imgUrl alt="">
      <video v-else controls>
        <source :src="imgUrl" type="video/mp4">
      </video>
    </div>
    <div class="upload-btn">
      <Upload
          type="drag"
          v-model="imgUrl"
          action="/backend/api/file/upload"
          :show-upload-list="false"
          :on-success="successUpload"
      >
        <div class="uploadBtn">
          <Icon type="md-add" size="24" />
        </div>
      </Upload>
    </div>
  </div>
</template>

<script>
export default {
  name: "uploadReturnUrl",
  props: {
    haveImgUrl: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      imgUrl: '',
    }
  },
  watch: {
    haveImgUrl: {
      immediate:true,
      handler: function (newValue, oldValue) {
        console.log('newValue ==== ', newValue);
        this.imgUrl = newValue;
      }
    }
  },
  methods:{

    isImageFile(filePath) {
      let ext = filePath.split('.').pop().toLowerCase()
      let imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      return imageExtensions.includes(ext)
    },

    successUpload(response, file, fileList){

      console.log('response',response)
      console.log('file',file)
      console.log('fileList',fileList)

      this.imgUrl = response.body;
      console.log('imgUrl===>', this.imgUrl)
      if(response){
        this.$emit('uploadControl', file)
      }

    }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>
.upload-container{
  display: flex;
}
.show-img{
  width: 160px;
  height: 90px;
  margin-right: 10px;
  img, video{
    width: 160px;
    height: 90px;
    object-fit: cover;
  }
}
.upload-btn{
  .ivu-upload{
    width: 160px;
    height: 90px;
  }
  .uploadBtn{
    width: 160px;
    height: 90px;
    text-align: center;
    line-height: 90px;
    border: 1px dashed #c5c8ce;
  }
  img{
    width: 160px;
    height: 90px;
    object-fit: cover;
  }
}

</style>
