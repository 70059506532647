<template>
<!-- 留言管理 -->
  <div>
    <div class="books-list-top">
      <div class="header-left">
        <div class="option">
          搜索：
          <Input v-model="queryDataForm.keywords" placeholder="请输入关键词" style="width: 200px" />
        </div>
        <div>
          <span>留言时间：</span>
          <DatePicker type="datetimerange" format="yyyy-MM-dd HH:mm"
                      v-model="dateTime"
                      @on-change="dateChange"
                      placeholder="选择开始和结束日期时间" style="width: 300px"></DatePicker>
        </div>
      </div>
      <div style="display: flex; align-items: center; ">
        <Button type="primary" @click="search" style="margin-right: 10px">查询</Button>
        <Button type="default" @click="reset">重置</Button>
      </div>
    </div>

    <div style="margin-top: 10px;">
      <Button type="primary" @click="handleChoiceness" shape="circle" style="margin-right: 10px; width: 100px">精选留言</Button>
      <Button type="primary" @click="handleCancelChoiceness" shape="circle" style="margin-right: 10px; width: 100px">取消精选</Button>
      <Button type="primary" @click="deleteComment" shape="circle" style="margin-right: 10px; width: 100px">删除留言</Button>
    </div>

    <div>
    </div>

    <Table
        class="topicListTable"
        border style="margin-top: 15px;"
        highlight-row
        :loading="loading"
        ref="selection"
        row-key="id"
        :columns="columns"
        :data="dataObject"
        @on-selection-change="onSelectionChangeTable"
        @on-expand-tree="onExpandChangeTree"
    >
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

  </div>
</template>

<script>
export default {
  name: "CommentManager",
  data() {
    let topicId = this.$storage.get('Comment', 'topicId');
    return {
      dateTime: null,
      queryDataForm: {
        topicId: topicId ? topicId : null,
        keywords: null,
        startDate: null, // 查询时  选择的留言起始时间
        endDate: null, // 查询时  选择的留言结束时间
      },
      // 列表加载
      loading: true,
      // 选择项
      selectArr: [],
      // 数据
      dataObject: [],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'subject', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      commentIds: [], // 勾选的全部数据id集合
      choicenessCommentIds: [], // 勾选的数据未精选的数据id集合
      cancelChoicenessCommentIds: [],  // 勾选的数据已精选的数据id集合
      selectArrData: [],
      // 列表数据
      columns: [
        {
          title: '',
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '题号',
          minWidth: 200,
          align: 'center',
          tree: true,
          render: (h, params) => {
            let topic = params.row.subject + ' - ' + params.row.topicSource + ' - ' + params.row.topicNumber;
            return h('span', topic)
          }
        },
        {
          title: '姓名/昵称',
          key: 'studentName',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '留言内容',
          key: 'content',
          minWidth: 200,
          align: 'center',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '留言时间',
          key: 'createdDate',
          minWidth: 180,
          align: 'center',
          render: (h, params) => {
            let time = this.$moment(params.row.createdDate).format('YYYY-MM-DD HH:mm:ss')
            return h('div', time)
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 200,
          align: 'center',
          render: (h, params) => {
            console.log(params.row.isSelect);
            let isSelect = params.row.isSelect;
            let str = isSelect === 0 ? '精选' : '取消精选';
            let info = isSelect === 0 ? '确定精选该留言吗？' : '确定取消该精选留言吗？';
            return h('div', [
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {

                    let type = params.row.replyStudentId ? 2 : 1;
                    let ids = [];
                    if (type === 2) {
                      ids.push(params.row.id.substring(5));
                    } else {
                      ids.push(params.row.id);
                    }
                    let url1 = `/api/comment/auslese/${type}?ids=${ids}`;
                    let url2 = `/api/comment/cancel/auslese/${type}?ids=${ids}`;
                    let url = isSelect === 0 ? url1 : url2;

                    this.$Modal.confirm({
                      title: '提示',
                      content: `<p>${info}</p>`,
                      onOk: () => {
                        this.$axios.post(url, {}, {})
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success(`${str}成功`);

                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                              }
                            })
                            .catch(error => {
                              this.$Message.error(`${str}失败`);
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning(`您已经取消了${str}留言`);
                      }
                    })

                  },
                }
              }, str),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                },
                on: {
                  click: () => {

                    let type = params.row.replyStudentId ? 2 : 1;
                    let ids = [];
                    if (type === 2) {
                      ids.push(params.row.id.substring(5));
                    } else {
                      ids.push(params.row.id);
                    }
                    let url = `/api/comment/delete/${type}?ids=${ids}`;
                    let requestVo = {};

                    this.$Modal.confirm({
                      title: '提示',
                      content: '<p>确定删除该留言？</p>',
                      onOk: () => {
                        this.$axios.post(url, requestVo, {})
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success('删除成功');

                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                              }
                            })
                            .catch(error => {
                              this.$Message.error('删除失败');
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning("您已经取消了删除");
                      }
                    })

                  },
                }
              }, '删除'),
            ])
          }
        }
      ]
    }
  },
  methods: {

    // 查询列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
      }
      console.log('pageObj ===>', pageObj)
      this.$axios.post('/api/comment/page', requestVo, {params: pageObj}).then(res => {
        if (res.code === '10000') {
          let data = res.body.content;
          data.forEach((item, index) => {
            if(item.commentReplyRespVoList != null){
              item.commentReplyRespVoList.forEach((val, index) => {
                val.id = 'reply' + val.id;
                val.topicSource = item.topicSource;
                val.topicNumber = item.topicNumber;
                val.subject = item.subject;
                val._disabled = true;
              })
            }
          })
          this.dataObject = JSON.parse(JSON.stringify(data).replace(/commentReplyRespVoList/g, 'children'))
          this.pageData.total = res.body.totalElements
          this.loading = false

        }
      }).catch(error => {
        console.log(error)
        this.loading = false;
      })

    },

    // 关键词搜索
    search() {
      this.query(1, this.pageData.size, this.queryDataForm)
    },

    // 选择 留言时间 进行查询
    dateChange(time) {
      console.log(this.dateTime);
      this.queryDataForm.startDate = this.$moment(time[0]).format();
      this.queryDataForm.endDate = this.$moment(time[1]).format();
      if (time[0] === '' && time[1] === '') {
        this.queryDataForm.startDate = null;
        this.queryDataForm.endDate = null;
      }
    },

    // 精选留言
    handleChoiceness() {

      let url = `/api/comment/auslese/1?ids=${this.choicenessCommentIds}`;
      this.$axios.post(url, {}, {}).then(res => {
        if (res.code === '10000') {
          this.$Message.success('精选成功');
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 取消精选留言
    handleCancelChoiceness() {

      let url = `/api/comment/cancel/auslese/1?ids=${this.cancelChoicenessCommentIds}`;
      this.$axios.post(url, {}, {}).then(res => {
        if (res.code === '10000') {
          this.$Message.success('取消精选成功');
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
        }
      }).catch(error => {
        console.log(error)
      })
    },

    // 删除留言
    deleteComment() {
      let url = `/api/comment/delete/1?ids=${this.commentIds}`;
      this.$axios.post(url, {}, {}).then(res => {
        if (res.code === '10000') {
          this.$Message.success('删除成功');
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
        }
      }).catch(error => {
        console.log(error)
      })
    },

    // 重置
    reset() {
      this.queryDataForm = {
        keywords: null,
        startDate: null,
        endDate: null,
      }
      this.dateTime = null
      this.query(1, this.pageData.size, this.queryDataForm);
    },

    // 列表选择
    onSelectionChangeTable(selection) {
      let selectArrData = this.$refs.selection.getSelection();
      this.selectArrData = selectArrData;

      let selectCommentIds = [];
      let choicenessCommentIds = [];
      let cancelChoicenessCommentIds = [];
      selectArrData.forEach(item => {
        selectCommentIds.push(item.id)
        if (item.isSelect === 0) {
          choicenessCommentIds.push(item.id)
        }
        if (item.isSelect === 1) {
          cancelChoicenessCommentIds.push(item.id)
        }
      })
      this.commentIds = selectCommentIds;
      this.choicenessCommentIds = choicenessCommentIds;
      this.cancelChoicenessCommentIds = cancelChoicenessCommentIds;


      this.selectArr = selection
    },

    onExpandChangeTree(	rowKey, status) {
      console.log('rowKey ===>', rowKey)
      console.log('status ===>', status)
    },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },

  },
  mounted() {
    let page = this.$storage.get('CommentManage', 'page') ? this.$storage.get('CommentManage', 'page') : this.pageData.page;
    this.query(page, this.pageData.size, this.queryDataForm)
    this.$storage.remove('CommentManage', 'page')
  },
  destroyed() {
    this.$storage.remove('Comment', 'topicId');
  }
}
</script>

<style lang="less" scoped>
.books-list-top {
  display: flex;
  justify-content: space-between;
}
.header-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-left .option{
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.topicListTable {
  overflow: initial;
}

.publishModal {
  .ivu-poptip {
    width: 100%;
  }
}

.publishModal .content {
  margin-left: 40px;
}

.publishModal .content p {
  margin-bottom: 20px;
}
</style>

<style>
.topicListTable .ivu-poptip {
  display: block;
}

.topicListTable .ivu-poptip-rel {
  display: block;
}
</style>