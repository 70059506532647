<!--
  @author yanzi
  @date 2023/11/23
  @Description: 子任务 - 套题 - 选题题目
-->
<template>
  <div>
    <Form ref="ruleValidate">
      <FormItem prop="chooseTopic">
        <Button type="primary" @click="handleOpenModal">点击选择</Button>
        <span style="font-size: 12px; margin-left: 10px; color: #faa0a0;">可上下拖动排序，得点击"确定"按钮保存</span>
        <Table
            class="checkListTable"
            style="margin: 20px 0;"
            :columns="columns"
            :loading="loading"
            :data="newDataList"
            draggable
            @on-drag-drop="changeOrder"
        >
        </Table>
      </FormItem>

      <FormItem>
        <Button type="primary" :loading="topicLoading" @click="handleSubmit">确定</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>

    <subtask-relevance-topic-modal
        :isShow="relevanceTopicModal"
        :allCheckData="newDataList"
        @on-refresh="refresh"
        @isShow="isShow"
        @check-data="handleCheckData"
    >
    </subtask-relevance-topic-modal>
  </div>
</template>

<script>
import SubtaskRelevanceTopicModal from './SubtaskRelevanceTopicModal'
export default {
  name: "SubtaskCheckTopic",
  components: {
    'subtask-relevance-topic-modal': SubtaskRelevanceTopicModal
  },
  data () {
    return {
      // 套题id - - 子任务id
      id: this.$storage.get('subtask', 'id'),
      loading: false,
      topicLoading: false, // 确定按钮 加 topicLoading 限制按钮只能点击一次
      dataList: [],
      newDataList: [],
      newDataIds: [],
      relevanceTopicModal: false,
      columns: [
        {
          title: '编号',
          type: 'index',
          minWidth: 80,
          align: 'center',
        },
        {
          title: 'ID',
          key: 'id',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '题目',
          key: 'topicTitle',
          minWidth: 460,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            let content = params.row.topicTitle
            return h('Poptip', {
              props: {
                trigger: 'hover',
                placement: 'top',
                wordWrap: true,
              }
            }, [
              h('div', {
                domProps: {
                  innerHTML: content
                },
                style: {
                  minWidth: '400px',
                  maxHeight: '48px',
                  display: '-webkit-box',
                  webkitLineClamp: 1,
                  webkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  margin: '0 auto',
                }
              }),
              h('div', {
                slot: 'content',
              }, [
                h('div', {
                  domProps: {
                    innerHTML: content
                  },
                  style: {
                    width: '360px',
                    maxHeight: '500px',
                    wordWrap: 'break-word',
                    wordBreak: 'normal'
                  }
                })
              ])
            ])
          },

          props: {
            level: {
              type: Number,
              require: true
            }
          }
        },
        {
          title: '科目',
          key: 'subject',
          width: 80,
          align: 'center',
        },
        {
          title: '考点',
          key: 'examinationSite',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '来源',
          key: 'topicSource',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '难度',
          key: 'difficulty',
          minWidth: 160,
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 180,
          align: 'center',
          fixed: 'right',
          render: (h,params) => {
            return h('div',[
                  h('Button', {
                    style: {
                      margin: '5px 5px 5px 0',
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                      ghost: true,
                    },
                    on: {
                      click: () => {

                        this.$Modal.confirm({
                          title: '提示',
                          content: '<p>确定删除该道题目吗？</p>',
                          onOk: () => {
                            // // 删除已选择的题目 已有id走删除接口 无id不走接口，删除本地数据
                            // this.$lodash.remove(this.dataList, n => {
                            //   return n.id === params.row.id
                            // })

                            this.dataList.map(item => {
                              if (item.id === params.row.id) {
                                console.log('id存在')
                                this.$axios.get(`/api/mission/task/topic/delete/${this.id}/${params.row.id}`).then(res => {
                                  if (res.code === '10000') {
                                    this.$Message.success('删除成功')
                                  }
                                }).catch(error => {
                                  console.log('error====>', error)
                                })
                              }
                            })

                            console.log('id不存在')
                            this.newDataList = this.newDataList.filter(item => {
                              return item.id !== params.row.id
                            })
                            this.newDataIds = this.newDataList.map(item => {
                              return item.id
                            })
                            console.log('this.newDataIds====>', this.newDataIds)

                          },

                          onCancel: () => {
                            this.$Message.warning("您已经取消了删除");
                          }
                        })

                      },
                    }
                  }, '删除')
                ],
            );
          }
        }
      ]
    }
  },
  mounted() {
    this.queryBindTopic()
  },
  methods: {

    queryBindTopic() {
      this.loading = true
      let url = `/api/mission/task/topic/find/topic/${this.id}`;
      this.$axios.post(url, {}, {})
          .then(res => {
            console.log('res===>', res)
            if (res.code === '10000') {
              this.dataList = res.body
              this.newDataList = res.body
              this.newDataIds = this.newDataList.map(item => {
                return item.id
              })
              this.loading = false
            }
          })
          .catch(error => {
            console.log('error===>', error)
            if (error.code === '10000') {
              this.dataList = []
              this.newDataList = []
              this.loading = false
            }
          })

    },

    // 确定
    handleSubmit() {
      this.topicLoading = true

      let url = '/api/mission/task/topic/bind';
      let requestVo = {
        taskId: this.id,
        topicIds: this.newDataIds,// 题目id集合
      };
      console.log('requestVo===>', requestVo)

      this.$refs.ruleValidate.validate(valid => {
        if (valid) {

          this.$axios.post(url, requestVo, {})
              .then(res => {
                if (res.code === '10000') {
                  this.$Message.success('保存成功');
                  localStorage.setItem('isRemove', JSON.stringify(true));
                  this.$router.push({ path:`/study/subtask/list` });
                  this.$storage.remove('subtask', 'id')
                  this.topicLoading = false
                }
              })
              .catch(error => {
                this.$Message.error('保存失败');
                this.topicLoading = false
              })

        } else {
          return false;
        }

      })

    },
    // 取消
    handleReset() {
      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$storage.remove('subtask', 'id')
      this.$router.push({ path:`/study/subtask/list` });
    },
    handleOpenModal() {
      this.relevanceTopicModal = true;
      console.log('newDataList====>', this.newDataList)
    },
    changeOrder(oldIndex, newIndex) {

      const movedItem = this.newDataList.splice(oldIndex, 1)[0];
      this.newDataList.splice(newIndex, 0, movedItem);

      this.newDataIds = this.newDataList.map(item => {
        return item.id
      })
      console.log('newDataIds======>', this.newDataIds)

    },
    refresh() {
      // this.queryDataForm = {
      //   keywords: null,
      // };
      // this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    isShow(val) {
      this.relevanceTopicModal = val;
    },
    handleCheckData(data) {
      console.log('data=====>', data)
      // this.dataList.push(...data)
      this.newDataList = data
      this.newDataIds = this.newDataList.map(item => {
        return item.id
      })
      console.log('data====>new newDataIds====>', this.newDataList)
      console.log('data====>new newDataIds====>', this.newDataIds)
    },
  }
}
</script>

<style scoped>

</style>
