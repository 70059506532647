<!--
  @author yanzi
  @date 2023/8/3
  @Description: Master 标签
-->
<template>
  <div class="label">
    <div>
      <div style="display: flex; justify-content: space-between">
        <Button type="primary" shape="circle" @click="newLabel">新建标签</Button>
        <div style="display: flex">
          <Input style="width: 300px; margin-bottom: 10px; margin-right: 10px" v-model="keywords"
                 search enter-button="搜索" placeholder="请输入名称"
                 @on-search="search"
          />
          <Button type="default" @click="refresh">重置</Button>
        </div>
      </div>
      <Modal
          v-model="modal"
          @on-ok="okLabel"
          @on-cancel="cancelLabel">
        <p slot="header" style="text-align:left">
          <span v-if="addLabel.handleStatus">新增标签</span>
          <span v-if="!addLabel.handleStatus">编辑标签</span>
        </p >
        <Input v-model="valueLabel" show-word-limit
               maxlength="12" placeholder="标签长度不得超过12" style="width: 400px" />
      </Modal>
    </div>
    <div>
      <Table border style="margin-top: 15px;"
             highlight-row
             :loading="loading"
             @on-selection-change="onSelectionChangeTable"
             ref="selection"
             :columns="columns"
             :data="dataObject">
      </Table>
      <Row style="margin-top:15px; float:right;">
        <Page :total="pageData.total"
              :page-size="pageData.size"
              :current="pageData.page"
              @on-change="pageOnChange"
              @on-page-size-change="pageSizeChange"
              show-total show-sizer show-elevator></Page>
      </Row>
    </div>
  </div>
</template>

<script>
import Validation from '../../../util/validation';
export default {
  name: "MsArticleLabel",
  data() {
    return {
      keywords: '',
      id: '',

      queryDataForm:{
        labelName:null
      },
      addLabel:{
        handleStatus: true
      },
      modal: false,
      valueLabel: '',
      // 列表加载
      loading: true,

      // 选择项
      selectArr: [],
      // 数据
      dataObject: [],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      // 列表数据
      columns: [
        {
          title: '标签名称',
          key: 'labelName',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '文章数量',
          key: 'labelBindNumber',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 200,
          align: 'center',
          render: (h,params) => {
            return h('div',[
                  h('Button', {
                    props: {
                      type: 'primary',
                      size: 'small'
                    },
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        this.modal = true;
                        this.addLabel.handleStatus = false
                        this.valueLabel = params.row.labelName;
                        this.id = params.row.id;
                      }
                    }
                  }, '编辑'),
                  h('Button', {
                    props: {
                      type: 'primary',
                      size: 'small',
                      ghost: true,
                    },
                    on: {
                      click: () => {
                        console.log(params.row.id);
                        this.deleteLabel(params.row.id);
                      }
                    }
                  }, '删除')
                ],
            );
          }
        }
      ],
    }
  },
  methods: {

    // 查询列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
      }
      this.dataObject = []
      this.loading = false
      // this.$axios.post("/api/article/label/page", requestVo, {params: pageObj})
      //     .then(res => {
      //       console.log('res===>', res)
      //       if (res.code === '10000') {
      //         this.dataObject = res.body.content
      //         this.pageData.total = res.body.totalElements
      //         this.loading = false
      //       }
      //     })
      //     .catch(error => {
      //       console.log('error===>', error)
      //     })
    },

    search() {
      console.log(this.keywords);
      this.queryDataForm.labelName = this.keywords;
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    refresh() {
      this.keywords = '';
      this.queryDataForm.labelName = this.keywords;
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    newLabel() {
      this.modal = true;
      this.addLabel.handleStatus = true
    },

    // 确定添加 或者 编辑标签
    okLabel() {
      let requestVo = {};
      requestVo = this.addLabel.handleStatus ?
          {
            labelName: this.valueLabel,
          }
          :
          {
            id: this.id,
            labelName: this.valueLabel,
          };

      if (!Validation.required(this.valueLabel)) {
        this.$Message.warning('请输入标签');
        console.log('11111111111');
        return false;
      }
      this.$axios.post('/api/article/label/handle', requestVo, {})
          .then(res => {
            if (res.code === '10000') {
              this.$Message.success(this.addLabel.handleStatus ? '添加成功' : '编辑成功');
              this.query();
              this.valueLabel = '';
            }
          })
          .catch(error => {
            this.valueLabel = '';
            this.$Message.error(error);
          });

    },

    // 删除标签
    deleteLabel(id) {
      let url = '/api/article/label/delete/' + id;
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确定删除该标签吗？</p>',
        onOk: () => {
          this.$axios.get(url, {}, {})
              .then(res => {
                console.log('res ==>', res)
                if (res.code === '10000') {
                  this.$Message.success('删除成功');
                  this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
                }
              })
              .catch(error => {
                this.$Message.error('删除失败');
              })
        },

        onCancel: () => {
          this.$Message.warning("您已经取消了删除");
        }
      })
    },

    // 取消添加标签
    cancelLabel() {
      this.$Message.info(this.addLabel.handleStatus ? '取消添加' : '取消编辑');
      this.valueLabel = '';
    },


    /*分页相关函数*/
    onSelectionChangeTable(selection) {
      this.selectArr = selection
    },
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  },
  mounted() {
    this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
  }
}
</script>

<style scoped>

</style>
