<!--
  @author yanzi
  @date 2024/5/23
  @Description: GRE题目管理
-->
<template>
  <div>
    <div class="books-list-top">
      <div style="display: flex; align-items: center">
        <div>
          <span>科目：</span>
          <dict-select
              style="width: 180px;display: inline-block; margin-right: 10px"
              v-model="queryDataForm.subject"
              :value="queryDataForm.subject"
              dict-key="greTopicSubject"
              select-type="NORMAL"
              :view-value="true"
              @on-change="selectSubjectChange"
              @on-item="selectSubjectItemChange"
          ></dict-select>
        </div>
        <div>
          <span>题型：</span>
          <dict-multiple-select
              style="width: 380px;display: inline-block; margin-right: 10px"
              v-model="questionType"
              :isDisabled="!queryDataForm.subject"
              :value="questionType"
              :dict-key="changeQuestionType"
              select-type="NORMAL"
              default-value="show"
              @on-change="selectQuestionTypeChange"
          ></dict-multiple-select>
        </div>
      </div>

      <div style="display: flex; align-items: center;">
        <Input style="width: 300px; margin-right: 10px" v-model="queryDataForm.keywords"
               search enter-button="搜索" placeholder="请输入名称"
               @on-search="search"
        />
        <Button type="default" @click="reset">重置</Button>
      </div>
    </div>
    <div style="display: flex; align-items: center; margin-top: 10px">
      <div>
        <span>类型：</span>
        <Select v-model="queryDataForm.type" placeholder="请选择题目类型" style="width: 180px; margin-right: 10px">
          <Option v-for="item in greTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div>
        <span>来源：</span>
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="queryDataForm.topicSource"
            :value="queryDataForm.topicSource"
            :isDisabled="!queryDataForm.subject"
            :dict-key="greTopicSource"
            select-type="NORMAL"
            @on-change="selectSourceChange"
        ></dict-select>
      </div>
      <div>
        <span>难度：</span>
        <dict-select
            style="width: 180px;display: inline-block;"
            v-model="queryDataForm.difficulty"
            :value="queryDataForm.difficulty"
            dict-key="topicDifficulty"
            select-type="NORMAL"
            @on-change="selectTopicDifficultyChange"
        ></dict-select>
      </div>
    </div>
    <div style="margin-top: 10px;">
      <Button type="primary" @click="addTopic" shape="circle" style="margin-right: 10px; width: 100px">新建题目</Button>
      <Button type="primary" @click="handlePublish" shape="circle" style="margin-right: 10px; width: 100px">上架</Button>
    </div>

    <div>
    </div>

    <Table
        class="topicListTable"
        border style="margin-top: 15px;"
        highlight-row
        :loading="loading"
        ref="selection"
        :columns="columns"
        :data="dataObject"
        @on-selection-change="onSelectionChangeTable"
        @on-sort-change="onSortChangeTable"
    >
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

    <!--  题目预览 Modal  -->
    <Modal v-model="topicPreviewModal" :mask-closable="false" title="题目预览" width="790">
      <Card style="width: 760px">
        <div class="topic-content">
          <div>
            <div ref="topicHtml" :class='isImgTopicTitle ? "topic-title topic-title-special" : "topic-title"' v-html="currentTopicContent.topicTitle"></div>
          </div>
        </div>
      </Card>
      <div slot="footer">
        <Button type="default" @click="topicPreviewModal=false;isImgTopicTitle=false;">取消</Button>
      </div>
    </Modal>

  </div>

</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';
import DictMultipleSelect from "../../../components/DictSelect/DictMultipleSelect";
import { computeAccuracy } from '../../../util/compute';
// import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
  name: "GreTopicManage",
  components: {
    DictSelect,
    DictMultipleSelect,
    // 'vue-ueditor-wrap': VueUeditorWrap
  },
  data() {
    let data = this.$storage.get('GreTopicList', 'queryDataForm');
    return {
      questionType: data ? data.questionType ? data.questionType : null : null,
      greTypeList: [{
        value: 'AW',
        label: 'AW'
      }, {
        value: 'Verbal',
        label: 'Verbal'
      }, {
        value: 'Quant',
        label: 'Quant'
      }], // 题目类型
      queryDataForm: {
        type: data ? data.type ? data.type : null : null,
        keywords: data ? data.keywords ? data.keywords : null : null,
        subject: data ? data.subject ? data.subject : null : null,
        examinationSite: data ? data.questionType ? data.questionType : null : null,
        topicSource: data ? data.topicSource ? data.topicSource : null : null,
        difficulty: data ? data.difficulty ? data.difficulty : null : null,
      },
      // 来源 根据 科目 联动的
      greTopicSource: null,
      // 题型 根据 科目 联动的
      changeQuestionType: null,
      // 列表加载
      loading: true,

      // 选择项
      selectArr: [],
      //题目类型参数
      roleList: {
        id: null,
        roleName: null
      },
      //题目类型返回数据
      roleListArray:[],
      // 数据
      dataObject: [],
      //当前选中的题目id
      currentTopicId: null,
      topicInfo: '', // 题目信息：科目-来源-序号
      topicPreviewModal: false,  // 是否显示 预览 的模态框
      currentTopicContent: {},  // 当前题目的信息
      isImgTopicTitle: false, // 判断题目内容有没有img图片
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        // prop: 'createdDate', //默认排序字段
        // direction: 'DESC' //默认排序方向
      },
      topicIds: [],
      selectArrData: [],
      publishArr: [{
        isPublish: 0,
        label: '上架'
      }, {
        isPublish: 1,
        label: '下架'
      }],
      selectCouldUpdate: false,   // 是否允许修改 题目题型
      // 题目类型
      topicTypeData: [],
      // 列表数据
      columns: [
        {
          title: '',
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '编号',
          type: 'index',
          minWidth: 80,
          align: 'center',
        },
        {
          title: 'ID',
          key: 'id',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '题目',
          key: 'topicTitle',
          minWidth: 460,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            let content = params.row.topicTitle
            return h('Poptip', {
              props: {
                trigger: 'hover',
                placement: 'top',
                wordWrap: true,
              }
            }, [
              h('div', {
                domProps: {
                  innerHTML: content
                },
                style: {
                  minWidth: '400px',
                  maxHeight: '48px',
                  display: '-webkit-box',
                  webkitLineClamp: 1,
                  webkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  margin: '0 auto',
                }
              }),
              h('div', {
                slot: 'content',
              }, [
                h('div', {
                  domProps: {
                    innerHTML: content
                  },
                  style: {
                    width: '360px',
                    maxHeight: '500px',
                    wordWrap: 'break-word',
                    wordBreak: 'normal'
                  }
                })
              ])
            ])
          },
          props: {
            level: {
              type: Number,
              require: true
            }
          }
        },
        {
          title: '科目',
          key: 'subject',
          width: 80,
          align: 'center',
        },
        {
          title: '题型',
          key: 'examinationSite',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '来源',
          key: 'topicSource',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '难度',
          key: 'difficulty',
          minWidth: 120,
          align: 'center',
          sortable: true,
          // sortMethod: (a, b, type) => {
          //
          //   this.pageData.direction = type.toUpperCase();
          //
          // }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 380,
          align: 'center',
          render: (h, params) => {
            let isPublish = params.row.isPublish;
            let show = isPublish === 0 ? this.publishArr[0].label : this.publishArr[1].label;
            let publishId = isPublish === 0 ? this.publishArr[1].isPublish : this.publishArr[0].isPublish;
            return h('div', [
              // h('Button', {
              //   style: {
              //     margin: '5px 5px 5px 0'
              //   },
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   on: {
              //     click: () => {
              //       this.$storage.set('Comment', 'topicId', params.row.id);
              //       this.$router.push({
              //         path: '/notice/comment/CommentManage'
              //       })
              //     }
              //   }
              // }, '查看留言'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0'
                },
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.topicPreviewModal = true;
                    console.log('topic single ====>', params.row);
                    this.currentTopicContent = params.row;
                    if (params.row.topicTitle.search(/<img /i) >= 0) {
                      this.isImgTopicTitle = true;
                    }


                  }
                }
              }, '预览'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    this.$router.push({
                      path: `/gre/topic/addOrEdit`
                    });
                    this.$storage.set('GreTopicAddOrEdit', 'id', params.row.id);
                    this.$storage.set('GreTopicList', 'page', this.pageData.page);

                    this.$storage.set('GreTopicList', 'queryDataForm', this.queryDataForm);
                  }
                }
              }, '编辑'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    let url = '/api/gre/topic/publish';
                    let requestVo = {
                      isPublish: publishId,
                      topicIds: [params.row.id],
                    };

                    console.log('requestVo====', requestVo);

                    this.$Modal.confirm({
                      title: '提示',
                      content: '<p>您确定' + show + '该题目吗？</p>',
                      onOk: () => {
                        this.$axios.post(url, requestVo, {})
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success('上架成功');
                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                              }
                            })
                            .catch(error => {
                              this.$Message.error(show + '失败');
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning("您已经取消了" + show);
                      }
                    })

                  }
                }
              }, show),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                },
                on: {
                  click: () => {

                    let url = '/api/gre/topic/delete/' + params.row.id;
                    let requestVo = {};

                    this.$Modal.confirm({
                      title: '提示',
                      content: '<p>确定删除该题目？</p>',
                      onOk: () => {
                        this.$axios.post(url, requestVo, {})
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success('删除成功');

                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                              }
                            })
                            .catch(error => {
                              this.$Message.error('删除失败');
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning("您已经取消了删除");
                      }
                    })

                  },
                }
              }, '删除')
            ])
          }
        }

      ]
    }
  },
  methods: {

    // 查询列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
        // sort: this.pageData.prop + ',' + this.pageData.direction,
      }

      this.$axios.post("/api/gre/topic/page", requestVo, {params: pageObj})
          .then(res => {
            console.log('res===>', res)
            if (res.code === '10000') {
              let resParam = res.body.content
              this.dataObject = []
              this.dataObject = res.body.content
              this.pageData.total = res.body.totalElements
              this.loading = false

            }
          })
          .catch(error => {
            console.log('error===>', error)
            if (error.code === '10000') {
              this.dataObject = []
              this.dataObject = error.body.content
              this.pageData.total = error.body.totalElements
              this.loading = false
            }
          })
    },

    // 新建题目
    addTopic() {
      this.$router.push({
        path: '/gre/topic/addOrEdit'
      });
    },

    // 批量上架
    handlePublish() {
      //  没有选择学员时点击
      if (this.topicIds.length === 0) {
        this.$Message.warning('请选择题目')
      } else {

        this.$Modal.confirm({
          title: '提示',
          content: '<p>您确定上架这些题目吗？</p>',
          onOk: () => {
            this.publishTopic();
          },

          onCancel: () => {
            this.topicIds = [];
            this.selectArrData = [];
            this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            this.$Message.warning("您已经取消了上架");
          }
        })
      }

    },

    // 上架题目
    publishTopic() {
      let url = '/api/gre/topic/publish';
      let requestVo = {
        isPublish: 1,
        topicIds: this.topicIds,
      };
      this.$axios.post(url, requestVo, {})
          .then(res => {
            console.log('res ==>', res)
            if (res.code === '10000') {
              this.$Message.success('上架成功');

              this.query(this.pageData.page, this.pageData.size, this.queryDataForm);

              this.topicIds = [];
              this.selectArrData = [];
            }
          })
          .catch(error => {
            this.$Message.error('上架失败');
          })
    },


    // 关键词搜索
    search() {
      this.query(1, this.pageData.size, this.queryDataForm);
    },
    // 科目搜索
    selectSubjectChange(param) {
      console.log('param====>', param);
      this.queryDataForm.subject = param;
      this.queryDataForm.examinationSite = null;

      this.query(1, this.pageData.size, this.queryDataForm);
    },

    selectSubjectItemChange(item) {
      console.log('item======>', item)

      this.changeQuestionType = `${item.value.toLowerCase()}GreQuestionType`;
      this.queryDataForm.examinationSite = null;

    },

    // 题型搜索
    selectQuestionTypeChange(param) {

      this.questionType = []
      this.questionType = param;
      this.queryDataForm.examinationSite = param.toString();
      this.query(1, this.pageData.size, this.queryDataForm);
    },
    // 来源搜索
    selectSourceChange(param) {
      this.queryDataForm.topicSource = param;
      //  this.query(1, this.pageData.size, this.queryDataForm);
    },
    // 难度搜索
    selectTopicDifficultyChange(param) {
      this.queryDataForm.difficulty = param;
      this.query(1, this.pageData.size, this.queryDataForm);
    },

    // 重置
    reset() {
      this.changeQuestionType = null;
      this.questionType = [];
      this.queryDataForm = {
        keywords: null,
        subject: null,
        examinationSite: null,
        topicSource: null,
        difficulty: null,
      }
      this.query(1, this.pageData.size, this.queryDataForm);
    },

    // 列表选择
    onSelectionChangeTable(selection) {
      let selectArrData = this.$refs.selection.getSelection();
      this.selectArrData = selectArrData;

      let selectStdId = [];
      selectArrData.forEach(item => {
        selectStdId.push(item.id)
      })
      this.topicIds = selectStdId;
      this.selectArr = selection
    },

    // 排序
    onSortChangeTable() {
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(pageNumber, this.pageData.size, this.queryDataForm);
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, pageSize, this.queryDataForm);
    },
  },
  mounted() {

    let page = this.$storage.get('GreTopicList', 'page') ? this.$storage.get('GreTopicList', 'page') : this.pageData.page;
    let queryDataForm = this.$storage.get('GreTopicList', 'queryDataForm') ? this.$storage.get('GreTopicList', 'queryDataForm') : this.queryDataForm;

    console.log('mounted ====>queryDataForm =====>', this.queryDataForm);
    this.query(page, this.pageData.size, queryDataForm);
    this.$storage.remove('GreTopicList', 'page')
    this.$storage.remove('GreTopicList', 'queryDataForm')

  }
}
</script>

<style lang="css" scoped>
.books-list-top {
  display: flex;
  justify-content: space-between;
}

.topicListTable {
  overflow: initial;
}

.publishModal .content {
  margin-left: 40px;
}

.publishModal .content p {
  margin-bottom: 20px;
}

.topic-content{
  position: relative;
  padding: 5px 10px 5px;
}
.topic-content .topic-title{
  text-align: left;
  white-space: pre-wrap; /* 只对中文起作用，强制换行。 */
  word-wrap: break-word; /* 只对英文起作用，以单词作为换行依据。 */
  line-height: 1.6;
  text-align: justify; /* css英文语句的两端对齐： */
  font-family: FontAwesome;
}
.topic-content .topic-title >>> div {
  white-space: normal!important;
}
.topic-content .topic-title >>> div p {
  margin: 20px 0;
}
.topic-content .topic-title >>> table.table-sorter{
  font-family: FontAwesome;
}
.topic-content .topic-title >>> table.table-sorter thead tr th{
  position: relative;
  text-align: center;
}
.topic-content .topic-title >>> table.table-sorter thead tr th:before{
  position: absolute;
  right: 5px;
}
.topic-content .topic-title >>> table.table-sorter thead tr th.ascending:before{
  font-family: FontAwesome;
  content: "\f0de";
}
.topic-content .topic-title >>> table.table-sorter thead tr th.descending:before{
  font-family: FontAwesome;
  content: "\f0dd";
}
.topic-content .topic-title >>> table.table-sorter tbody tr td {
  text-align: center!important;
}

.topic-content .topic-title >>> table.question-table{
  line-height: 1!important;
}

.topic-content .topic-title >>> table.question-table thead tr td{
  text-align: center;
}
.topic-content .topic-title >>> table.question-table td{
  height: auto;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  /*border-left: 1px solid #D2D2D2;*/
  /*border-top: 1px solid #D2D2D2;*/
}
.topic-content .topic-title >>> .vertical-choice{
  display: flex;
  justify-content: center;
}
.topic-content .topic-title >>> .vertical-choice:before{
  font-family: FontAwesome;
  background: #f6f6f6;
  border: 1px solid #d2d2d2;
  color: #353535;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
  padding: 4px;
}
.topic-content .topic-title >>> table .choice-content,
.topic-content .topic-title >>> ul.ul-options li div.choice-content
{
  margin-left: 8px;
  white-space: pre-wrap;
}
.topic-content .topic-title >>> ul.ul-options{
  white-space: nowrap;
}
.topic-content .topic-title >>> ul.ul-options li {
  width: 100%;
  display: block;
  min-height: 10px;
  list-style: disc outside;
  margin-top: 10px;
  margin-bottom: 10px;
  zoom: 1;
  margin-left: 0;
}
.topic-content .topic-title >>> ul.ul-options li:before,
.topic-content .topic-title >>> ul.ul-options li:after{
  display: table;
  content: "";
}
.topic-content .topic-title >>> ul.ul-options li:after{
  clear: both;
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice,
.topic-content .topic-title >>> ul.ul-options li div.li-choice
{
  background: #f6f6f6;;
  border: 1px solid #d2d2d2;;
  color: #353535;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 10px 4px 0;
}

.topic-content .topic-title >>> .vertical-choice.correct:before{
  background-color: var(--green-20);
  content: "\f058";
  border: 1px solid var(--green-30);
  color: var(--green-60);
}
.topic-content .topic-title >>> .vertical-choice.incorrect:before{
  background: var(--red-20);
  content: "\f057";
  border: 1px solid var(--red-30);
  color: var(--red-70);
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.correct,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.correct{
  background-color: var(--green-20);
  border: 1px solid var(--green-30);
  color: var(--green-60);
}

.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.incorrect,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.incorrect{
  background: var(--red-20);
  border: 1px solid var(--red-30);
  color: var(--red);
}
.topic-content .topic-title >>> .vertical-choice.corrected:before,
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.corrected:before,
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.correct:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.corrected:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.correct:before{
  content: "\f058";
  color: var(--green-60);
}
.topic-content .topic-title >>> .vertical-choice.incorrect:before,
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.incorrect:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.incorrect:before{
  content: "\f057";
  color: var(--red-70);
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice:first-of-type,
.topic-content .topic-title >>> ul.ul-options li div.li-choice:first-of-type{
  margin-left: 0;
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice:before
{
  font-size: 16px;
  font-family: FontAwesome;
  padding: 0 10px;
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice,
.topic-content .topic-title >>> ul.ul-options li div.li-choice,
.topic-content .topic-title >>> ul.ul-options li div.choice-content
{
  min-height: 10px;
  margin: 8px 8px 0 8px;
  display: inline-block;
  vertical-align: top;
  float: left;
}
.topic-content .topic-title >>> ul.ul-options li div.choice-content{
  min-width: 65%;
  max-width: 72%;
}
.topic-content .topic-title >>> ul.ul-options li div.choice-content.correct{
  color: var(--green);
}
.topic-content .topic-title >>> ul.ul-options li div.choice-content.incorrect{
  color: var(--red);
}
.topic-content .topic-title >>> ul.ul-options li div.li-accuracy-choice{
  background-color: var(--red);
  color: #333;
  text-align: center;
  width: 32px;
  height: 18px;
  margin-top: 14px;
  border-radius: 4px;
  line-height: 18px;
  display: inline-block;
  vertical-align: top;
  float: left;
  font-size: 14px!important;
}
.topic-content .topic-title >>> div.inline-result {
  display: inline;
  border: 1px solid #9e9e9e;
  border-radius: 3px;
  padding: 2px 10px 2px 4px;
  white-space: nowrap;
  line-height: 2em;
}
.topic-content .topic-title >>> div.inline-result+div.inline-result {
  margin-left: 3px;
}
.topic-content .topic-title >>> div.inline-result.corrected {
  background: #f6f6f6;
  border: 1px solid #d2d2d2;
  color: #353535;
}
.topic-content .topic-title >>> div.inline-result.correct {
  background: var(--green-10);
  border: 1px solid var(--green-20);
  color: var(--green-60);
}
.topic-content .topic-title >>> div.inline-result.correct:before {
  content: "\f058";
  font-family: FontAwesome;
}
.topic-content .topic-title >>> div.inline-result.incorrect {
  background: #f8d6d7;
  border: 1px solid #f1adae;
  color: #DC3235;
}
.topic-content .topic-title >>> ul.ul-options li div.li-choice.incorrect:before,
.topic-content .topic-title >>> div.inline-result.incorrect:before {
  content: "\f057";
}
.topic-content .topic-title >>> div.inline-result:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "";
  margin-right: 8px;
}
.topic-content .topic-title >>> ul.ul-options li div.li-choice.corrected:before,
.topic-content .topic-title >>> div.inline-result.corrected:before {
  content: "\f05d";
  color: var(--green-60);
}
.topic-content .topic-title >>> div.inline-result:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "";
  margin-right: 8px;
}

/* 多材料题目 */
.topic-content .topic-title >>> .tabs-group{

}
.topic-content .topic-title >>> .tabs-group .tabs-btn{
  border-radius: 3px;
  margin-left: 5px;
  padding: 5px 20px;
  font-size: 13px;
  background: none;
  color: #333;
  border: 1px solid #ddd;
}
.topic-content .topic-title >>> .tabs-group .tabs-btn.active{
  background: #909090;
}
.topic-content .topic-title >>> .tabs-content{
  white-space: normal;
}
.topic-content .topic-title >>> .content-tab{
  background: #f7f7f7;
  padding: 20px;
  color: #3c3737 !important;
  display: none;
}
.topic-content .topic-title >>> .content-tab.active{
  display: block;
}
.topic-content .topic-title >>> .tabs-content .content-tab ul {
  margin: 20px 0;
}
.topic-content .topic-title >>> .tabs-content .content-tab ul li {
  margin-left: 30px;
}


.topic-content .options{
  text-align: left;
  padding-top: 20px;
  padding-bottom: 10px;
  word-break: break-all;
  line-height: 1.6;
}
</style>

<style>
.topicListTable .ivu-poptip {
  display: block;
}

.topicListTable .ivu-poptip-rel {
  display: block;
}

.topic-content .topic-title-special p{
  display: flex;
  align-items: center;
}
</style>

