<template>
  <div class="tinymce-box-main">
    <textarea :id="tinymceId"></textarea>
  </div>
</template>

<script>

export default {
  name: 'TEditor',
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      contentValue: this.value,
      tinymceId: this.id,
    };
  },
  watch: {
    value(newValue) {
      this.contentValue = newValue;
      if (this.editor && this.editor.getContent() !== newValue) {
        this.editor.setContent(newValue);
      }
    },
    contentValue(newValue) {
      if (this.editor && this.editor.getContent() !== newValue) {
        this.editor.setContent(newValue);
      }
      this.$emit('input', newValue);
    }
  },
  methods: {
    initializeEditor() {
      tinymce.init({
        selector: `#${this.tinymceId}`,
        height: this.height,
        language_url: `/tinymce/langs/zh_CN.js`,  //引入语言包文件
        language: 'zh_CN',  //语言类型
        autoresize_max_height: 500, // 编辑区域的最大高
        menubar: true,
        fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
        // fontsize_formats: "12px 14px 16px 18px 20px 24px 36px 48px 56px 72px",
        // font_size_style_values : "xx-small,x-small,small,medium,large,x-large,xx-large",
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        plugins: [
          `advlist anchor autolink autosave code codesample
  directionality emoticons fullscreen hr image axupimgs imagetools importcss insertdatetime
  legacyoutput link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace
  spellchecker tabfocus table template textpattern visualblocks visualchars wordcount`
        ],
        toolbar:
            [`undo redo | formatselect fontselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | indent2em | outdent indent |
             blockquote removeformat | fullscreen`,
              `hr bullist numlist link | image axupimgs media | charmap	 preview anchor pagebreak insertdatetime table | kityformula-editor`],
        external_plugins: {
          'kityformula-editor': '/tinymce/plugins/kityformula-editor/plugin.min.js',
          'axupimgs': '/tinymce/plugins/axupimgs/plugin.min.js',
          'indent2em': '/tinymce/plugins/indent2em/plugin.min.js',
        },
        paste_auto_cleanup_on_paste : true,
        paste_remove_styles: true,
        paste_remove_styles_if_webkit: true,
        paste_strip_class_attributes: true,
        // paste_data_images: true, // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        // paste_webkit_styles: 'all',
        // paste_merge_formats: true,
        nonbreaking_force_tab: false,
        convert_urls: false,
        // images_upload_url: '/tinymce',
        // images_upload_base_path: '/backend',
        //想要哪一个图标提供本地文件选择功能，参数可为media(媒体)、image(图片)、file(文件)
        file_picker_types: 'media',
        //be used to add custom file picker to those dialogs that have it.
        file_picker_callback: function(cb, value, meta) {
          //当点击meidia图标上传时,判断meta.filetype == 'media'有必要，因为file_picker_callback是media(媒体)、image(图片)、file(文件)的共同入口
          if (meta.filetype == 'media'){
            // 创建一个隐藏的type=file的文件选择input
            let input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.onchange = function(){
              let file = this.files[0]; // 只选取第一个文件。如果要选取全部，后面注意做修改
              let xhr, formData;
              xhr = new XMLHttpRequest();
              xhr.open('POST', '/backend/api/file/upload');
              xhr.withCredentials = self.credentials;
              xhr.upload.onprogress = function (e) {
                // 进度(e.loaded / e.total * 100)
                // 进度(e.loaded / e.total * 100)
                let percent=e.loaded / e.total * 100;
                console.log('percent===>', percent);
              };
              xhr.onerror = function () {
                //根据自己的需要添加代码
                console.log(xhr.status);
                return;
              };
              xhr.onload = function () {
                let json;
                if (xhr.status < 200 || xhr.status >= 300) {
                  console.log('HTTP 错误: ' + xhr.status);
                  return;
                }
                json = JSON.parse(xhr.responseText);
                //假设接口返回JSON数据为{status: 0, msg: "上传成功", data: {location: "/localImgs/1546434503854.mp4"}}
                if(json.code=='10000'){
                  //接口返回的文件保存地址
                  let mediaLocation= json.body;
                  //cb()回调函数，将mediaLocation显示在弹框输入框中
                  cb(mediaLocation, { title: file.name });
                }else{
                  console.log(json.body);
                  return;
                }

              };
              formData = new FormData();
              //假设接口接收参数为file,值为选中的文件
              formData.append('file', file);
              //正式使用将下面被注释的内容恢复
              xhr.send(formData);
            }
            //触发点击
            input.click();
          }
        },
        images_upload_handler: (blobInfo, success, failure) => {

          console.log('blobInfo===>', blobInfo.blob());
          if (blobInfo.blob().name) {
            let params = new FormData();
            params.append('file', blobInfo.blob());   // blobInfo.blob();  转化为易于理解的file对象
            let config={
              headers:{
                "Content-Type":"multipart/form-data"
              }
            }
            console.log('size===>', blobInfo.blob().size/1024/1024)
            this.$axios.post('/api/file/upload', params, config).then(res => {
              if (res.code === '10000') {
                console.log('res===>', res);
                success(res.body);
              }
            }).catch(() => {
              failure("上传出错")
            })
            // if(blobInfo.blob().size/1024/1024>2){
            //   failure("上传失败，图片大小请控制在 2M 以内")
            // }else{
            //
            // }
          } else {
            return;
          }
        },
        setup: editor => {
          this.editor = editor;
          editor.on('Change', () => {
            this.contentValue = editor.getContent();
          });
          editor.on('init', () => {
            editor.setContent(this.contentValue);
          });

        },
      });
    },
    destroyEditor() {
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }
    },
    //清空内容
    clear(){
      this.contentValue = ''
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }
      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`))
    }
  },
  mounted() {
    this.initializeEditor();
  },
  beforeDestroy() {
    this.destroyEditor();
  },
}

</script>

<style scoped>

</style>


