<template>
<!-- 新增/编辑 套题 -->
  <div>
    <Form :model="formParams" ref="ruleValidate" :rules="ruleValidate" label-position="left" :label-width="120">
      <FormItem label="套题名称：" prop="examName" style="font-weight: 700">
        <Input v-model="formParams.examName" style="width: 300px"></Input>
      </FormItem>
      <FormItem label="分类：" prop="category" style="font-weight: 700">
        <dict-select
            style="width: 180px;display: inline-block"
            v-model="formParams.category"
            :value="formParams.category"
            dict-key="bookCategory"
            select-type="NORMAL"
            @on-change="selectCategoryChange"
        ></dict-select>
      </FormItem>
      <FormItem label="套题封面：" style="font-weight: 700">
        <p>
          <span style="color: #909399; font-size: 12px">500*300像素，建议图片透明度改为70%，支持png、jpg格式，小于5M</span>
        </p>
        <upload-return-url
            :have-img-url="fileUrl"
            @uploadControl="uploadImg"
        ></upload-return-url>
      </FormItem>

      <FormItem label="是否上架：" prop="isPublish" style="font-weight: 700">
        <Radio-group v-model="formParams.isPublish">
          <Radio :label="1">是</Radio>
          <Radio :label="0">否</Radio>
        </Radio-group>
      </FormItem>

      <FormItem label="是否计时：" prop="isTime" style="font-weight: 700">
        <i-switch v-model="formParams.isTime!==0" @on-change="handleChangeTime" />
        <TimePicker v-model="formParams.timeNumber" format="HH:mm" placeholder="请选择计时时长" style="width: 140px; margin-left: 20px;"></TimePicker>
      </FormItem>

      <FormItem>
        <Button type="primary" @click="handleSubmit">确定</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>


  </div>
</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';
import UploadReturnUrl from '../../../components/upload/uploadReturnUrl';

export default {
  name: "ExamAddOrEdit",
  components: {
    'dict-select': DictSelect,
    'upload-return-url': UploadReturnUrl,
  },
  data() {
    return {
      // 套题id
      id: this.$storage.get('ExamAddOrEdit', 'id'),
      formParams: {
        examName: '',
        category: '',
        isPublish: 0,
        isTime: 0,
        timeNumber: null,
      },
      fileUrl: null,
      // 表单验证
      ruleValidate: {
        examName: [{
          required: true, message: '请输入套题名称', trigger: 'blur',
        }],
        category: [{
          required: true, message: '请选择类别', trigger: 'change',
        }],
      },
    }
  },
  methods: {

    queryDetail(id) {
      this.$axios.get(`/api/book/details/${id}`, {}, {})
      .then(res => {
        console.log('res====>', res);
        if(res.code === '10000') {
          let data = res.body;
          this.formParams.examName = data.bookName;
          this.formParams.category = data.bookCategory;
          this.formParams.isPublish = data.isPublish;
          this.formParams.isTime = data.isTime;
          this.formParams.timeNumber = data.timeNumber!==0 ? this.$moment.utc(data.timeNumber).format('HH:mm') : null;

          console.log('this.formParams.isTime====>', this.formParams.isTime)
          this.fileUrl = data.fileUrl;

        }
      })
      .catch(error => {
        console.log(error)
      })

    },

    selectCategoryChange(param) {
      this.formParams.category = param;
    },
    uploadImg(file) {

      console.log('response=====> file====>', file)
      if(file){
        this.fileUrl = file.response.body;
      }

    },
    handleChangeTime(status) {
      this.formParams.isTime = status ? 1 : 0;
      console.log('this.formParams.isTime=====>', this.formParams.isTime)
    },
    handleSubmit() {

      let url = '/api/book/handle';
      console.log('this.formParams.category', this.formParams.category);
      console.log('this.formParams', this.formParams);
      let requestVo = {
        bookName: this.formParams.examName,
        bookCategory: this.formParams.category,  // 类别
        fileUrl: this.fileUrl,
        isPublish: this.formParams.isPublish,
        isTime: this.formParams.isTime,
        timeNumber: this.formParams.timeNumber ? this.$moment.duration(this.formParams.timeNumber).as('seconds')*1000 : null,
      };
      console.log('requestVo===>', requestVo)

      let id = this.id;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      this.$refs.ruleValidate.validate(valid => {
        if (valid) {
          this.$axios.post(url, requestVo, {})
              .then(res => {
                if (res.code === '10000') {
                  this.$Message.success('保存成功');
                  this.formParams.examName = ''
                  this.formParams.category = ''
                  this.formParams.isPublish = 0
                  this.formParams.isTime = 0
                  this.formParams.timeNumber = null
                  this.fileUrl = null
                  this.$router.push({ path:`/content/exam/ExamList` });
                }
              })
              .catch(error => {
                this.$Message.error('保存失败');
              })
        } else {
          return false;
        }
      })

    },
    handleReset() {
      this.$router.push({ path:`/content/exam/ExamList` });
    },

  },
  mounted() {
    let id = this.id;
    if (id) {
      this.queryDetail(id);
    }
  },
  destroyed() {
    this.$storage.remove('ExamAddOrEdit', 'id');
  }
}
</script>

<style lang="less" scoped>

</style>