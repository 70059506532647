<template>

  <div id="relevanceTopic">
    <Modal
        class-name="relevanceTopicModal"
        width="1100"
        title="关联题目"
        :mask-closable="false"
        v-model="relevanceTopic"
        @on-ok="okRelevanceTopic"
        @on-cancel="cancelRelevanceTopic">
      <div>
        <div class="books-list-top" style="display: flex; align-items: center">
          <div>
            <span>科目：</span>
            <dict-select
                style="width: 180px;display: inline-block; margin-right: 10px"
                v-model="queryDataForm.subject"
                :value="queryDataForm.subject"
                dict-key="subject"
                select-type="NORMAL"
                @on-change="selectSubjectChange"
            ></dict-select>
          </div>
          <div v-if="queryDataForm.subject !== 'Verbal' && queryDataForm.subject !== 'Quant'">
            <span>考点：</span>
            <dict-multiple-select
                style="width: 380px;display: inline-block; margin-right: 10px"
                v-model="examinationSite"
                :isDisabled="!queryDataForm.subject"
                :value="examinationSite"
                :dict-key="changeExaminationSite"
                select-type="NORMAL"
                default-value="show"
                @on-change="selectExaminationSiteChange"
            ></dict-multiple-select>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex; align-items: flex-end; margin-top: 10px">
            <div>
              <span>来源：</span>
              <dict-select
                  style="width: 180px;display: inline-block; margin-right: 10px"
                  v-model="queryDataForm.topicSource"
                  :value="queryDataForm.topicSource"
                  :isDisabled="!queryDataForm.subject"
                  :dict-key="source"
                  select-type="NORMAL"
                  @on-change="selectSourceChange"
              ></dict-select>
            </div>
            <div>
              <span>难度：</span>
              <dict-select
                  style="width: 180px;display: inline-block;"
                  v-model="queryDataForm.difficulty"
                  :value="queryDataForm.difficulty"
                  dict-key="topicDifficulty"
                  select-type="NORMAL"
                  @on-change="selectTopicDifficultyChange"
              ></dict-select>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div style="display: flex; align-items: center; ">
              <Input style="width: 300px; margin-right: 10px" v-model="queryDataForm.keywords"
                     search enter-button="搜索" placeholder="请输入名称"
                     @on-search="search"
              />
              <Button type="default" @click="reset">重置</Button>
            </div>
          </div>
        </div>
        <div>
        </div>
        <Table
            class="RelevanceTopicList"
            border style="margin-top: 15px;"
            highlight-row
            :loading="loading"
            ref="selection"
            :columns="columns"
            :data="dataObject"
            @on-select="onSelect"
            @on-select-cancel="onSelectCancel"
            @on-select-all="onSelectAll"
            @on-select-all-cancel="onSelectAllCancel"
        >
        </Table>
        <Row style="margin-top:15px;float:right;">
          <Page :total="pageData.total"
                :page-size="pageData.size"
                :current="pageData.page"
                @on-change="pageOnChange"
                @on-page-size-change="pageSizeChange"
                show-total show-sizer show-elevator></Page>
        </Row>
      </div>
    </Modal>
  </div>
</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';
import DictMultipleSelect from "../../../components/DictSelect/DictMultipleSelect";

export default {
  name: "RelevanceTopicModal",
  components: {
    'dict-select': DictSelect,
    'dict-multiple-select': DictMultipleSelect
  },
  props: {
    isShow: {
      type: [Boolean],
      default: false
    },
    allCheckData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      relevanceTopic: this.isShow,
      bookId: this.$storage.get('ExamAddOrEdit', 'id'),
      loading: true,
      examinationSite: [],
      multiple: true,
      queryDataForm: {
        keywords: null,
        subject: null,
        examinationSite: null,
        topicSource: null,
        difficulty: null,
      },
      // 来源 根据 科目 联动的
      source: null,
      // 考点 根据 科目 联动的
      changeExaminationSite: null,
      // 选择项
      selectArr: [],
      selectArrData: [],
      // 选中的数组 !!!
      selectedData: [],
      arr1: [], // 原本的
      arr2: [], // 去重后的
      // 数据
      dataObject: [],
      topicIds: [],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      // 列表数据
      columns: [
        {
          title: '',
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '编号',
          type: 'index',
          width: 80,
          align: 'center',
        },
        {
          title: '题目',
          key: 'topicTitle',
          minWidth: 500,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            let content = params.row.topicTitle
            return h('Poptip', {
              props: {
                trigger: 'hover',
                placement: 'top',
                wordWrap: true,
              }
            }, [
              h('div', {
                domProps: {
                  innerHTML: content
                },
                style: {
                  minWidth: '500px',
                  display: '-webkit-box',
                  webkitLineClamp: 1,
                  webkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  margin: '0 auto',
                }
              }),
              h('div', {
                slot: 'content',
              }, [
                h('div', {
                  domProps: {
                    innerHTML: content
                  },
                  style: {
                    maxWidth: '400px',
                    maxHeight: '400px',
                    wordWrap: 'break-word',
                    wordBreak: 'normal'
                  }
                })
              ])
            ])
          },
          props:{
            level:{
              type:Number,
              require:true
            }
          }
        },
        {
          title: '科目',
          key: 'subject',
          width: 80,
          align: 'center',
        },
        {
          title: '考点',
          key: 'examinationSite',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '来源',
          key: 'topicSource',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '难度',
          key: 'difficulty',
          minWidth: 120,
          align: 'center',
        },
      ]
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.relevanceTopic = newValue
        if (this.relevanceTopic) {
          this.query(1, this.pageData.size, {})
        }
      }
    },
    allCheckData: {
      immediate: true,
      handler: function (newVal, oldVal) {
        console.log('this.allCheckData====>', this.allCheckData)
        this.selectedData = this.allCheckData
      }
    }

  },
  methods: {

    // 查询列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
      }

      this.$axios.post("/api/topic/page", requestVo, {params: pageObj})
          .then(res => {
            console.log('res===>', res)
            if (res.code === '10000') {
              this.dataObject = res.body.content
              this.pageData.total = res.body.totalElements
              this.loading = false

              this.updateChecked()
            }
          })
          .catch(error => {
            console.log('error===>', error)
          })
    },
    // 判断是否选中
    sortData() {
      if (this.selectArrData.length) {
        this.dataObject.forEach(ele => {
          if (this.selectArrData.includes(ele.id)) ele._checked = true;
        })
      }
    },

    // 关键词搜索
    search() {
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    // 科目搜索
    selectSubjectChange(param) {
      console.log('param====>', param);
      this.queryDataForm.subject = param;
      this.source = `${param.toLowerCase()}Source`;
      this.changeExaminationSite = `${param.toLowerCase()}ExaminationSite`;
      this.queryDataForm.topicSource = null;
      this.queryDataForm.examinationSite = null;

      this.handleChangeQueryData(1);

    },
    // 考点搜索
    selectExaminationSiteChange(param) {

      this.examinationSite = []
      this.examinationSite = param;
      this.queryDataForm.examinationSite = param.toString();
      this.handleChangeQueryData(1);
    },
    // 来源搜索
    selectSourceChange(param) {
      this.queryDataForm.topicSource = param;
      this.handleChangeQueryData(1);
    },
    // 难度搜索
    selectTopicDifficultyChange(param) {
      this.queryDataForm.difficulty = param;
      this.handleChangeQueryData(1);
    },

    // 重置
    reset() {
      this.changeExaminationSite = null;
      this.examinationSite = [];
      this.queryDataForm = {
        keywords: null,
        subject: null,
        examinationSite: null,
        topicSource: null,
        difficulty: null,
      }
      this.handleChangeQueryData(1);
    },

    // 选好题目点击确定
    okRelevanceTopic() {
      console.log(this.$lodash.uniqBy(this.selectedData, 'id'))
      this.$emit('isShow', false)
      this.$emit('on-refresh')
      this.$emit('check-data', this.selectedData)
      // this.selectedData = []
    },

    cancelRelevanceTopic() {
      this.relevanceTopic = false;
      this.$emit('isShow', false)
      this.$emit('on-refresh')
      // this.selectedData = []
    },

    // 选中某一项时触发
    onSelect(selection, row) {

      // 添加到已选项
      this.selectedData.push(row);
      console.log('onSelect ====> selectedData ===>', this.selectedData)

    },

    // 取消选中某一项时触发
    onSelectCancel(selection, row) {

      //从已选项中去除取消项
      this.$lodash.remove(this.selectedData, n => {
        return n.id === row.id
      })
      console.log('onSelectCancel ===> selectedData ===>' , this.selectedData)

    },

    onSelectAll(selection) {

      // 数组合并，有可能用户先选择了某几项，已经被我们push进去，因此数组合并需要去重一下
      this.selectedData = this.$lodash.uniqBy(this.selectedData.concat(selection), 'id');
      console.log('onSelectAll ===> selectedData ====>' , this.selectedData)
    },

    onSelectAllCancel() {

      // 从已选项中移除当页数据
      this.selectedData = this.$lodash.differenceBy(this.selectedData, this.dataObject, 'id')
      console.log('onSelectAllCancel ===> selectedData ====>' , this.selectedData)
    },

    //把源数据加上_checked字段，遍历已选项数据，更新选中状态
    updateChecked() {
      for (let i = 0; i < this.dataObject.length; i++) {
        this.dataObject[i]._checked = false;
        for (let j = 0; j < this.selectedData.length; j++) {
          if (this.selectedData[j].id === this.dataObject[i].id) {
            this.dataObject[i]._checked = true;
          }
        }
      }
    },

    // 判断科目subject是否为Q和V，列表参数相应改变
    handleChangeQueryData(page) {
      let subject = this.queryDataForm.subject;
      let requestVo = {
        type: subject === 'Verbal' ? 1 : 2,
        keywords: this.queryDataForm.keywords,
        topicSource: this.queryDataForm.topicSource,
        difficulty: this.queryDataForm.difficulty,
        examinationSite: this.queryDataForm.examinationSite,
      };
      this.query(page, this.pageData.size, subject === 'Verbal' || subject === 'Quant' ? requestVo : this.queryDataForm);

    },


    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.handleChangeQueryData(pageNumber)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.handleChangeQueryData(1)
    },

  },

  mounted() {
    console.log('this.allCheckData====>', this.allCheckData)
    this.query(this.pageData.page, this.pageData.size, {})
  },
}
</script>

<style lang="less" scoped>
.RelevanceTopicList{
  overflow: initial;
}

</style>
<style>
.RelevanceTopicList .ivu-poptip{
  display: block;
}
.RelevanceTopicList  .ivu-poptip-rel{
  display: block;
}
.ivu-table-cell{
  max-height: 48px;
}
</style>
