<template>
<!-- 新建/编辑 学员 -->
  <main>
    <div class="student-self-info">
      <div class="title">
        <Divider type="vertical" style="" />
        基础信息
      </div>
      <div class="student-self-info-base">
        <Form :model="formParams" ref="ruleValidate" :rules="ruleValidate" label-position="left" :label-width="120">
          <FormItem label="姓名：" prop="nickName" style="font-weight: 700">
            <Input v-model="formParams.nickName" style="width: 300px"></Input>
          </FormItem>
          <FormItem label="手机号：" prop="phone" style="font-weight: 700">
            <Input v-model="formParams.phone" style="width: 300px"></Input>
          </FormItem>
          <FormItem label="目标分数：" style="font-weight: 700">
            <Input type="number" v-model="formParams.targetScores" style="width: 300px"></Input>
          </FormItem>
          <FormItem label="目标考试日期：" style="font-weight: 700">
            <DatePicker type="date" placeholder="请选择日期" v-model="formParams.testTime" style="width: 300px"></DatePicker>
          </FormItem>
          <FormItem label="备注：" prop="remarks" style="font-weight: 700">
            <Input v-model="formParams.remarks" style="width: 300px"></Input>
          </FormItem>
        </Form>
      </div>
    </div>
    <div class="student-self-info-label">
      <div class="title">
        <Divider type="vertical" style="" />
        标签
      </div>
      <div class="student-self-info-label-list">
        <div class="label-list">
          <div :title="item.labelName" v-if="showLabelArr.length > 0" v-for="item in showLabelArr" >{{ item.labelName }}</div>
          <Icon type="ios-add-circle-outline" size="24" style="cursor: pointer" color="#2d8cf0" @click="addLabel" />
        </div>
        <Modal
            class-name="checkModal"
            title="标签"
            v-model="modal"
            @on-ok="okLabel"
            @on-cancel="cancelLabel">
          <checkbox-group ref="checkBoxGroup" v-model="checkLabelArr">
            <Tooltip v-for="item in labelList" :key="item.id" :content="item.labelName" placement="top">
              <checkbox :label="item.id + ':' + item.labelName" border>{{ item.labelName }}</checkbox>
            </Tooltip>
          </checkbox-group>

        </Modal>
      </div>
    </div>

<!--       数据部分先隐藏  -->
<!--    <div class="student-self-data">-->
<!--      <div class="title">-->
<!--        <Divider type="vertical" style="" />-->
<!--        数据-->
<!--      </div>-->
<!--      <div class="student-self-data-text">-->
<!--        <Input v-model="data" type="textarea" :rows="4" placeholder="请输入。。。" style="width: 440px" />-->
<!--      </div>-->
<!--    </div>-->

    <div class="student-self-info-save">
      <Button type="primary" @click="addSave">保存</Button>
      <Button type="primary" ghost @click="handleReset">取消</Button>
    </div>
  </main>

</template>

<script>
import Validation from "../../../util/validation";

export default {
  name: "StudentAddOrEdit",
  data() {
    return {
      id: this.$storage.get('StudentAddOrEdit', 'id'),
      // 表单数据
      formParams: {
        nickName: '',
        phone: '',
        targetScores: '',
        testTime: '',
        remarks: '',
      },
      // 表单验证
      ruleValidate: {
        nickName: [{
          required: true, message: '请输入姓名', trigger: 'blur',
        }],
        phone: [
          { required: true, message: '请输入11位手机号', type: 'string', len: 11, trigger: 'blur' }
        ]
      },
      // 页面要展示的标签
      showLabelArr: [],
      // Modal选中的标签
      checkLabelArr: [],
      // 已有的标签数据
      labelList: [],
      modal: false,
      // data: ''
    }
  },
  methods: {

    queryDetail(id) {
      this.$axios.post(`/api/student/details/${id}`, {}, {})
          .then(res => {
            console.log('res====>', res);
            if(res.code === '10000') {
              let data = res.body;
              let arr = data.labelVoList;
              let checkArr = [];
              arr.forEach(item => {
                let str =  item.id + ':' + item.labelName;
                checkArr.push(str);
              });
              this.checkLabelArr = checkArr;

              this.showLabelArr = data.labelVoList;
              this.formParams.nickName = data.nickName;
              this.formParams.phone = data.phone;
              this.formParams.targetScores = data.targetScores;
              this.formParams.testTime = data.testTime;
              this.formParams.remarks = data.remarks;

            }
          })
          .catch(error => {
            console.log(error)
          })

    },

    addLabel() {
      this.modal = true
    },
    //查询所有学员标签
    queryStudentLabel(){
      // 标签查询
      this.$axios.post('/api/student/label/list', {}, {})
          .then(res => {
            console.log(res);
            if (res.code === '10000') {
              this.labelList = res.body;
            }
          })
          .catch(error => {
            console.log(error);
          })
    },

    // 选择标签Modal 确定
    okLabel() {
      console.log('checkLabelArr===>', this.checkLabelArr);
      let checkLabelArr = this.checkLabelArr;
      let checkArr = [];
      checkLabelArr.forEach(item => {
        let str = item.split(':');
        let checkObj = {
          id: str[0],
          labelName: str[1]
        }
        checkArr.push(checkObj);
      })
      this.showLabelArr = checkArr;
    },

    // 选择标签Modal 取消
    cancelLabel() {
      if (this.showLabelArr.length === 0) {
        this.checkLabelArr = [];
      }
      if (this.checkLabelArr.length === 0) {
        this.showLabelArr = [];
      }
      this.$Message.warning("您已经取消了打标签");
    },

    // 点击保存
    addSave() {
      console.log('checkLabelArr ===>',this.checkLabelArr)
      let formParams = this.formParams;

      let url = '/api/student/handle';
      let requestVo = {
        labelVolist: this.showLabelArr,
        nickName: formParams.nickName,
        phone: formParams.phone,
        targetScores: formParams.targetScores,
        testTime: formParams.testTime,
        remarks: formParams.remarks,
        project: 'GMAT'
      };
      let id = this.id;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      this.$refs.ruleValidate.validate(valid => {
        if (valid) {
          this.$axios.post(url, requestVo, {})
              .then(res => {
                console.log('res ==>',res)
                if(res.code === '10000') {
                  this.$Message.success('保存成功');
                  this.$router.push({ path:`/student/self/StudentInfo` });
                }
              })
              .catch(error => {
                this.$Message.error('保存失败');
              })
        } else {
          return false;
        }
      })



    },

    // 点击取消
    handleReset() {
      this.$router.push({ path:`/student/self/StudentInfo` });
    }

  },

  mounted() {
    let id = this.id;
    if (id) {
      this.queryDetail(id);
    }

    this.queryStudentLabel()
  },

  destroyed() {
    this.$storage.remove('StudentAddOrEdit', 'id');
  }

}
</script>

<style lang="less" scoped>
  .title{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #282828;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .student-self-info{
    margin-bottom: 50px;
  }
  .student-self-info-base{
    margin-left: 20px;
  }
  .student-self-info-label-list{
    max-width: 600px;
    padding: 10px 0 20px 20px;
    .label-list{
      max-width: 600px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      div{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        height: 32px;
        line-height: 32px;
        padding: 0 15px;
        transition: border .2s ease-in-out;
        font-size: 14px;
        margin: 10px 10px 0 0;
        width: 100px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .student-self-info-save{
    margin: 50px 0 20px 300px;
    button{
      margin-right: 8px;
    }
  }
  .student-self-info-label-list .ivu-icon{
    margin: 10px 10px 0 0;
  }
  .checkModal{
    //.singleLabel{
    //  display: inline-block;
    //  overflow: hidden;
    //  white-space: nowrap;
    //  text-overflow: ellipsis;
    //  width: 110px;
    //  margin-right: 0;
    //}
    .ivu-checkbox-group{
      text-align: left;
      .ivu-checkbox-group-item {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100px;
      }
    }

  }
</style>
