<!--
  @author yanzi
  @date 2023/11/22
  @Description: 新增|修改 子任务
-->
<template>
  <div>
    <Form :model="subtaskFormParams" ref="subtaskRuleValidate" :rules="subtaskRuleValidate" :label-width="120">
      <FormItem label="类型" prop="category" style="font-weight: 700">
        <Select v-model="subtaskFormParams.category" style="width:260px" :disabled="!!subtaskId">
          <Option v-for="item in categoryList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem>
      <FormItem label="名称" prop="name" style="font-weight: 700;">
        <Input v-model="subtaskFormParams.name" placeholder="请输入任务名称" style="width: 260px" />
      </FormItem>

      <FormItem label="内容" prop="content" style="font-weight: 700;" v-if="subtaskFormParams.category === 1">
        <t-editor ref="editor" v-model="subtaskFormParams.content" height=500 />
      </FormItem>

      <FormItem label="内容" prop="content" style="font-weight: 700;" v-if="subtaskFormParams.category === 2">
        <Input v-model="subtaskFormParams.content" placeholder="请输入视频链接" clearable style="margin-bottom: 10px;" />
        <upload-return-url
            :have-img-url="subtaskFormParams.content"
            @uploadControl="handleUploadVideo"
        ></upload-return-url>
        <span style="color: #f68080; font-size: 12px; display: block; margin-top: 2px;">上传视频可直接通过输入框输入视频链接</span>
      </FormItem>

      <FormItem label="内容" prop="content" style="font-weight: 700;" v-if="subtaskFormParams.category === 3 || subtaskFormParams.category === 4">
        <Input v-model="subtaskFormParams.content" disabled placeholder="无内容，不需输入" style="width: 260px" />
      </FormItem>

      <FormItem label="描述" prop="taskDesc" style="font-weight: 700;">
        <Input type="textarea" v-model="subtaskFormParams.taskDesc" :autosize="{minRows: 4}" placeholder="请输入任务描述" />
      </FormItem>
      <FormItem label="是否计时：" prop="isTime" style="font-weight: 700">
        <i-switch v-model="subtaskFormParams.isTime!==0" @on-change="handleChangeTime" />
        <TimePicker v-model="subtaskFormParams.timeNumber" format="HH:mm" placeholder="请选择计时时长" style="width: 140px; margin-left: 20px;"></TimePicker>
      </FormItem>

      <FormItem>
        <Button type="primary" :loading="subtaskLoading" @click="handleSubmit">确定</Button>
        <Button @click="handleReset('subtaskRuleValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import uploadReturnUrl from '../../../components/upload/uploadReturnUrl'
export default {
  name: "SubtaskAddOrEdit",
  components: { uploadReturnUrl },
  data() {
    return {
      taskId: this.$storage.get('task', 'id'),
      subtaskId: this.$storage.get('subtask', 'id'),
      loadingModalBtn: false,
      addOrEditSubtaskModal: false,
      categoryList: [
        {
          label: '文字',
          value: 1
        },
        {
          label: '视频',
          value: 2
        },
        {
          label: '题目',
          value: 3
        },
        {
          label: 'Review',
          value: 4
        }
      ],
      subtaskFormParams: {
        category: null,
        name: '',
        content: '',
        taskDesc: '',
        isTime: 0,
        timeNumber: null,
      },
      subtaskRuleValidate: {
        category: [
          { required: true, message: '请选择类型', trigger: 'change', type: 'number' }
        ],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      },
      subtaskLoading: false,
    }
  },
  mounted() {
    if (this.subtaskId) {
      this.querySubtaskDetail(this.subtaskId);
    }
  },
  destroyed() {
    this.$storage.remove('subtask', 'id')
  },
  methods: {
    querySubtaskDetail() {
      this.$axios.get(`/api/mission/task/details/${this.subtaskId}`).then(res => {
        if (res.code === '10000') {
          const data = res.body
          this.subtaskFormParams.category = data.category
          this.subtaskFormParams.name = data.name
          this.subtaskFormParams.content = data.content
          this.subtaskFormParams.taskDesc = data.taskDesc
          this.subtaskFormParams.isTime = data.isTime
          this.subtaskFormParams.timeNumber = data.timeNumber!==0 ? this.$moment.utc(data.timeNumber).format('HH:mm') : null;
        }
      }).catch(error => {
        console.log('error====>', error)
      })
    },
    handleUploadVideo(file) {
      if(file){
        this.subtaskFormParams.content = file.response.body;
      }
    },
    handleChangeTime(status) {
      this.subtaskFormParams.isTime = status ? 1 : 0;
    },
    handleSubmit() {
      console.log('this.subtaskFormParams=====>', this.subtaskFormParams)

      this.subtaskLoading = true

      let requestVo = {
        category: this.subtaskFormParams.category,
        content: this.subtaskFormParams.content,
        missionId: this.taskId,
        name: this.subtaskFormParams.name,
        taskDesc: this.subtaskFormParams.taskDesc,
        isTime: this.subtaskFormParams.isTime,
        timeNumber: this.subtaskFormParams.timeNumber ? this.$moment.duration(this.subtaskFormParams.timeNumber).as('seconds')*1000 : null,
      };
      let id = this.subtaskId;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }
      console.log('requestVo ====>', requestVo)

      this.$refs.subtaskRuleValidate.validate(valid => {
        if (valid) {
          this.$axios.post('/api/mission/task/handle', requestVo, {})
              .then(res => {
                if (res.code === '10000') {
                  this.addOrEditSubtaskModal = false
                  this.$refs['subtaskRuleValidate'].resetFields(); // 重置表单数据
                  this.subtaskId = null
                  this.$Message.success('保存成功')
                  this.subtaskLoading = false
                  this.$router.push('/study/subtask/list')
                }
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          return false;
        }
      })

    },
    handleReset(name) {
      this.$router.push('/study/subtask/list')
    }
  }
}
</script>

<style scoped>

</style>
