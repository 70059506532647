<template>
<!-- 消息中心 -->
  <div>
    <header>
      <Button>全部已读</Button>

      <Select v-model="status" style="width:100px">
        <Option v-for="item in statusData" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
    </header>

    <Table :columns="columns" :data="dataObject"></Table>

  </div>
</template>

<script>
export default {
  name: "MessageCenter",
  data() {
   return {
     status: '全部',
     statusData: [
       {
         label: '全部',
         value: '全部'
       },
       {
         label: '已读',
         value: '已读'
       },
       {
         label: '未读',
         value: '未读'
       }
     ],
     dataObject: [],
     columns: [
       {
         title: '消息标题',
         key: 'title',
         align: 'center',
         minWidth: 700
       },
       {
         title: '时间',
         key: 'time',
         align: 'center',
         minWidth: 300
       }
     ]
   }
  },
  methods: {

  }
}
</script>

<style scoped>
header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>