<template>
<!-- 文章列表 -->
<div class="article-list-container">
  <div style="display: flex; justify-content: space-between">
    <Button type="primary" shape="circle" @click="handleNewArticle">新建文章</Button>
    <div style="display: flex">
      <dict-select
          style="width: 180px;display: inline-block; margin-right: 10px"
          v-model="queryDataForm.articleCategory"
          :value="queryDataForm.articleCategory"
          dict-key="articleCategory"
          select-type="NORMAL"
          @on-change="handleSelectCategoryChange"
      ></dict-select>
      <Input style="width: 200px; margin-bottom: 10px; margin-right: 10px" v-model="keywords"
             search enter-button="搜索" placeholder="请输入文章名称"
             @on-search="handleSearch"
      />
      <Button type="default" @click="handleRefresh">重置</Button>
    </div>
  </div>

  <Table border style="margin-top: 15px;"
         highlight-row
         :loading="loading"
         @on-selection-change="onSelectionChangeTable"
         ref="selection"
         :columns="columns"
         :data="dataObject">
  </Table>

  <Row style="margin-top:15px; float:right;">
    <Page :total="pageData.total"
          :page-size="pageData.size"
          :current="pageData.page"
          @on-change="pageOnChange"
          @on-page-size-change="pageSizeChange"
          show-total show-sizer show-elevator></Page>
  </Row>

</div>

</template>

<script>
import DictSelect from "../../../components/DictSelect/DictSelect";
export default {
  name: "ArticleList",
  components: { DictSelect },
  data() {
    return {
      keywords: '',
      selectLabel: '全部标签',
      labels: [
        {
          value: '全部标签',
          label: '全部标签'
        }, {
          value: 'GMAT',
          label: 'GMAT'
        }, {
          value: 'MBA',
          label: 'MBA'
        }, {
          value: 'MASTER',
          label: 'MASTER'
        }
      ],
      // 筛选条件
      queryDataForm: {
        articleCategory: null,
        articleTitle:null
      },
      // 列表加载
      loading: true,
      // 选择项
      selectArr: [],
      // 数据
      dataObject: [],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'id', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      // table列表数据
      columns: [
        {
          title: '标题',
          key: 'articleTitle',
          minWidth: 300,
          align: 'center',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '作者',
          key: 'author',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '分类',
          key: 'articleCategory',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '发布时间',
          key: 'createdDate',
          minWidth: 180,
          align: 'center',
          render: (h, params) => {
            let time = this.$moment(params.row.createdDate).format('YYYY-MM-DD HH:mm:ss')
            return h('div', time)
          }
        },
        {
          title: '浏览量',
          key: 'pageViews',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 340,
          align: 'center',
          render: (h, params) => {
            let zdType = params.row.isPlacedTheTop === 0 ? 1 : 0;  //  是否置顶
            let zdName = params.row.isPlacedTheTop === 0 ? '置顶' : '取消置顶';
            return h('div', [
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0'
                },
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.$router.push({
                      path: '/content/article/ArticleAddOrEdit'
                    });
                    this.$storage.set('ArticleAddOrEdit', 'id', params.row.id);
                    this.$storage.set('ArticleList', 'page', this.pageData.page);
                  }
                }
              }, '编辑'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0'
                },
                props: {
                  type: zdType === 1 ? 'primary' : 'default',
                  size: 'small'
                },
                on: {
                  click: () => {
                    let url = `/api/article/placedthetop/${params.row.id}/${zdType}`;
                    this.$Modal.confirm({
                      title: '提示',
                      content: '<p>您确定' + zdName + '该文章吗？</p>',
                      onOk: () => {
                        this.$axios.get(url)
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success(zdName + '成功');
                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                              }
                            })
                            .catch(error => {
                              this.$Message.error(zdName + '失败');
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning("您已经取消了" + zdName);
                      }
                    })
                  }
                }
              }, zdName),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0'
                },
                props: {
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                },
                on: {
                  click: () => {
                    let url = '/api/article/delete/' + params.row.id;
                    this.$Modal.confirm({
                      title: '提示',
                      content: '<p>确定删除该文章吗？</p>',
                      onOk: () => {
                        this.$axios.get(url)
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success('删除成功');
                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
                              }
                            })
                            .catch(error => {
                              this.$Message.error('删除失败');
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning("您已经取消了删除");
                      }
                    })
                  }
                }
              }, '删除'),
            ])
          }
        }
      ]
    }
  },
  methods: {

    // 查询文章列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
        sort: this.pageData.prop + ',' + this.pageData.direction,
      };
      this.$axios.post('/api/article/page', requestVo, {params: pageObj}).then(res => {
        if (res.code === '10000') {
          this.dataObject = res.body.content
          this.pageData.total = res.body.totalElements
          this.loading = false
        }
      }).catch(error => {
        console.log('error===>', error);
      })

    },

    // 选择文章分类
    handleSelectCategoryChange(param) {
      this.queryDataForm.articleCategory = param;
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    // 搜索
    handleSearch() {
      this.queryDataForm.articleTitle = this.keywords;
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    // 新建文章
    handleNewArticle() {
      this.$router.push({
        path: '/content/article/ArticleAddOrEdit',
      });
    },
    // 重置
    handleRefresh() {

      this.keywords = '';
      this.queryDataForm = {
        articleCategory: '',
        articleTitle: '',
      };
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    /*分页相关函数*/
    onSelectionChangeTable(selection) {
      this.selectArr = selection
    },
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  },
  mounted() {
    this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
  }
}
</script>

<style scoped>

</style>