<!--
  @author yanzi
  @date 2024/7/4
  @Description: GRE反馈列表
-->
<template>
  <section>
    <div class="feedback-list-top">
      <div class="header-left" style="display: flex; align-items: center">
        <div class="option">
          <span>科目：</span>
          <dict-multiple-select
              style="width: 200px;"
              v-model="queryDataForm.subject"
              :value="queryDataForm.subject"
              dict-key="greTopicSubject"
              select-type="NORMAL"
              @on-change="handleSubjectChange"
          ></dict-multiple-select>
        </div>
        <div class="option">
          <span>来源：</span>
          <dict-multiple-select
              style="width: 200px; margin-left: 28px"
              v-model="queryDataForm.topicSource"
              :value="queryDataForm.topicSource"
              dict-key="greTopicSource"
              select-type="NORMAL"
              @on-change="handleSourceChange"
          >
          </dict-multiple-select>
        </div>
      </div>

      <div style="display: flex; align-items: center; ">
        <Input v-model="queryDataForm.keywords" placeholder="请输入关键词" style="width: 300px; margin-right: 10px"
               search enter-button="搜索" @on-search="search" />
        <Button type="default" @click="reset">重置</Button>
      </div>
    </div>
    <div class="header-left" style="margin-top: 10px;">
      <div class="option">
        <span>状态：</span>
        <Select v-model="queryDataForm.isToDealWith" style="width: 200px;">
          <Option v-for="item in options" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div>
        <span>反馈时间：</span>
        <DatePicker type="datetimerange" format="yyyy-MM-dd HH:mm"
                    v-model="dateTime"
                    @on-change="dateChange"
                    placeholder="选择开始和结束日期时间" style="width: 300px"></DatePicker>
      </div>
    </div>


    <!--    <div style="margin-top: 10px;">-->
    <!--      <Button type="primary" @click="deleteFeedback" shape="circle" style="margin: 0 10px; width: 100px">删除反馈</Button>-->
    <!--    </div>-->

    <div>
    </div>

    <Table
        class="topicListTable"
        border style="margin-top: 15px;"
        highlight-row
        :loading="loading"
        ref="selection"
        row-key="id"
        :columns="columns"
        :data="dataObject"
        @on-selection-change="onSelectionChangeTable"
        @on-expand-tree="onExpandChangeTree"
    >
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

    <!--  回复用户的留言 Modal  -->
    <Modal
        title="回复"
        v-model="replyMessageModal"
        :closable="false">
      <Input v-model="messageReply" type="textarea" placeholder="请输入要给用户回复的内容" />
      <div slot="footer">
        <Button type="primary" @click="handleSubmitReply">确定</Button>
        <Button type="default" @click="cancelReply">取消</Button>
      </div>
    </Modal>

  </section>
</template>

<script>
import DictMultipleSelect from '../../../components/DictSelect/DictMultipleSelect'
export default {
  name: "GreFeedback",
  components: { DictMultipleSelect },
  data() {
    return {
      replyMessageModal: false, // 是否展示给用户回复留言的弹窗
      messageReply: '', // 给用户回复的内容
      dateTime: null,
      queryDataForm: {
        keywords: null,
        subject: null,
        topicSource: null,
        feedbackDateStart: null, // 查询时  选择的反馈起始时间
        feedbackDateEnd: null, // 查询时  选择的反馈结束时间
        isToDealWith: null,
      },
      currentFeedback: {}, // 当前反馈数据
      options: [{
        value: '1',
        label: '已处理'
      }, {
        value: '0',
        label: '未处理'
      }],
      // 列表加载
      loading: true,
      // 选择项
      selectArr: [],
      // 数据
      dataObject: [],
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        prop: 'subject', //默认排序字段
        direction: 'DESC' //默认排序方向
      },
      commentIds: [], // 勾选的全部数据id集合
      choicenessCommentIds: [], // 勾选的数据未精选的数据id集合
      cancelChoicenessCommentIds: [],  // 勾选的数据已精选的数据id集合
      selectArrData: [],
      // 列表数据
      columns: [
        {
          title: '题号',
          key: 'topicNumber',
          minWidth: 80,
          align: 'center',
          tree: true,
        },
        {
          title: '姓名/昵称',
          key: 'nickName',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '反馈内容',
          key: 'content',
          minWidth: 200,
          align: 'center',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '反馈时间',
          key: 'createdDate',
          minWidth: 140,
          align: 'center',
          render: (h, params) => {
            let time = this.$moment(params.row.createdDate).format('YYYY-MM-DD HH:mm:ss')
            return h('div', time)
          }
        },
        {
          title: '科目',
          key: 'subject',
          minWidth: 80,
          align: 'center'
        },
        {
          title: '来源',
          key: 'topicSource',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '状态',
          key: 'action',
          minWidth: 180,
          align: 'center',
          render: (h, params) => {
            let isToDealWith = params.row.isToDealWith;
            let show = isToDealWith === 0 ? this.options[1].label : this.options[0].label;
            let showContent = isToDealWith === 0 ? '您确定处理该反馈吗？' : '您确定该反馈变为未处理状态吗？';
            let type = isToDealWith === 0 ? this.options[0].value : this.options[1].value;
            return h('div', [
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: isToDealWith === 0 ? 'default' : 'primary',
                  size: 'small',
                  disabled: isToDealWith === 1
                },
                on: {
                  click: () => {

                    this.currentFeedback = params.row
                    this.replyMessageModal = true

                  }
                }
              }, show),
            ])
          }
        }
      ]
    }
  },
  methods: {

    // 查询列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
      }
      console.log('pageObj ===>', pageObj)
      // url
      this.$axios.post('/api/gre/feedback/page', requestVo, {params: pageObj}).then(res => {
        if (res.code === '10000') {
          let data = res.body.content;
          data.forEach((item, index) => {
            if(item.commentReplyRespVoList != null){
              item.commentReplyRespVoList.forEach((val, index) => {
                val.id = 'reply' + val.id;
                val.topicSource = item.topicSource;
                val.topicNumber = item.topicNumber;
                val.subject = item.subject;
                val._disabled = true;
              })
            }
          })
          this.dataObject = JSON.parse(JSON.stringify(data).replace(/commentReplyRespVoList/g, 'children'))
          this.pageData.total = res.body.totalElements
          this.loading = false

        }
      }).catch(error => {
        console.log(error)
        this.loading = false;
      })

    },

    // 关键词搜索
    search() {
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
    },

    // 选择科目
    handleSubjectChange(value) {
      this.queryDataForm.subject = value;
    },

    // 选择来源
    handleSourceChange(value) {
      this.queryDataForm.topicSource = value;
    },

    // 选择 反馈时间 进行查询
    dateChange(time) {
      console.log(this.dateTime);
      this.queryDataForm.feedbackDateStart = this.$moment(time[0]).format();
      this.queryDataForm.feedbackDateEnd = this.$moment(time[1]).format();
      if (time[0] === '' && time[1] === '') {
        this.queryDataForm.feedbackDateStart = null;
        this.queryDataForm.feedbackDateEnd = null;
      }
    },

    // 删除反馈
    deleteFeedback() {
      // let url = `/api/comment/delete/1?ids=${this.commentIds}`;
      this.$axios.post(url, {}, {}).then(res => {
        if (res.code === '10000') {
          this.$Message.success('删除成功');
          this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
        }
      }).catch(error => {
        console.log(error)
      })
    },

    // 重置
    reset() {
      this.queryDataForm = {
        keywords: null,
        subject: null,
        topicSource: null,
        feedbackDateStart: null,
        feedbackDateEnd: null,
        isToDealWith: null,
      }
      this.dateTime = null
      this.query(1, this.pageData.size, this.queryDataForm);
    },

    handleSubmitReply() {
      let data = this.currentFeedback
      let param = {
        studentId: data.studentId,
        topicId: data.topicId,
        feedbackId: data.id,
        content: data.topicSource + '-' + data.subject + '-' + data.topicNumber + '题目反馈：' + '【' + this.messageReply + '】'
      }
      console.log('param====>', param)

      if (this.messageReply) {
        this.$axios.post('/api/gre/feedback/dealwith', param, {}).then(res => {
          if (res.code === '10000') {
            this.$Message.success('操作成功');
            this.currentFeedback = {}
            this.messageReply = ''
            this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
            this.replyMessageModal = false
          }
        }).catch(error => {
          this.messageReply = ''
          this.currentFeedback = {}
          console.log('error', error)
        })
      } else {
        this.$Message.error('回复信息不能为空哦~')
      }
    },

    cancelReply() {
      this.replyMessageModal = false
    },

    // 列表选择
    onSelectionChangeTable(selection) {
      this.selectArrData = this.$refs.selection.getSelection();

      this.selectArr = selection
    },

    onExpandChangeTree(	rowKey, status) {
      console.log('rowKey ===>', rowKey)
      console.log('status ===>', status)
    },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.query(1, this.pageData.size, this.queryDataForm);
    },
  },
  mounted() {
    this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
  }
}
</script>

<style scoped>
.feedback-list-top {
  display: flex;
  justify-content: space-between;
}
.header-left {
  display: flex;
  align-items: center;
}
.header-left .option{
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.topicListTable {
  overflow: initial;
}
</style>
