<!--
  @author yanzi
  @date 2023/8/14
  @Description: 咨询表单列表
-->
<template>

</template>

<script>
export default {
  name: "InquiryFormList",
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>
