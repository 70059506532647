<template>
    <div>
        <!-- 1. 导航栏 -->
        <Form ref="queryDataForm" :model="queryDataForm">
            <Row :gutter="16">
                <Col span="8">
                    <Row>
                        <Col span="6">
                            <FormItem>
                                字典名称:
                            </FormItem>
                        </Col>
                        <Col span="18">
                            <FormItem prop="name">
                                <i-input v-model="queryDataForm.name"
                                         class="queryFormInput"
                                         placeholder="请输入字典名称（中文或英文）"/>
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col span="8">
                    <Row>
                        <Col span="6">
                            <FormItem>
                                字典类型：
                            </FormItem>
                        </Col>
                        <Col span="18">
                            <FormItem prop="typeName">
                                <i-input v-model="queryDataForm.typeName"
                                         class="queryFormInput"
                                         placeholder="字典类型"/>
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col span="8">
                    <Row>
                        <Col span="6">
                            <FormItem>
                                编辑状态:
                            </FormItem>
                        </Col>
                        <Col span="18">
                            <FormItem prop="editable">
                                <Select class="queryFormSelect"
                                        v-model="queryDataForm.editable"
                                        style="width:100px">
                                    <Option v-for="item in editable" :value="item.value" :key="item.value">{{ item.label
                                        }}
                                    </Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem>
                        <Button type="primary" size="small" icon="ios-refresh" @click="refreshTable">刷新</Button>
                        <Button type="primary" size="small" icon="ios-add" @click="handleModal(0)">添加</Button>
                        <Button type="error" size="small" icon="ios-trash" @click="deleteSelectBtn">删除</Button>

                        <Button type="primary" size="small" icon="ios-search" @click="findValue">查询字典数据</Button>
                    </FormItem>
                </Col>
                <Col span="8" offset="4">
                    <FormItem style="float: right;">
                        <Button type="primary" size="small" icon="ios-search" @click="queryButton">查询</Button>
                        <Button type="primary" size="small" @click="resetForm('queryDataForm')"><i
                                class="iconfont12 icon-reset"></i>重置
                        </Button>
                        <!--<Button type="primary" size="small" icon="ios-document-outline" @click="exportBtn">导出</Button>-->
                    </FormItem>
                </Col>
            </Row>
        </Form>
        <!-- 2. 表格展示 -->
        <Table size="small" style="margin-top: 15px;" highlight-row border @on-selection-change="onSelectionChangeTable"
               :columns="columns" :data="dataObject">
        </Table>
        <Row style="margin-top:15px;">
            <Page size="small"
                  :total="pageData.total"
                  :page-size="pageData.size"
                  :current="pageData.page"
                  @on-change="pageOnChange"
                  @on-page-size-change="pageSizeChange"
                  style="float:right;" show-total show-sizer show-elevator>
            </Page>
        </Row>
        <!--  表格展示 -->
        <Modal
                v-model="findValueModal.showModal"
                :width="1000"
                @on-ok="findValueModalOk"
                @on-cancel="findValueModalCancel"
                :mask-closable="false">
            <p slot="header" style="text-align:left">
                <span v-if="handleStatus"><i class="iconfont" style="margin-right:3px;">&#xe64a;</i>字典列表管理</span>
            </p>

            <div style="margin-bottom: 10px">
                <Row>
                    <i-col span="8">
                        <Button type="primary" size="small" icon="ios-add" @click="handDictionariesValue('add')">新增
                        </Button>
                        <Button type="primary" size="small" icon="md-create" @click="handDictionariesValue('edit')">修改
                        </Button>
                        <Button type="error" size="small" icon="ios-trash" @click="deleteDictionariesBtn">删除</Button>
                    </i-col>
                </Row>
            </div>
            <Table
                    ref="findValueSelection"
                    highlight-row
                    size="small" border
                    @on-selection-change="onSelectionChangeTableByAddValue"
                    :columns="findValueModal.columns"
                    :data="findValueModal.dataObject">
            </Table>
            <Row style="margin-top:15px;">
                <Page size="small"
                      :total="findValueModal.pageData.total"
                      :page-size="findValueModal.pageData.size"
                      :current="findValueModal.pageData.page"
                      @on-change="findValueModalPageOnChange"
                      @on-page-size-change="findValueModalPageSizeChange"
                      style="float:right;" show-total show-sizer show-elevator>
                </Page>
            </Row>
        </Modal>
        <!--字典数据添加-->
        <Modal
                v-model="addValueModal.showModal"
                @on-ok="addValueModalOk()"
                @on-cancel="addValueModalCancel"
                :mask-closable="false"
        >'
            <p slot="header" style="text-align:left">
                <span v-if="handleStatus"><i class="iconfont" style="margin-right:3px;">&#xe64a;</i>新增</span>
                <span v-if="!handleStatus"><i class="iconfont" style="margin-right:3px;">&#xe659;</i>编辑</span>
            </p>
            <div>
                <Form ref="addDataForm" :model="addValueModal.addDataForm" :rules="addValueModal.rules"
                      :label-width="120">
                    <FormItem label="编码值" prop="codeValue">
                        <Input size="small" v-model="addValueModal.addDataForm.codeValue" placeholder="请输入编码值"
                               :maxlength="128"></Input>
                    </FormItem>
                    <FormItem label="编码中文名称(ZH)" prop="nameZh">
                        <Input size="small" v-model="addValueModal.addDataForm.nameZh"
                               placeholder="请输入编码中文名称(ZH)"
                               :maxlength="128"></Input>
                    </FormItem>
                    <FormItem label="编码英文名称(EN)" prop="nameEn">
                        <Input size="small" v-model="addValueModal.addDataForm.nameEn" placeholder="请输入编码英文名称(EN)"
                               :maxlength="128"></Input>
                    </FormItem>
                    <FormItem label="编码排序" prop="orderNo">
                        <InputNumber :min="1" :max="999999999" :step="1" :precision="0" style="width: 100%" size="small"
                                     v-model="addValueModal.addDataForm.orderNo" placeholder="请输入编码排序"
                                     :maxlength="128"></InputNumber>
                    </FormItem>

                    <FormItem label="编辑状态" prop="editable">
                        <i-Switch :true-value="true" :false-value="false" v-model="addValueModal.addDataForm.editable">
                            <span slot="open">可用</span>
                            <span slot="close">禁用</span>
                        </i-Switch>
                    </FormItem>
                    <FormItem label="默认选择" prop="selected">
                        <i-Switch :true-value="true" :false-value="false" v-model="addValueModal.addDataForm.selected">
                            <span slot="open">可用</span>
                            <span slot="close">禁用</span>
                        </i-Switch>
                    </FormItem>

                </Form>
            </div>

        </Modal>

        <Modal
                v-model="showModal"
                :loading="loading"
                @on-ok="handleSubmit()"
                @on-cancel="cancelModal">
            <p slot="header" style="text-align:left">
                <span v-if="handleStatus"><i class="iconfont" style="margin-right:3px;">&#xe64a;</i>新增</span>
                <span v-if="!handleStatus"><i class="iconfont" style="margin-right:3px;">&#xe659;</i>编辑</span>
            </p>
            <div>
                <Form ref="dataForm" :model="dataForm" :rules="rules" :label-width="120">
                    <FormItem label="字典名称(ZH)" prop="nameZh">
                        <Input size="small" v-model="dataForm.nameZh" placeholder="请输入字典名称(ZH)"
                               :maxlength="128"></Input>
                    </FormItem>
                    <FormItem label="字典名称(EN)" prop="nameEn">
                        <Input size="small" v-model="dataForm.nameEn" placeholder="请输入字典名称(EN)"
                               :maxlength="128"></Input>
                    </FormItem>
                    <FormItem label="字典类型" prop="typeName">
                        <Input size="small" v-model="dataForm.typeName" placeholder="请输入字典类型" :maxlength="128"></Input>
                    </FormItem>
                    <FormItem label="值长度" prop="lengthLimit">
                        <Input size="small" v-model="dataForm.lengthLimit" placeholder="请输入值长度"
                               :maxlength="128">3</Input>
                    </FormItem>
                    <FormItem label="编辑状态" prop="editable">
                        <i-Switch :true-value="true" :false-value="false" v-model="dataForm.editable">
                            <span slot="open">可用</span>
                            <span slot="close">禁用</span>
                        </i-Switch>
                    </FormItem>
                </Form>
            </div>
        </Modal>
    </div>
</template>

<script>
    export default {
        name: "CodeTypeList",
        data() {
            return {

                findValueModal: {
                    showModal: false,
                    loading: true,
                    // 用户表格列表
                    columns: [
                        {type: 'selection', width: 58, align: 'center'},
                        {
                            title: '编码排序',
                            key: 'orderNo',
                            align: 'center',
                            minWidth: 140,
                            sortable: true
                        },
                        {
                            title: '编码值',
                            key: 'codeValue',
                            align: 'center',
                            minWidth: 140
                        },
                        {
                            title: '编码中文名称(ZH)',
                            key: 'nameZh',
                            align: 'center',
                            minWidth: 140
                        },
                        {

                            title: '编码英文名称(EN)',
                            key: 'nameEn',
                            align: 'center',
                            minWidth: 140
                        },
                        {
                            title: '编辑状态',
                            key: 'editable',
                            minWidth: 110,
                            align: 'center',
                            sortable: true,
                            render: (h, params) => {
                                return h('div', params.row.editable ? '可用' : '禁用');
                            }
                        },
                        {
                            title: '选择状态',
                            key: 'selected',
                            minWidth: 110,
                            align: 'center',
                            sortable: true,
                            render: (h, params) => {
                                return h('div', params.row.selected ? '已选择' : '未选择');
                            }
                        }
                    ],
                    // 用户表格数据
                    dataObject: [],
                    // 分页对象
                    pageData: {
                        page: 1,
                        size: 10,
                        total: 0
                    },
                },
                //添加字典数据
                addValueModal: {
                    showModal: false,
                    loading: true,
                    addDataForm: {
                        codeValue: '',
                        nameZh: '',
                        nameEn: '',
                        orderNo: null,
                        editable: true,
                        selected: true,
                        codeTypeId: '',
                        codeType: null
                    },
                    rules: {
                        codeValue: [
                            {required: true, message: '编码值不能为空', trigger: 'blur'}
                        ],
                        nameZh: [
                            {required: true, message: '编码中文名称(ZH)不能为空', trigger: 'blur'}
                        ],
                        orderNo: [
                            {required: true, type: 'number', message: '编码排序不能为空', trigger: 'blur'}
                        ]

                    },
                    selectArr: []
                },
                // 初始化信息总条数
                dataCount: 0,
                // 每页显示多少条
                pageSize: 10,
                editable: [{'label': '可用', 'value': 1}, {'label': '禁用', 'value': 0}],
                // 添加用户信息表单
                dataForm: {
                    nameEn: '',
                    nameZh: '',
                    typeName: '',
                    lengthLimit: '',
                    editable: true,
                },
                rules: {
                    nameEn: [
                        {required: true, message: '字典名称(EN)不能为空', trigger: 'blur'}
                    ],
                    nameZh: [
                        {required: true, message: '字典名称(ZH)不能为空', trigger: 'blur'}
                    ],
                    typeName: [
                        {required: true, message: '字典类型不能为空', trigger: 'blur'}
                    ]
                },
                handleStatus: true,
                showModal: false,
                loading: true,
                // 查询用户表单
                queryDataForm: {
                    nameEn: '',
                    nameZh: '',
                    typeName: '',

                },
                // 用户选择列表
                selectArr: [],
                // 用户表格列表
                columns: [
                    {type: 'selection', width: 58, align: 'center'},
                    {
                        title: '字典名称(ZH)',
                        key: 'nameZh',
                        align: 'center',
                        width: 140,
                        sortable: true
                    },
                    {

                        title: '字典名称(EN)',
                        key: 'nameEn',
                        align: 'center',
                        width: 140,
                        sortable: true
                    },
                    {
                        title: '字典类型',
                        key: 'typeName',
                        width: 140,
                        align: 'center',
                        sortable: true
                    },
                    {
                        title: '值长度',
                        key: 'lengthLimit',
                        width: 120,
                        align: 'center',
                        sortable: true
                    },
                    {
                        title: '编辑状态',
                        key: 'editable',
                        width: 110,
                        align: 'center',
                        sortable: true,
                        render: (h, params) => {
                            return h('div', params.row.editable ? '可用' : '禁用');
                        }
                    },
                    {
                        title: '更新时间',
                        key: 'lastModifiedDate',
                        minWidth: 150,
                        align: 'center',
                        sortable: true
                    },
                    {
                        title: '操作',
                        key: 'action',
                        minWidth: 105,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small',
                                        icon: "md-create"
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.handleModal(params.row);
                                        }
                                    }
                                }, '编辑'),
                                h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small',
                                        icon: "ios-trash"
                                    },
                                    on: {
                                        click: () => {
                                            let ids = {
                                                id: params.row.id
                                            }
                                            this.deleteConfirm(ids);
                                        }
                                    }
                                }, '删除')
                            ]);
                        }
                    }
                ],
                // 用户表格数据
                dataObject: [],
                // 分页对象
                pageData: {
                    page: 1,
                    size: 10,
                    total: 0
                },

            }
        },
        methods: {
            //查看字典类型值
            findValue() {
                if (this.selectArr.length != 1) {
                    this.$Message.warning("请选择一条数据")
                    return
                }
                this.findValueModal.showModal = true
                this.queryCodeList()

            },

            //字典管理添加数据确定
            addValueModalOk() {
                let _this = this
                this.$refs.addDataForm.validate((valid) => {
                    this.addValueModal.addDataForm.codeTypeId = this.selectArr[0].id
                    if (valid) {
                        this.$axios({
                            url: '/api/codelist/handle',
                            method: 'post',
                            data: this.addValueModal.addDataForm
                        }).then(res => {
                            if (res.code == "OK") {

                                this.$refs.findValueSelection.selectAll(false)
                                this.addValueModal.selectArr = []
                                this.$refs['addDataForm'].resetFields();

                                _this.$Message.success('添加成功')
                            } else {
                                _this.$Message.error('添加失败')
                            }
                            this.queryCodeList();
                            _this.addValueModal.showModal = false;
                        }).catch(e => {
                            _this.$Message.error('系统异常，请稍后再试')
                        });
                    }
                });
            },
            //字典管理添加数据取消
            addValueModalCancel() {
                this.addValueModal.showModal = false;
                this.addValueModal.selectArr = []
                this.queryCodeList()

            },

            //字典管理数据添加
            handDictionariesValue(v) {
                if (v === 'edit') {
                    if (this.addValueModal.selectArr.length != 1) {
                        this.$Message.warning("请选择一条数据")
                        return
                    } else {
                        this.handleStatus = false
                        this.addValueModal.addDataForm = this.addValueModal.selectArr[0];
                        this.addValueModal.addDataForm.codeType = this.selectArr[0];
                        this.addValueModal.addDataForm.codeTypeId = this.selectArr[0].id;
                    }
                } else {
                    if (v === 'add') {
                        this.handleStatus = true
                        this.addValueModal.addDataForm.id = ''
                        this.addValueModal.addDataForm.codeValue = ''
                        this.addValueModal.addDataForm.nameZh = ''
                        this.addValueModal.addDataForm.nameEn = ''
                        this.addValueModal.addDataForm.orderNo = null
                        this.addValueModal.addDataForm.editable = true
                        this.addValueModal.addDataForm.selected = true
                        // this.addValueModal.addDataForm.codeType = this.selectArr[0]
                        // this.addValueModal.addDataForm.codeTypeId = this.selectArr[0].id
                    }
                }

                this.addValueModal.showModal = true;
            },
            //字典管理数据删除
            deleteDictionariesBtn() {
                if (this.addValueModal.selectArr.length === 0) {
                    this.$Message.warning("请至少选择一条要删除的数据")
                    return
                }
                this.$Modal.confirm({
                    title: '确认删除',
                    content: '<p>确定要对所选行进行<span style=\'color: red\'>删除</span>操作?</p>',
                    onOk: () => {

                      let selectArr = this.addValueModal.selectArr
                      let selectId = null
                      if (selectArr.length === 1) {
                        selectId = selectArr[0].id

                        this.$axios.get(`api/codelist/delete/${selectId}`).then(res => {
                          if (res.code === '10000') {
                            this.$Message.success('删除成功')
                            this.queryCodeList()
                          } else if (res.code === '40001') {
                            this.$Message.error('删除失败，当前字典表数据不存在')
                          }
                        }).catch(error => {
                          console.log('error ====>', error)
                        })
                      } else {
                        this.$Message.error('您只能选择一条数据删除')
                      }
                      //清空删除数组
                      this.addValueModal.selectArr = []
                    },
                    onCancel: () => {
                        this.$Message.warning("您已经取消了删除")
                    }

                })

            },

            queryCodeList(page = 1, size = 10, requestVo = {}) {

                this.findValueModal.pageData.page = page
                this.findValueModal.pageData.size = size
                let pageObj = {
                    page: page - 1,
                    size: size
                };
                this.loading = true
                requestVo = Object.assign({}, requestVo)
                // requestVo['page'] = pageObj;
                requestVo.codeTypeId = this.selectArr[0].id;
                this.$axios.post('/api/codelist/list', requestVo, {params: pageObj})
                    .then(res => {
                        this.findValueModal.dataObject = res.body.content;
                        this.findValueModal.pageData.total = res.body.totalElements;
                        this.loading = false
                    })
            },
            findValueModalOk() {
                this.findValueModal.showModal = false
            },
            findValueModalCancel() {
                this.addValueModal.selectArr = []
            },
            deleteConfirm(ids) {
                //组合参数
                let dataVo = new Array();
                dataVo.push(ids);
                let reqVo = {list: dataVo};
                //删除确认对话框
                this.$Modal.confirm({
                    title: '确认删除',
                    content: '<p>确定要对所选行进行<span style=\'color: red\'>删除</span>操作?</p>',
                    onOk: () => {
                        this.$axios({
                            url: '/api/codetype/delete',
                            method: 'post',
                            data: reqVo
                        }).then(res => {
                            if (res.code == "OK") {
                                this.refreshTable();
                                this.$Message.message(res.data.body);
                            } else {
                                this.$Message.error(res.data.body);
                            }

                        })
                    }
                })
            },
            handleSubmit() {
                this.$refs.dataForm.validate((valid) => {
                    if (valid) {
                      console.log('this.dataForm=====>', this.dataForm)
                        this.$axios.post('/api/codetype/handle', this.dataForm, {})
                            .then(res => {
                                if (res.code == "OK") {
                                    this.refreshTable();
                                    // this.dataForm = {status: 1};
                                    this.$refs['dataForm'].resetFields();
                                    this.selectArr = []
                                    this.$Message.success(res.message);
                                } else {
                                    this.$Message.error(res.message);
                                }
                                this.showModal = false;

                            })
                    }
                });
            },
            resetForm(name) {
                this.$refs[name].resetFields();
            },
            cancelModal() {
                this.showModal = false;
                // this.dataForm = {status: 1};
                // this.selectArr = []
                this.$refs['dataForm'].resetFields();
            },

            // 用户查询按钮
            queryButton() {
                this.refreshTable();
            },
            //用户添加&删除按钮
            handleModal(row) {
                this.showModal = true;
                if (row != null && row['id'] != null) {
                    this.handleStatus = false;
                    // Note: 数据传递
                    let localData = {};
                    for (let key in row) {
                        localData[key] = row[key];
                    }
                    this.dataForm = localData;
                } else {
                    this.handleStatus = true
                }
            },
            exportBtn() {

            },
            // 用户删除按钮
            deleteSelectBtn() {
                if (this.selectArr.length === 0) {
                    this.$Message.warning({
                        content: '请选择要删除的内容',
                        duration: 10,
                        closable: true
                    });
                    return;
                }
                this.$Modal.confirm({
                    title: '确认删除',
                    content: '<p>确定要对所选行进行<span style=\'color: red\'>删除</span>操作?</p>',
                    onOk: () => {
                        let dataVo = new Array();
                        this.selectArr.filter(item => {
                            dataVo.push({id: item.id});
                        });
                        let reqVo = {list: dataVo};
                        this.$axios({
                            url: '/api/codetype/delete',
                            method: 'post',
                            data: reqVo
                        }).then(res => {
                            if (res.code == "OK") {
                                this.refreshTable();
                                this.$Message.message(res.data.body);
                            } else {
                                this.$Message.error(res.data.body);
                            }
                        });
                        // 清空删除数组
                        this.selectArr = [];
                    },
                    onCancel: () => {
                        this.$Message.warning("您已经取消了删除");
                    }
                })

            },
            // 查询表格数据
            query(page = 1, size = 10, requestVo = {}) {

                this.pageData.page = page
                this.pageData.size = size
                let pageObj = {
                    page: page - 1,
                    size: size
                };
                this.$axios.post('/api/codetype/list', requestVo, {params: pageObj})
                    .then(res => {
                        this.dataObject = res.body.content;
                        this.pageData.total = res.body.totalElements;
                    })
            },
            // 字典数据页面表格多选
            onSelectionChangeTableByAddValue(row) {
                this.addValueModal.selectArr = row;
            },
            // 表格多选
            onSelectionChangeTable(row) {
                this.selectArr = row;
            },
            // 分页改变事件
            pageOnChange(pageNumber) {
                this.pageData.page = pageNumber;
                this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            },
            pageSizeChange(pageSize) {
                this.pageData.size = pageSize;
                this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            },

            findValueModalPageOnChange(pageNumber) {
                this.findValueModal.pageData.page = pageNumber;
                this.queryCodeList(this.findValueModal.pageData.page, this.findValueModal.pageData.size, this.queryDataForm);
            },
            findValueModalPageSizeChange(pageSize) {
                this.findValueModal.pageData.size = pageSize;
                this.query(this.findValueModal.pageData.page, this.findValueModal.pageData.size, this.queryDataForm);
            }
            ,
            refreshTable() {
                this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            }
        },
        mounted() {
            this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
        }
    }
</script>

<style>

</style>
