<!--
  @author yanzi
  @date 2023/8/3
  @Description: Master 新增|编辑 文章
-->
<template>
  <div>
    <Form ref="formValidate" :model="articleForm" :rules="articleRule" :label-width="100">
      <FormItem label="文章标题" prop="title" style="font-weight: 700;">
        <Input v-model="articleForm.title" placeholder="标题内容" style="width: 280px;"></Input>
      </FormItem>

      <FormItem label="文章类别" prop="category" style="font-weight: 700;">
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="articleForm.category"
            :value="articleForm.category"
            dict-key="articleCategory"
            select-type="NORMAL"
            :is-disabled="true"
        ></dict-select>
      </FormItem>

      <FormItem label="一级分类" prop="primaryClassification" style="font-weight: 700;">
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="articleForm.primaryClassification"
            :value="articleForm.primaryClassification"
            dict-key="primaryClassification"
            select-type="NORMAL"
            :is-filterable="true"
        ></dict-select>
      </FormItem>

      <FormItem label="二级分类" prop="secondaryClassification" style="font-weight: 700;">
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="articleForm.secondaryClassification"
            :value="articleForm.secondaryClassification"
            dict-key="secondaryClassification"
            select-type="NORMAL"
            :is-filterable="true"
        ></dict-select>
      </FormItem>

      <FormItem label="作者" prop="author" style="font-weight: 700;">
        <Input v-model="articleForm.author" placeholder="请输入作者" style="width: 280px;"></Input>
      </FormItem>

      <FormItem label="文章详情" prop="detail" style="font-weight: 700;">
        <t-editor ref="editor" v-model="articleForm.detail" height="500" />
      </FormItem>

      <FormItem label="标签" prop="label" style="font-weight: 700;">
        <CheckboxGroup v-model="labelArr" @on-change="handleCheckLabel">
          <Checkbox :label="item.id" v-for="item in labelData" :key="item.labelName">{{ item.labelName }}</Checkbox>
        </CheckboxGroup>
      </FormItem>

      <FormItem>
        <Button type="primary" @click="handleSubmit('formValidate')">保存</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import UploadReturnUrl from "../../../components/upload/uploadReturnUrl";
import DictSelect from "../../../components/DictSelect/DictSelect";
import TEditor from "../../../components/TEditor/TEditor";

export default {
  name: "MsArticleAddOrEdit",
  components: { UploadReturnUrl, DictSelect, TEditor },
  data() {
    return {
      // 文章id
      id: this.$storage.get('ArticleAddOrEdit', 'id'),
      articleForm: {
        title: '',  // 文章标题
        category: 'MBA',  // 文章分类
        primaryClassification: '', // 一级分类
        secondaryClassification: '', // 二级分类
        author: '',  // 作者
        detail: '', // 文章详情
        label: [], // 标签
      },
      articleRule: {
        title: [{ required: true, message: '请输入标题内容', trigger: 'blur' }],
        category: [{ required: true, message: '请选择文章分类', trigger: 'change' }],
        label: [
          { required: true, type: 'array', min: 1, message: '请至少勾选一个标签', trigger: 'change' },
        ],
      },
      // 已勾选标签数据
      labelArr: [],
      // 所有标签数据
      labelData: [],
      // 编辑时保存的已勾选标签数据
      alreadyLabel: [],
    }
  },
  methods: {

    // 获取文章标签列表
    queryArticleLabelList() {
      this.$axios.post("/api/article/label/list")
          .then(res => {
            if (res.code === '10000') {
              this.labelData = res.body
            }
          })
          .catch(error => {
            console.log('error===>', error)
          })
    },

    //  编辑时 获取文章详情接口
    queryDetail(id) {
      this.$axios.get(`/api/article/details/${id}`).then( res=> {
        if (res.code === '10000') {
          let data = res.body;

          this.articleForm = {
            title: data.articleTitle,
            category: data.articleCategory,
            author: data.author,
            detail: data.articleContent,
            label: data.articleLabelRelationVoList,
          };

          this.alreadyLabel = data.articleLabelRelationVoList;
          let arr = [];
          data.articleLabelRelationVoList.forEach(item => {
            arr.push(item.articleLabelId)
          })
          this.labelArr = arr;
        }
      }).catch(error => {
        console.log('error ===>', error);
      })
    },

    // 选择文章标签
    handleCheckLabel(data) {
      console.log('data====>', data);

      let newArr = [];

      // let label = this.alreadyLabel;
      // if(label.length > 0) {
      //
      //   let obj = {};
      //
      //   label.forEach(item => {
      //     console.log('11111')
      //
      //     if (data.indexOf(item.articleLabelId) !== -1 ) {
      //       data.forEach(i => {
      //         if (item.articleLabelId === i) {
      //           obj = item
      //           newArr.push(obj)
      //           console.log('newArr 1=====>', newArr);
      //         } else {
      //           newArr.push({
      //             id: null,
      //             articleId: this.id ? this.id : null,
      //             articleLabelId: i,
      //           });
      //           console.log('newArr 2=====>', newArr);
      //         }
      //       })
      //     } else if (data.indexOf(item.articleLabelId) === -1) {
      //       newArr.forEach(i => {
      //         if (item.articleLabelId === i.articleLabelId) {
      //           newArr.splice(i, 1);
      //         }
      //       })
      //       console.log('newArr 3=====>', newArr);
      //     }
      //   })
      //
      //   this.articleForm.label = newArr
      //
      //   console.log('newArr 编辑时=====>', newArr);
      // } else {
      //   console.log('22222')
      //   data.forEach(item => {
      //     newArr.push({
      //       id: null,
      //       articleId: this.id ? this.id : null,
      //       articleLabelId: item,
      //     });
      //   })
      //   this.articleForm.label = newArr
      //   console.log('newArr 新建时=====>', newArr);
      // }



      data.forEach(item => {
        newArr.push({
          id: null,
          articleId: this.id ? this.id : null,
          articleLabelId: item,
        });
      })
      this.articleForm.label = newArr
      console.log('newArr =====>', newArr);
    },

    // 保存
    handleSubmit (name) {


      let url = '/api/article/handle';
      let requestVo = {
        articleTitle: this.articleForm.title,
        articleCategory: this.articleForm.category,
        author: this.articleForm.author,
        articleContent: this.articleForm.detail,
        articleLabelRelationVoList: this.articleForm.label
      }

      console.log('requestVo====>', requestVo)

      // let id = this.id;
      // if (id) {
      //   requestVo = Object.assign({}, requestVo, { id });
      // }
      //
      // this.$refs[name].validate((valid) => {
      //   if (valid) {
      //
      //     this.$axios.post(url, requestVo, {}).then(res => {
      //       if (res.code === '10000') {
      //         this.$Message.success('保存成功');
      //         localStorage.setItem('isRemove', JSON.stringify(true));
      //         this.$storage.remove('ArticleAddOrEdit', 'id');
      //         this.$router.push({ path: '/content/article/ArticleManage' });
      //       }
      //     }).catch(error => {
      //       console.log('error ===>', error);
      //     })
      //
      //   } else {
      //     this.$Message.error('Fail!');
      //   }
      // })
    },

    // 取消
    handleReset (name) {

      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$storage.remove('ArticleAddOrEdit', 'id');
      this.$refs[name].resetFields();
      this.$router.push({ path: '/ms/article/list' })
    }
  },
  mounted() {

    this.queryArticleLabelList();

    let id = this.id;
    if (id) {
      this.queryDetail(id);
    }
  }
}
</script>

<style scoped>

</style>
