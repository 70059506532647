<!--
  @author yanzi
  @date 2023/11/20
  @Description: 子任务列表页面
-->
<template>
  <div>
    <div style="display: flex; align-items: center; margin-top: 10px;">
      <Button type="primary" @click="handleAddSubtask" shape="circle" style="margin-right: 10px; width: 100px;">新建子任务</Button>
    </div>
    <Table border style="margin-top: 15px;"
           highlight-row
           :loading="loading"
           ref="selection"
           :columns="columns"
           :data="dataObject"
           draggable
           @on-drag-drop="handleOrder"
    >
    </Table>
  </div>
</template>

<script>

export default {
  name: "SubtaskList",
  data () {
    return {
      loading: false,
      dataObject: [],
      columns: [
        {
          title: '编号',
          type: 'index',
          width: 80,
          align: 'center',
        },
        {
          title: 'ID',
          key: 'id',
          width: 80,
          align: 'center',
        },
        {
          title: '名称',
          key: 'name',
          minWidth: 120,
          align: 'center',
        },
        {
          title: '排序',
          key: 'sorted',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '类型',
          key: 'category',
          minWidth: 160,
          align: 'center',
          render: (h, params) => {
            return h('div', this.getCategory(params.row.category))
          }
        },
        {
          title: '内容',
          key: 'content',
          minWidth: 200,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            let content = params.row.content
            if (params.row.category === 3) {
              return h('div', [
                  h('Button', {
                    style: {
                      margin: '5px 5px 5px 0',
                      cursor: 'pointer'
                    },
                    props: {
                      type: 'primary',
                      size: 'small',
                    },
                    on: {
                      click: () => {
                        this.$router.push('/study/subtask/checkTopic')
                        this.$storage.set('subtask', 'id', params.row.id)
                      }
                    }
                  }, '查看')
              ])
            } else if (params.row.category === 4) {
              return h('div', '无')
            } else {
              return h('Poptip', {
                props: {
                  trigger: 'hover',
                  placement: 'top',
                  wordWrap: true,
                }
              }, [
                h('div', {
                  domProps: {
                    innerHTML: content
                  },
                  style: {
                    width: '200px',
                    maxHeight: '48px',
                    display: '-webkit-box',
                    webkitLineClamp: 1,
                    webkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    margin: '0 auto',
                  }
                }),
                h('div', {
                  slot: 'content',
                }, [
                  h('div', {
                    domProps: {
                      innerHTML: content
                    },
                    style: {
                      width: '200px',
                      maxHeight: '500px',
                      wordWrap: 'break-word',
                      wordBreak: 'normal'
                    }
                  })
                ])
              ])
            }
          },
          props: {
            level: {
              type: Number,
              require: true
            }
          }
        },
        {
          title: '描述',
          key: 'taskDesc',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '创建时间',
          key: 'createdDate',
          minWidth: 160,
          align: 'center',
          render: (h, params) => {
            return h('div', this.$moment(params.row.createdDate).format('YYYY-MM-DD HH:mm'))
          },
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 260,
          align: 'center',
          render: (h, params) => {
            return h('div', [
                h('Button', {
                  style: {
                    margin: '5px 5px 5px 0',
                  },
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$router.push('/study/subtask/addOrEdit')
                      this.$storage.set('subtask', 'id', params.row.id)
                    }
                  }
                }, '编辑'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                },
                on: {
                  click: () => {
                    this.handleDeleteTask(params.row.id)
                  }
                }
              }, '删除')
            ])
          }
        }
      ],
      taskId: this.$storage.get('task', 'id')
    }
  },
  mounted() {
    this.querySubtaskList()
  },
  methods: {
    querySubtaskList() {
      this.$axios.get(`/api/mission/task/list/${this.taskId}`).then(res => {
        if (res.code === '10000') {
          this.dataObject = res.body
        }
      }).catch(error => {
        console.log('error ===>', error)
      })
    },
    getCategory(value) {
      switch (value) {
        case 1:
          return '文字';
          break;
        case 2:
          return '视频';
          break;
        case 3:
          return '做题';
          break;
        case 4:
          return 'Review';
          break;
        default:
          return '';
          break;
      }
    },
    handleAddSubtask() {
      this.$router.push('/study/subtask/addOrEdit')
    },
    // 删除子任务
    handleDeleteTask(subtaskId) {

      let url = `/api/mission/task/delete/${subtaskId}`;
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确定删除该条子任务吗？</p>',
        onOk: () => {
          this.$axios.get(url, {}, {})
              .then(res => {
                console.log('res ==>', res)
                if (res.code === '10000') {
                  this.$Message.success('删除成功');
                  this.querySubtaskList()
                } else if (res.code === '40001') {
                  this.$Message.error('删除失败，当前任务不存在')
                } else if (res.code === '50001') {
                  this.$Message.error('当前任务已绑定学员数据，您无法执行删除操作')
                }
              })
              .catch(error => {
                this.$Message.error('删除失败');
              })
        },

        onCancel: () => {
          this.$Message.warning("您已经取消了删除");
        }
      })
    },
    // 拖动排序
    handleOrder(oldIndex, newIndex) {

      const movedItem = this.dataObject.splice(oldIndex, 1)[0];
      this.dataObject.splice(newIndex, 0, movedItem);

      // 更新sorted字段
      this.dataObject.forEach((obj, index) => {
        obj.sorted = this.dataObject.length - index;
      });

      console.log('this.dataObject=====>', this.dataObject)

      const newDataArr = this.dataObject.map(obj => ({ id: obj.id, sorted: obj.sorted }));

      console.log('newDataArr====>', newDataArr)
      // 对接拖动排序接口
      this.$axios.post('/api/mission/task/sorted', newDataArr).then(res => {
        if (res.code === '10000') {
          this.$Message.success('拖动成功')
          this.querySubtaskList();
        }
      }).catch(error => {
        console.log('error===>', error)
      })

    },
  }
}
</script>

<style scoped>

</style>
