<!--
  @author yanzi
  @date 2024/5/23
  @Description: GRE题目上传与编辑
-->
<template>
  <!-- 新建/编辑 题目 -->
  <div>
    <Form :model="formParams" ref="ruleValidate" :rules="ruleValidate" :label-width="120" class="topicForm">
      <FormItem label="科目" prop="greType" style="font-weight: 700">
        <Select v-model="formParams.greType" placeholder="请选择题目类型" style="width:200px">
          <Option v-for="item in greTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem>
      <FormItem label="题目科目" prop="subject" style="font-weight: 700">
        <dict-select
            style="width: 180px;display: inline-block"
            v-model="formParams.subject"
            :value="formParams.subject"
            dict-key="greTopicSubject"
            select-type="NORMAL"
            :view-value="true"
            @on-change="selectSubjectChange"
            @on-item="selectSubjectItemChange"
        ></dict-select>
      </FormItem>
      <FormItem label="题型" prop="questionType" style="font-weight: 700">
        <dict-multiple-select
            style="width: 380px;display: inline-block"
            v-model="questionType"
            :value="questionType"
            :dict-key="changeQuestionType"
            select-type="NORMAL"
            @on-change="selectQuestionTypeChange"
        ></dict-multiple-select>
      </FormItem>
      <FormItem label="来源" style="font-weight: 700">
        <dict-select
            style="width: 180px;display: inline-block"
            v-model="formParams.greSource"
            :value="formParams.greSource"
            dict-key="greTopicSource"
            select-type="NORMAL"
            @on-change="selectSourceChange"
        ></dict-select>
      </FormItem>
      <FormItem label="难度" prop="topicDifficulty" style="font-weight: 700">
        <dict-select
            style="width: 180px;display: inline-block"
            v-model="formParams.difficulty"
            :value="formParams.difficulty"
            dict-key="greTopicDifficulty"
            select-type="NORMAL"
            @on-change="selectTopicDifficultyChange"
        ></dict-select>
      </FormItem>

      <FormItem label="题目类型" prop="topicType" style="font-weight: 700">
        <Select v-model="formParams.topicType" placeholder="请选择题目类型" style="width:200px">
          <Option v-for="item in topicTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem>

      <FormItem label="题目序号" prop="topicNumber" style="font-weight: 700;">
        <Input v-model="formParams.topicNumber" placeholder="请输入题目序号" style="width: 180px" />
      </FormItem>

      <FormItem label="题目模板" style="font-weight: 700;">
        <Tabs :value="formParams.topicType.toString()">
          <TabPane label="单空单选题" name="1">
            <TemplateEditor id="t-editor-1" v-model="template1" :height="300" />
          </TabPane>
          <TabPane label="双空单选题" name="2">
            <TemplateEditor id="t-editor-2" v-model="template2" :height="300" />
          </TabPane>
          <TabPane label="三空单选题" name="3">
            <TemplateEditor id="t-editor-3" v-model="template3" :height="300" />
          </TabPane>
          <TabPane label="单选" name="4">
            <TemplateEditor id="t-editor-4" v-model="template4" :height="300" />
          </TabPane>
          <TabPane label="多选" name="5">
            <TemplateEditor id="t-editor-5" v-model="template5" :height="300" />
          </TabPane>
          <TabPane label="数学-填空单空" name="6">
            <TemplateEditor id="t-editor-6" v-model="template6" :height="300" />
          </TabPane>
          <TabPane label="数学-填空双空" name="7">
            <TemplateEditor id="t-editor-7" v-model="template7" :height="300" />
          </TabPane>
          <TabPane label="语文-选句子" name="8">
            <TemplateEditor id="t-editor-8" v-model="template8" :height="300" />
          </TabPane>
        </Tabs>
      </FormItem>

      <FormItem label="题目提示语" style="font-weight: 700">
        <Input v-model="formParams.remark" placeholder="请输入题目提示语" />
        <Radio-group vertical @on-change="changeRemark">
          <!--    填空-单选题（五选一）&&  填空-双空题 &&  填空-三空题  -->
          <Radio :label="remarks[0]">
            <span>{{ remarks[0] }}</span><span style="color: red;"> 填空-单选题（五选一）&&  填空-双空题 &&  填空-三空题</span>
          </Radio>
          <!--     填空-六选二（句子等价）     -->
          <Radio :label="remarks[1]">
            <span>{{ remarks[1] }}</span><span style="color: red;"> 填空-六选二（句子等价）</span>
          </Radio>
          <!--     阅读-单选题（5选一） &&  数学-单选题   -->
          <Radio :label="remarks[2]">
            <span>{{ remarks[2] }}</span><span style="color: red;"> 阅读-单选题（5选一） &&  数学-单选题</span>
          </Radio>
          <!--     阅读-不定项选择题（三选多） &&  数学-不定项选择题   -->
          <Radio :label="remarks[3]">
            <span>{{ remarks[3] }}</span><span style="color: red;"> 阅读-不定项选择题（三选多） &&  数学-不定项选择题</span>
          </Radio>
          <!--     阅读-选择句子（多选一）   -->
          <Radio :label="remarks[4]">
            <span>{{ remarks[4] }}</span><span style="color: red;"> 阅读-选择句子（多选一）</span>
          </Radio>
          <!--     数学-比较大小   -->
          <Radio :label="remarks[5]">
            <span>{{ remarks[5] }}</span><span style="color: red;"> 数学-比较大小</span>
          </Radio>
          <!--     数学-填空题   -->
          <Radio :label="remarks[6]">
            <span>{{ remarks[6] }}</span><span style="color: red;">  数学-填空题</span>
          </Radio>
        </Radio-group>

      </FormItem>

      <FormItem label="题目内容" prop="topicTitle" style="font-weight: 700;">
        <TEditor id="greTopicEditor" v-model="formParams.topicTitle" :height="500" />
      </FormItem>

      <FormItem label="题目答案" prop="topicAnswer" style="font-weight: 700">
        <Input v-model="formParams.topicAnswer" placeholder="请输入答案" style="width: 120px" />
      </FormItem>

      <FormItem label="题目解析" prop="topicAnalysis" style="font-weight: 700">
        <TEditor id="greEditorAnalysis" v-model="formParams.topicAnalysis" :height="400" />
      </FormItem>

      <FormItem>
        <Button type="primary" @click="handleSubmit('formValidate')">确定</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>

  </div>

</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';

import DictMultipleSelect from "../../../components/DictSelect/DictMultipleSelect";
import TEditor from '../../../components/TEditor/TEditor';
import TemplateEditor from '../../../components/TEditor/TemplateEditor'

export default {
  name: "GreTopicAddOrEdit",
  components: {
    DictMultipleSelect,
    DictSelect,
    TEditor,
    TemplateEditor,
  },
  data() {
    return {
      multiple: true,
      // 题目id
      id: this.$storage.get('GreTopicAddOrEdit', 'id'),
      greSubject: null,
      // 题型
      questionType: null,
      // 来源 根据 科目 联动的
      greTopicSource: null,
      // 题型 根据 科目 联动的
      changeQuestionType: null,
      greTypeList: [{
        value: 1,
        label: 'AW'
      }, {
        value: 2,
        label: 'Verbal'
      }, {
        value: 3,
        label: 'Quant'
      }],
      topicTypeList: [{
        value: 1,
        label: '单空单选题'
      }, {
        value: 2,
        label: '双空单选题'
      }, {
        value: 3,
        label: '三空单选题'
      }, {
        value: 4,
        label: '单选'
      }, {
        value: 5,
        label: '多选'
      }, {
        value: 6,
        label: '数学-填空单空'
      }, {
        value: 7,
        label: '数学-填空双空'
      }, {
        value: 8,
        label: '语文-选句子'
      }
      ],
      formParams: {
        greType: null, // 大科目： AW、Verbal、Quant
        questionType: null, // 题型
        subject: null, // 科目
        greSource: null,
        difficulty: null, // 难度，非必填
        topicType: 0, // 题目类型，和模版相关 1:单空单选题 2:双空单选题 3:三空单选题 4:单选 5:多选题 6:数学-填空单空 7:数学-填空双空 8:语文-选句子
        remark: '', // 题目提示语，非必填
        topicTitle: '', // 题目
        topicAnswer: null, // 题目答案
        topicAnalysis: '', // 题目解析
        topicNumber: null, // 题目序号
      },

      topicAnswerList: ['A', 'B', 'C', 'D', 'E'],
      ruleValidate: {
        greType: [{
          required: true, type: 'number', message: '请选择题目大科目', trigger: 'change',
        }],
        subject: [{
          required: true, message: '请选择题目科目', trigger: 'change',
        }],
        // questionType: [{
        //   required: true, type: 'array', min: 1, message: '请选择考点', trigger: 'change',
        // }],
        // source: [{
        //   required: true, message: '请选择来源', trigger: 'change'
        // }],
        topicType: [{
          required: true, type: 'number', message: '请选择题目类型', trigger: 'change'
        }],
        // topicTitle: [{
        //   required: true, message: '请输入题目内容', trigger: 'change'
        // }],
        topicAnswer: [{
          required: true, message: '请输入正确答案', trigger: 'change'
        }],
        topicNumber: [{
          required: true, message: '请输入题目序号', trigger: 'change'
        }]
      },
      template1: `<p>题目详情</p>
                  <div class="options-single-container">
                    <dl>
                      <dt></dt>
                      <dd><button type="button" value="A">optionA</button></dd>
                      <dd><button type="button" value="B">optionB</button></dd>
                      <dd><button type="button" value="C">optionC</button></dd>
                      <dd><button type="button" value="D">optionD</button></dd>
                      <dd><button type="button" value="E">optionE</button></dd>
                    </dl>
                  </div>`,
      template2: `<p>题目详情</p>
                  <div class="options-two-container">
                      <dl>
                          <dt>Blank (i)</dt>
                          <dd><button type="button" name="blank1" value="A">optionA</button></dd>
                          <dd><button type="button" name="blank2" value="B">optionB</button></dd>
                          <dd><button type="button" name="blank3" value="C">optionC</button></dd>
                      </dl>
                      <dl>
                          <dt>Blank (ii)</dt>
                          <dd><button type="button" name="blank1" value="D">optionD</button></dd>
                          <dd><button type="button" name="blank2" value="E">optionE</button></dd>
                          <dd><button type="button" name="blank3" value="F">optionF</button></dd>
                      </dl>
                  </div>`,
      template3: `<p>题目详情</p>
                  <div class="options-container">
                    <dl>
                        <dt>Blank (i)</dt>
                        <dd><button type="button" name="blank1" value="A">optionA</button></dd>
                        <dd><button type="button" name="blank1" value="B">optionB</button></dd>
                        <dd><button type="button" name="blank1" value="C">optionC</button></dd>
                    </dl>

                    <dl>
                        <dt>Blank (ii)</dt>
                        <dd><button type="button" name="blank2" value="D">optionD</button></dd>
                        <dd><button type="button" name="blank2" value="E">optionE</button></dd>
                        <dd><button type="button" name="blank2" value="F">optionF</button></dd>
                    </dl>

                    <dl>
                        <dt>Blank (iii)</dt>
                        <dd><button type="button" name="blank3" value="G">optionG</button></dd>
                        <dd><button type="button" name="blank3" value="H">optionH</button></dd>
                        <dd><button type="button" name="blank3" value="I">optionI</button></dd>
                    </dl>
                  </div>`,
      template4: `<p>题目详情</p>
                  <p><strong>问题</strong></p>
                  <form id="questionSingleChoiceForm">
                    <label style="display: flex;">
                        <input type="radio" name="option" value="A">optionA
                    </label>
                    <label style="display: flex;">
                        <input type="radio" name="option" value="B">optionB
                    </label>
                    <label style="display: flex;">
                        <input type="radio" name="option" value="C">optionC
                    </label>
                    <label style="display: flex;">
                        <input type="radio" name="option" value="D">optionD
                    </label>
                    <label style="display: flex;">
                        <input type="radio" name="option" value="E">optionE
                    </label>
                    <label style="display: flex;">
                        <input type="radio" name="option" value="F">optionF
                    </label>
                  </form>`,
      template5:  `<p>题目详情</p>
                  <form class="questionMultipleChoiceForm">
                      <label style="display: block;">
                        <input type="checkbox" name="celestialObjects" value="A"> <span>optionA</span>
                      </label>
                      <label style="display: block;">
                        <input type="checkbox" name="celestialObjects" value="B"> <span>optionB</span>
                      </label>
                      <label style="display: block;">
                        <input type="checkbox" name="celestialObjects" value="C"> <span>optionC</span>
                      </label>
                      <label style="display: block;">
                        <input type="checkbox" name="celestialObjects" value="D"> <span>optionD</span>
                      </label>
                      <label style="display: block;">
                        <input type="checkbox" name="celestialObjects" value="E"> <span>optionE</span>
                      </label>
                      <label style="display: block;">
                        <input type="checkbox" name="celestialObjects" value="F"> <span>optionF</span>
                      </label>
                  </form>`,
      template6: `<p>题目详情</p>
                  <p class="oneGapFilling"><input type="number" id="gapFillingInput" step="0.1" style="border: 1px solid black; font-size: 16px; text-align: left; width: 120px; margin: 5px; padding: 5px;"></p>`,
      template7: `<p>题目详情</p>
                  <div class="fraction">
                      <input type="number" id="numerator" name="numerator" required style="border: 1px solid black; font-size: 16px; text-align: center; width: 80px; margin: 5px; padding: 5px;">
                      <div class="fraction-bar" style="height: 2px; background-color: black; margin: 2px 0 2px 5px; width: 92px;"></div>
                      <input type="number" id="denominator" name="denominator" required style="border: 1px solid black; font-size: 16px; text-align: center; width: 80px; margin: 5px; padding: 5px;">
                  </div>`,
      template8: `<div id="verbalPassage" style="line-height: 30px;">
                      <span class="sentence" data-value="A"">句子A.</span>
                      <span class="sentence" data-value="B"">句子B.</span>
                      <span class="sentence" data-value="C"">句子C.</span>
                      <span class="sentence" data-value="D"">句子D.</span>
                      <span class="sentence" data-value="E"">句子E.</span>
                      <span class="sentence" data-value="F"">句子F.</span>
                  </div>
                  <p>Select the sentence in which the author expresses an opinion towards the results of the study.</p>`,
      remarks: [
          'For each blank select one word from each column that best completes the sentence.',
          'Select exactly two answer choice that best completes the sentence and produce sentences that are alike in meaning.',
          'Choose the option that best answers the question.',
          'Consider each of the choices separately and select all that apply.',
          'Select the sentence from the passage that best answers the question.',
          'Choose the correct statement.',
          'Enter the answer in the blank.'
      ]
    }
  },
  methods: {
    changeRemark(value) {
      console.log('change ====>', value)
      this.formParams.remark = value
    },
    handleEditorChange({ quill, html, text }) {
      console.log('Editor content changed:', html)
    },

    queryDetail(id) {
      this.$axios.get(`/api/gre/topic/details/${id}`, {}, {})
          .then(res => {
            console.log('res====>', res);
            if(res.code === '10000') {
              let data = res.body;

              this.formParams.subject = data.subject;
              this.greSubject = data.subject;

              if (data.subject === '填空') {
                this.changeQuestionType = 'gapfillingGreQuestionType';
              } else if (data.subject === '阅读') {
                this.changeQuestionType = 'readGreQuestionType';
              } else if (data.subject === '逻辑') {
                this.changeQuestionType = 'logicGreQuestionType';
              } else if (data.subject === '数学') {
                this.changeQuestionType = 'mathGreQuestionType';
              }

              this.questionType = data.examinationSite.split(',');
              this.formParams.questionType = data.examinationSite.split(',');
              this.formParams.greSource = data.topicSource;
              this.formParams.difficulty = data.difficulty;
              this.formParams.topicNumber = data.topicNumber ? data.topicNumber.toString() : '';
              this.formParams.greType = parseInt(data.type);
              this.formParams.topicType = data.topicType;

              let topicTitle = data.topicTitle;
              if (topicTitle && (topicTitle.indexOf("<p>") === -1 || topicTitle.indexOf("</p>") === -1)) {
                topicTitle = "<p>" + topicTitle + "</p>"
              }

              this.formParams.topicTitle = topicTitle;
              this.formParams.topicAnswer = data.topicAnswer;
              this.formParams.topicAnalysis = data.topicAnalysis ? data.topicAnalysis : '';
              this.formParams.remark = data.remark;

            }
          })
          .catch(error => {
            console.log(error);
          })

    },

    selectSubjectChange(param) {
      console.log('param====>', param)
      this.formParams.subject = param;

      this.formParams.questionType = null;

    },

    selectSubjectItemChange(item) {
      console.log('item======>', item)

      this.changeQuestionType = `${item.value.toLowerCase()}GreQuestionType`;

      this.formParams.questionType = null;

    },
    selectQuestionTypeChange(param) {
      console.log('topicAddOrEdit param===>', param)
      this.questionType = param;
      console.log('questionType show===>', param)
      this.formParams.questionType = param;
    },
    selectSourceChange(param) {
      this.formParams.greSource = param;
    },
    selectTopicDifficultyChange(param) {
      this.formParams.difficulty = param;
    },


    // 确定
    handleSubmit() {
      let url = '/api/gre/topic/handle';
      console.log('this.formParams.subject====>', this.formParams.subject);

      let requestVo = {
        type: this.formParams.greType, // 大科目 1.AW  2.Verbal  3.Quant
        subject: this.formParams.subject,
        examinationSite: this.formParams.questionType ? this.formParams.questionType.toString() : null,
        topicSource: this.formParams.greSource,
        difficulty: this.formParams.difficulty,
        topicType: this.formParams.topicType,
        topicTitle: this.formParams.topicTitle,
        topicAnswer: this.formParams.topicAnswer,
        topicAnalysis: this.formParams.topicAnalysis,
        topicNumber: parseInt(this.formParams.topicNumber),
        remark: this.formParams.remark,
      }

      console.log('requestVo====>', requestVo)

      let id = this.id;
      if (id) {
        requestVo = Object.assign({}, requestVo, { id });
      }

      this.$refs.ruleValidate.validate(valid => {
        if (valid) {
          this.$axios.post(url, requestVo, {})
              .then(res => {
                if (res.code === '10000') {
                  this.$Message.success('保存成功');
                  localStorage.setItem('isRemove', JSON.stringify(true));
                  this.$storage.remove('GreTopicAddOrEdit', 'id');
                  this.$router.push({ path:`/gre/topic/manage` });
                }
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          return false;
        }
      })

    },
    // 取消
    handleReset() {
      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$storage.remove('GreTopicAddOrEdit', 'id');
      this.$router.push({ path:`/gre/topic/manage` });
    },
  },

  watch:{
    "context":{
      handler(newVal, oldVal) {
        this.formParams.context = newVal;
        console.log('newVal',newVal)
      }
    }
  },
  mounted() {
    let id = this.id;
    if (id) {
      this.queryDetail(id);
    }
  },
}
</script>

<style lang="less" scoped>

.topicForm{
  .ivu-form-item{
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
</style>

