import { render, staticRenderFns } from "./FeedbackList.vue?vue&type=template&id=e86d287a&scoped=true&"
import script from "./FeedbackList.vue?vue&type=script&lang=js&"
export * from "./FeedbackList.vue?vue&type=script&lang=js&"
import style0 from "./FeedbackList.vue?vue&type=style&index=0&id=e86d287a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e86d287a",
  null
  
)

export default component.exports