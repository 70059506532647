<template>
  <!-- 题目管理 列表 -->
  <div>
    <div class="books-list-top">
      <div style="display: flex; align-items: center">
        <div>
          <span>科目：</span>
          <dict-select
              style="width: 180px;display: inline-block; margin-right: 10px"
              v-model="queryDataForm.subject"
              :value="queryDataForm.subject"
              dict-key="subject"
              select-type="NORMAL"
              @on-change="selectSubjectChange"
          ></dict-select>
        </div>
        <div v-if="queryDataForm.subject !== 'Verbal' && queryDataForm.subject !== 'Quant'">
          <span>考点：</span>
          <dict-multiple-select
              style="width: 380px;display: inline-block; margin-right: 10px"
              v-model="examinationSite"
              :isDisabled="!queryDataForm.subject"
              :value="examinationSite"
              :dict-key="changeExaminationSite"
              select-type="NORMAL"
              default-value="show"
              @on-change="selectExaminationSiteChange"
          ></dict-multiple-select>
        </div>
      </div>

      <div style="display: flex; align-items: center; ">
        <Input style="width: 300px; margin-right: 10px" v-model="queryDataForm.keywords"
               search enter-button="搜索" placeholder="请输入名称"
               @on-search="search"
        />
        <Button type="default" @click="reset">重置</Button>
      </div>
    </div>
    <div style="display: flex; align-items: center; margin-top: 10px">
      <div>
        <span>来源：</span>
        <dict-select
            style="width: 180px;display: inline-block; margin-right: 10px"
            v-model="queryDataForm.topicSource"
            :value="queryDataForm.topicSource"
            :isDisabled="!queryDataForm.subject"
            :dict-key="source"
            select-type="NORMAL"
            @on-change="selectSourceChange"
        ></dict-select>
      </div>
      <div>
        <span>难度：</span>
        <dict-select
            style="width: 180px;display: inline-block;"
            v-model="queryDataForm.difficulty"
            :value="queryDataForm.difficulty"
            dict-key="topicDifficulty"
            select-type="NORMAL"
            @on-change="selectTopicDifficultyChange"
        ></dict-select>
      </div>
    </div>
    <div style="margin-top: 10px;">
      <Button type="primary" @click="addTopic" shape="circle" style="margin-right: 10px; width: 100px">新建题目</Button>
      <Button type="primary" @click="handlePublish" shape="circle" style="margin-right: 10px; width: 100px">上架</Button>
    </div>

    <div>
    </div>

    <Table
        class="topicListTable"
        border style="margin-top: 15px;"
        highlight-row
        :loading="loading"
        ref="selection"
        :columns="columns"
        :data="dataObject"
        @on-selection-change="onSelectionChangeTable"
        @on-sort-change="onSortChangeTable"
    >
    </Table>
    <Row style="margin-top:15px;float:right;">
      <Page :total="pageData.total"
            :page-size="pageData.size"
            :current="pageData.page"
            @on-change="pageOnChange"
            @on-page-size-change="pageSizeChange"
            show-total show-sizer show-elevator></Page>
    </Row>

    <!--  显示|编辑正确率 Modal  -->
    <Modal v-model="accuracyModal" :mask-closable="false" title="答案正确率">
      <p style="padding-left: 20px;">{{ this.topicInfo }}</p>
      <Form ref="accuracyItem" :model="accuracyForm" :label-width="100" style="padding: 20px 20px 0" id="accuracyForm">
        <FormItem label="选项A">
          <Row :gutter="10">
            <Col span="12"><Input type="number" v-model="accuracyForm.valueA" /></Col>
            <Col span="12"><Input v-model="valueAccuracyA" disabled></Input></Col>
          </Row>
        </FormItem>
        <FormItem label="选项B">
          <Row :gutter="10">
            <Col span="12"><Input type="number" v-model="accuracyForm.valueB" /></Col>
            <Col span="12"><Input v-model="valueAccuracyB" disabled></Input></Col>
          </Row>
        </FormItem>
        <FormItem label="选项C">
          <Row :gutter="10">
            <Col span="12"><Input type="number" v-model="accuracyForm.valueC" /></Col>
            <Col span="12"><Input v-model="valueAccuracyC" disabled></Input></Col>
          </Row>
        </FormItem>
        <FormItem label="选项D">
          <Row :gutter="10">
            <Col span="12"><Input type="number" v-model="accuracyForm.valueD" /></Col>
            <Col span="12"><Input v-model="valueAccuracyD" disabled></Input></Col>
          </Row>
        </FormItem>
        <FormItem label="选项E">
          <Row :gutter="10">
            <Col span="12"><Input type="number" v-model="accuracyForm.valueE" /></Col>
            <Col span="12"><Input v-model="valueAccuracyE" disabled></Input></Col>
          </Row>
        </FormItem>
        <FormItem label="做题人数">
          <Input type="number" v-model="accuracyForm.valueUserCount" />
        </FormItem>
        <FormItem label="做题总时间">
          <Input type="number" v-model="accuracyForm.valueTotalTime" />
        </FormItem>
        <FormItem label="做题次数">
          <Input type="number" v-model="accuracyForm.valueCount" />
        </FormItem>
        <FormItem label="做题正确人数">
          <Input type="number" v-model="accuracyForm.valueCorrect" />
        </FormItem>
      </Form>

      <div slot="footer">
        <Button type="primary" @click="handleAccuracySave">保存</Button>
        <Button type="default" @click="handleAccuracyCancel('accuracyItem')">取消</Button>
      </div>
    </Modal>

    <!--  题目预览 Modal  -->
    <Modal v-model="topicPreviewModal" :mask-closable="false" title="题目预览" width="790">
      <Card style="width: 760px">
        <div class="topic-content">
          <div>
            <div ref="topicHtml" :class='isImgTopicTitle ? "topic-title topic-title-special" : "topic-title"' v-html="currentTopicContent.topicTitle"></div>
          </div>
          <div class="options" v-if="currentTopicContent.type !== 3">
            <p>(A) {{ currentTopicContent.topicOptionA }}</p>
            <p>(B) {{ currentTopicContent.topicOptionB }}</p>
            <p>(C) {{ currentTopicContent.topicOptionC }}</p>
            <p>(D) {{ currentTopicContent.topicOptionD }}</p>
            <p>(E) {{ currentTopicContent.topicOptionE }}</p>
          </div>
        </div>
      </Card>
      <div slot="footer">
        <Button type="default" @click="topicPreviewModal=false;isImgTopicTitle=false;">取消</Button>
      </div>
    </Modal>

  </div>

</template>

<script>
import DictSelect from '../../../components/DictSelect/DictSelect';
import DictMultipleSelect from "../../../components/DictSelect/DictMultipleSelect";
import { computeAccuracy } from '../../../util/compute';
// import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
  name: "TopicList",
  components: {
    DictSelect,
    DictMultipleSelect,
    // 'vue-ueditor-wrap': VueUeditorWrap
  },
  data() {
    let data = this.$storage.get('TopicList', 'queryDataForm');
    let subject = null;
    if (data) {
      if (data.subject) {
        subject = data.subject
      } else if (data.type) {
        if (data.type === 1) {
          subject = 'Verbal'
        } else if (data.type === 2) {
          subject = 'Quant'
        }
      }
    }
    return {
      examinationSite: data ? data.examinationSite ? data.examinationSite.split(',') : null : null,
      queryDataForm: {
        keywords: data ? data.keywords ? data.keywords : null : null,
        subject: subject,
        examinationSite: data ? data.examinationSite ? data.examinationSite : null : null,
        topicSource: data ? data.topicSource ? data.topicSource : null : null,
        difficulty: data ? data.difficulty ? data.difficulty : null : null,
      },
      // 来源 根据 科目 联动的
      source: null,
      // 考点 根据 科目 联动的
      changeExaminationSite: null,
      // 列表加载
      loading: true,

      // 选择项
      selectArr: [],
      //题目类型参数
      roleList: {
        id: null,
        roleName: null
      },
      //题目类型返回数据
      roleListArray:[],
      // 数据
      dataObject: [],
      //当前选中的题目id
      currentTopicId: null,
      // 正确率form数据
      accuracyForm: {
        valueA: null,
        valueB: null,
        valueC: null,
        valueD: null,
        valueE: null,
        valueUserCount: null, // 做题人数
        valueTotalTime: null, // 做题总时间
        valueCount: null, // 做题次数
        valueCorrect: null,  // 题目正确人数
      },
      currentAccuracyId: null, // 当前题目正确率的id
      valueAccuracyA: null, // A 选项的正确率
      valueAccuracyB: null, // B 选项的正确率
      valueAccuracyC: null, // C 选项的正确率
      valueAccuracyD: null, // D 选项的正确率
      valueAccuracyE: null, // E 选项的正确率
      accuracyModal: false, // 是否显示正确率的模态框
      topicInfo: '', // 题目信息：科目-来源-序号
      topicPreviewModal: false,  // 是否显示 预览 的模态框
      currentTopicContent: {},  // 当前题目的信息
      isImgTopicTitle: false, // 判断题目内容有没有img图片
      //分页字段
      pageData: {
        page: 1,
        size: 10,
        total: 0,
        // prop: 'createdDate', //默认排序字段
        // direction: 'DESC' //默认排序方向
      },
      topicIds: [],
      selectArrData: [],
      publishArr: [{
        isPublish: 0,
        label: '上架'
      }, {
        isPublish: 1,
        label: '下架'
      }],
      selectCouldUpdate: false,   // 是否允许修改 题目题型
      // 题目类型
      topicTypeData: [],
      // 列表数据
      columns: [
        {
          title: '',
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '编号',
          type: 'index',
          minWidth: 80,
          align: 'center',
        },
        {
          title: 'ID',
          key: 'id',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '题目',
          key: 'topicTitle',
          minWidth: 460,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            let content = params.row.topicTitle
            return h('Poptip', {
              props: {
                trigger: 'hover',
                placement: 'top',
                wordWrap: true,
              }
            }, [
              h('div', {
                domProps: {
                  innerHTML: content
                },
                style: {
                  minWidth: '400px',
                  maxHeight: '48px',
                  display: '-webkit-box',
                  webkitLineClamp: 1,
                  webkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  margin: '0 auto',
                }
              }),
              h('div', {
                slot: 'content',
              }, [
                h('div', {
                  domProps: {
                    innerHTML: content
                  },
                  style: {
                    width: '360px',
                    maxHeight: '500px',
                    wordWrap: 'break-word',
                    wordBreak: 'normal'
                  }
                })
              ])
            ])
          },
          props: {
            level: {
              type: Number,
              require: true
            }
          }
        },
        {
          title: '科目',
          key: 'subject',
          width: 80,
          align: 'center',
        },
        {
          title: '考点',
          key: 'examinationSite',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '来源',
          key: 'topicSource',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '难度',
          key: 'difficulty',
          minWidth: 120,
          align: 'center',
          sortable: true,
          // sortMethod: (a, b, type) => {
          //
          //   this.pageData.direction = type.toUpperCase();
          //
          // }
        },
        {
          title: '题目类型',
          key: 'roleListEntityList',
          minWidth: 240,
          align: 'center',
          render: (h, params) => {
            return h('Select', {
                  props: {
                    value: params.row.roleListArray,
                    multiple: true,
                  },
                  on: {
                    'on-open-change': value => {
                      this.selectCouldUpdate = value;
                    },
                    'on-change': value => {
                      if(this.selectCouldUpdate) {
                        this.handleTopicType(params.row.id, value);
                      }
                    }
                  }
                },
                this.topicTypeData.map(item => {
                  return h('Option', {
                    props: {
                      value: item.id,
                    }
                  }, item.resourcesName)
                })
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 380,
          align: 'center',
          render: (h, params) => {
            let isPublish = params.row.isPublish;
            let show = isPublish === 0 ? this.publishArr[0].label : this.publishArr[1].label;
            let publishId = isPublish === 0 ? this.publishArr[1].isPublish : this.publishArr[0].isPublish;
            return h('div', [
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0'
                },
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.$storage.set('Comment', 'topicId', params.row.id);
                    this.$router.push({
                      path: '/notice/comment/CommentManage'
                    })
                  }
                }
              }, '查看留言'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0'
                },
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.topicPreviewModal = true;
                    console.log('topic single ====>', params.row);
                    this.currentTopicContent = params.row;
                    if (params.row.topicTitle.search(/<img /i) >= 0) {
                      this.isImgTopicTitle = true;
                    }

                    // IR题目展现样式修改
                    this.$nextTick(() => {
                      // 题目材料中 表格排序
                      // 获取表头行tr和表格体tbody
                      const tbody = this.$refs.topicHtml.querySelector('tbody');
                      const thead = this.$refs.topicHtml.querySelector('thead');
                      if (tbody && thead) {
                        console.log('thead====>', thead)
                        const tr = thead.querySelector('tr');

                        // 获取前5个表头th元素
                        const ths = Array.from(tr.querySelectorAll('th')).slice(0, 5);
                        console.log('th=====>', ths)
                        console.log('tbody.rows====>', tbody.rows)

                        // 创建排序函数
                        function sortTable(columnIndex, ascending) {
                          const rows = Array.from(tbody.querySelectorAll('tr'));
                          const sortMethod = (a, b) => {
                            const aValue = a.cells[columnIndex].textContent.trim();
                            const bValue = b.cells[columnIndex].textContent.trim();
                            if (!isNaN(aValue) && !isNaN(bValue)) {
                              return ascending ? aValue - bValue : bValue - aValue;
                            } else {
                              return ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                            }
                          };
                          rows.sort(sortMethod);
                          rows.forEach(row => tbody.appendChild(row));
                        }


                        let prevIndex = -1; // 记录上一个被点击的th元素索引
                        // 为每个表头th元素分别绑定点击事件
                        ths.forEach((th, index) => {
                          th.addEventListener('click', () => {
                            // 恢复上一个被点击的元素的样式
                            if (prevIndex !== -1 && prevIndex !== index) {
                              console.log('prevIndex====>恢复====>', prevIndex)
                              ths[prevIndex].classList.remove('ascending')
                              ths[prevIndex].classList.remove( 'descending')
                              ths[prevIndex].style.borderBottom = 'none'
                            }
                            th.style.borderBottom = "1px solid #000";
                            prevIndex = index;
                            // 判断当前排序状态并根据情况调用排序函数
                            const isAscending = th.classList.contains('ascending');
                            console.log('isAscending=====>', isAscending)
                            sortTable(index, !isAscending);
                            // th.classList.toggle('ascending');
                            if (!isAscending) {
                              th.classList.remove('descending')
                              th.classList.add('ascending')
                            } else {
                              th.classList.remove('ascending')
                              th.classList.add('descending')
                            }

                            console.log('index====>', index)
                          });
                        });

                      }

                      // 多tabs题目
                      if (this.$refs.topicHtml.querySelectorAll('.tabs-btn').length > 0) {
                        const tabBtns = this.$refs.topicHtml.querySelectorAll('.tabs-btn')
                        console.log('tabBtns=========>', tabBtns)
                        const tabContents = this.$refs.topicHtml.querySelectorAll('.content-tab')
                        tabBtns[0].classList.add('active')
                        tabContents[0].classList.add('active')

                        tabBtns.forEach(btn => {
                          btn.addEventListener('click', () => {
                            // 切换按钮的选中状态
                            tabBtns.forEach(otherBtn => {
                              if (otherBtn !== btn) {
                                otherBtn.classList.remove('active')
                              } else {
                                otherBtn.classList.add('active')
                              }
                            })

                            // 切换对应的div内容的显示/隐藏
                            const btnIndex = Array.from(tabBtns).indexOf(btn)
                            tabContents.forEach((content, index) => {
                              if (index === btnIndex) {
                                content.style.display = 'block'
                              } else {
                                content.style.display = 'none'
                              }
                            })

                          })
                        })
                      }
                      console.log('topicType======>', params.row.topicType)
                      /**
                       * topicType 题目类型
                       * 1 基础题型             之前除IR之外的题目
                       * 2 纵向单个选择          IR题型
                       * 3 横向多个选择          IR题型
                       * 4 图表纵向两个选择      IR题型
                       * 5 下拉选择             IR题型
                       * */

                      let topicTypes = params.row.topicType // 因为IR题目新增了题目类型字段
                      // 2.多选项单选 topicType === 2 纵向单个选择
                      if(topicTypes === 2) {
                        const singleChoiceBtns = this.$refs.topicHtml.querySelectorAll('.li-choice')
                        console.log('singleChoiceBtns=====>', singleChoiceBtns)
                        const singleChoiceContents = this.$refs.topicHtml.querySelectorAll('.choice-content')
                        console.log('singleChoiceContents======>', singleChoiceContents)
                        // 遍历当前li下面的所有单选框，修改图标样式
                        singleChoiceBtns.forEach(btn => {
                          btn.classList.add('fa', 'fa-circle-o')
                          const answer = btn.dataset.choice // 获取当前图标的 data-choice 值
                          btn.addEventListener('click', () => {
                            this.choiceTopicAnswer = answer
                            console.log('choiceTopicAnswer====>topicType=2======>', answer) // 答案
                            singleChoiceBtns.forEach(othenBtn => {
                              if (othenBtn !== btn) {
                                othenBtn.classList.remove('fa-dot-circle-o')
                                othenBtn.classList.add('fa-circle-o')
                              } else {
                                othenBtn.classList.remove('fa-circle-o')
                                othenBtn.classList.add('fa-dot-circle-o')
                              }
                            })
                          })
                        })
                        // singleChoiceContents.forEach(con => {
                        //   con.style.maxWidth = '89%'
                        // })

                      }

                      // 3.多题 True or False选择 topicType === 3 横向多个选择
                      if(topicTypes === 3) {
                        const choiceBtns = this.$refs.topicHtml.querySelectorAll('.li-multi-choice')
                        let liSelectedChoices = {}
                        for(let i = 0; i < choiceBtns.length; i++) {
                          choiceBtns[i].classList.add('fa', 'fa-circle-o')
                          const choiceBtn = choiceBtns[i]
                          const answer = choiceBtn.dataset.choice // 获取当前图标的 data-choice 值

                          choiceBtn.addEventListener('click', event => {
                            // 找到当前单选框所在行的li元素
                            const li = choiceBtn.parentNode
                            const liIdx = li.getAttribute('data-index')
                            console.log('li======>', li)
                            console.log('liIdx======>', liIdx)

                            // 获取到当前li里面的选项按钮
                            const rowChoiceBtns = li.querySelectorAll('.li-multi-choice')

                            // 遍历当前li下面的所有单选框，修改图标样式
                            for(let j = 0;  j < rowChoiceBtns.length; j++) {
                              const btn = rowChoiceBtns[j]
                              if (btn === choiceBtn) {
                                btn.classList.remove('fa-circle-o')
                                btn.classList.add('fa-dot-circle-o')
                              } else {
                                btn.classList.remove('fa-dot-circle-o')
                                btn.classList.add('fa-circle-o')
                              }
                            }

                            const rowChoiceAnswers = this.$refs.topicHtml.querySelectorAll(`[data-choice="${answer}"]`)
                            console.log('rowChoiceAnswers=====>', rowChoiceAnswers)
                            // 遍历所有单选框，获取点击之后的answer值，A/B
                            for (let k = 0; k < rowChoiceAnswers.length; k++) {
                              const btn = rowChoiceAnswers[k]
                              if (btn === choiceBtn) { // 如果是当前点击的单选框，则加选中样式
                                liSelectedChoices[liIdx] = btn.dataset.choice // 选择的答案
                                console.log(liSelectedChoices)
                                this.choiceTopicAnswer = Object.values(liSelectedChoices).toString()
                                return btn.dataset.choice
                              }
                            }
                          })
                        }


                      }

                      // 4.图表选择 topicType === 4 图表纵向两个选择
                      if(topicTypes === 4) {
                        const tableChoiceBtns = this.$refs.topicHtml.querySelectorAll('.vertical-choice')
                        let tableSelectedChoices = {}
                        console.log('tableChoiceBtns=====>', tableChoiceBtns)
                        for(let i = 0; i < tableChoiceBtns.length; i++) {
                          tableChoiceBtns[i].classList.add('fa', 'fa-circle-o')
                          const choiceBtn = tableChoiceBtns[i]
                          const index = choiceBtn.dataset.index // 获取当前图标的 data-index 值
                          console.log('index========>', index)
                          const answer = choiceBtn.dataset.choice // 获取当前图标的 data-choice 值

                          // 绑定点击事件
                          choiceBtn.addEventListener('click', event => {
                            console.log('event=====>', event)
                            // 找到所有与该单选框图标所在列数相同的单选框
                            const columnChoiceBtns = this.$refs.topicHtml.querySelectorAll(`[data-index="${index}"]`)

                            // 遍历所有单选框，修改图标样式
                            for (let j = 0; j < columnChoiceBtns.length; j++) {
                              const btn = columnChoiceBtns[j]
                              if (btn === choiceBtn) { // 如果是当前点击的单选框，则加选中样式
                                btn.classList.remove('fa-circle-o')
                                btn.classList.add('fa-dot-circle-o')
                              } else { // 否则加未选中样式
                                btn.classList.remove('fa-dot-circle-o')
                                btn.classList.add('fa-circle-o')
                              }
                            }

                            const columnChoiceAnswers = this.$refs.topicHtml.querySelectorAll(`[data-choice="${answer}"]`)
                            // 遍历所有单选框，获取点击之后的answer
                            for (let k = 0; k < columnChoiceAnswers.length; k++) {
                              const btn = columnChoiceAnswers[k]
                              if (btn === choiceBtn) { // 如果是当前点击的单选框，则加选中样式
                                console.log('btn.data-choice======>', btn.dataset.choice)
                                tableSelectedChoices[index] = btn.dataset.choice // 选择的答案
                                console.log(tableSelectedChoices)
                                this.choiceTopicAnswer = Object.values(tableSelectedChoices).toString()
                                return btn.dataset.choice
                              }
                            }

                          })
                        }

                      }

                      // 5.下拉选择题目 topicType === 5 下拉选择
                      if(topicTypes === 5) {
                        const selects = this.$refs.topicHtml.querySelectorAll('select')
                        const selectedChoices = []
                        const selectedChoicesContent = []
                        for (let i = 0; i < selects.length; i++) {
                          const select = selects[i]
                          select.addEventListener('change', event => {
                            const currentSelect = event.target
                            console.log('event.target=====>', currentSelect)
                            console.log(currentSelect.value)  // select选择的值

                            const selectedIndex = currentSelect.selectedIndex;
                            console.log('selectedIndex====>', selectedIndex)
                            console.log('i====>', i)
                            const selectedOption = currentSelect.options[selectedIndex];
                            const selectedChoice = selectedOption.dataset.choice; // option项的data-choice的值
                            console.log(selectedChoice);
                            const selectedText = selectedOption.textContent;  // 选择option的内容
                            console.log(selectedText);
                            selectedChoices[i] = selectedChoice
                            selectedChoicesContent[i] = selectedText
                            console.log('selectedChoices=====>', selectedChoices)
                            console.log('selectedChoicesContent=====>', selectedChoicesContent)
                            this.selectedChoices = selectedChoices
                            this.selectedChoicesContent = selectedChoicesContent
                            this.choiceTopicAnswer = selectedChoices.toString()
                          })
                        }

                      }

                    })

                  }
                }
              }, '预览'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    this.$router.push({
                      path: `/content/topic/TopicAddOrEdit`
                    });
                    this.$storage.set('TopicAddOrEdit', 'id', params.row.id);
                    this.$storage.set('TopicList', 'page', this.pageData.page);
                    let subject = this.queryDataForm.subject;
                    let requestVo = {
                      type: subject === 'Verbal' ? 1 : 2,
                      keywords: this.queryDataForm.keywords,
                      topicSource: this.queryDataForm.topicSource,
                      difficulty: this.queryDataForm.difficulty,
                      examinationSite: this.queryDataForm.examinationSite,
                    };
                    this.$storage.set('TopicList', 'queryDataForm', subject === 'Verbal' || subject === 'Quant' ? requestVo : this.queryDataForm);
                  }
                }
              }, '编辑'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    this.topicInfo = params.row.subject+'--'+params.row.topicSource+'--'+params.row.topicNumber;
                    this.accuracyModal = true;
                    this.currentTopicId = params.row.id;
                    this.$axios.get(`/api/topic/data/details/${params.row.id}`).then(res=> {
                      console.log('res===>', res);
                      let data = res.body;
                      this.currentAccuracyId = res.body.id;
                      this.accuracyForm.valueA = data.optionASelectivity;
                      this.accuracyForm.valueB = data.optionBSelectivity;
                      this.accuracyForm.valueC = data.optionCSelectivity;
                      this.accuracyForm.valueD = data.optionDSelectivity;
                      this.accuracyForm.valueE = data.optionESelectivity;
                      this.accuracyForm.valueUserCount = data.doTopicUserNumber;
                      this.accuracyForm.valueTotalTime = data.doTopicTotalTime;
                      this.accuracyForm.valueCount = data.doTopicCount;
                      this.accuracyForm.valueCorrect = data.correct;

                      this.valueAccuracyA = computeAccuracy(this.accuracyForm.valueA, this.accuracyForm.valueCount);
                      this.valueAccuracyB = computeAccuracy(this.accuracyForm.valueB, this.accuracyForm.valueCount);
                      this.valueAccuracyC = computeAccuracy(this.accuracyForm.valueC, this.accuracyForm.valueCount);
                      this.valueAccuracyD = computeAccuracy(this.accuracyForm.valueD, this.accuracyForm.valueCount);
                      this.valueAccuracyE = computeAccuracy(this.accuracyForm.valueE, this.accuracyForm.valueCount);
                    }).catch(error => {
                      console.log('error===>', error);
                    });
                  }
                }
              }, '正确率'),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    let url = '/api/topic/publish';
                    let requestVo = {
                      isPublish: publishId,
                      topicIds: [params.row.id],
                    };

                    console.log('requestVo====', requestVo);

                    this.$Modal.confirm({
                      title: '提示',
                      content: '<p>您确定' + show + '该题目吗？</p>',
                      onOk: () => {
                        this.$axios.post(url, requestVo, {})
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success('上架成功');
                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                              }
                            })
                            .catch(error => {
                              this.$Message.error(show + '失败');
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning("您已经取消了" + show);
                      }
                    })

                  }
                }
              }, show),
              h('Button', {
                style: {
                  margin: '5px 5px 5px 0',
                },
                props: {
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                },
                on: {
                  click: () => {

                    let url = '/api/topic/delete/' + params.row.id;
                    let requestVo = {};

                    this.$Modal.confirm({
                      title: '提示',
                      content: '<p>确定删除该题目？</p>',
                      onOk: () => {
                        this.$axios.post(url, requestVo, {})
                            .then(res => {
                              console.log('res ==>', res)
                              if (res.code === '10000') {
                                this.$Message.success('删除成功');

                                this.query(this.pageData.page, this.pageData.size, this.queryDataForm)
                              }
                            })
                            .catch(error => {
                              this.$Message.error('删除失败');
                            })
                      },

                      onCancel: () => {
                        this.$Message.warning("您已经取消了删除");
                      }
                    })

                  },
                }
              }, '删除')
            ])
          }
        }

      ]
    }
  },
  methods: {

    // 查询列表
    query(page = 1, size = 10, requestVo = {}) {
      this.pageData.page = page;
      this.pageData.size = size;
      let pageObj = {
        page: page - 1,
        size: size,
        // sort: this.pageData.prop + ',' + this.pageData.direction,
      }

      this.$axios.post("/api/topic/page", requestVo, {params: pageObj})
          .then(res => {
            console.log('res===>', res)
            if (res.code === '10000') {
              let resParam = res.body.content
              this.dataObject = []
              this.dataObject = res.body.content
              this.pageData.total = res.body.totalElements
              this.loading = false

              for (let i = 0; i < this.dataObject.length; i++) {
                if (this.dataObject[i].isPublish === 1) {
                  this.dataObject[i]._disabled = true
                }
                let roleListArray = []
                this.dataObject[i].roleListEntityList.forEach(item =>{
                  roleListArray.push(item.id)
                })

                this.dataObject[i].roleListArray = roleListArray
              }
            }
          })
          .catch(error => {
            console.log('error===>', error)
            if (error.code === '10000') {
              this.dataObject = []
              this.dataObject = error.body.content
              this.pageData.total = error.body.totalElements
              this.loading = false
            }
          })
    },

    // 获取题目类型数据
    queryTopicTypeData() {
      this.$axios.post('/api/student/role/resources/list').then(res => {
        if (res.code === '10000') {
          this.topicTypeData = res.body
        }
      })
    },

    // 选择题目类型 提交到 后台
    handleTopicType(id, data) {
      console.log('data===>', data);

      let requestVo = {
        id: id,
        topicRoles: data.length>0 ? data.toString() : ''
      };
      this.$axios.post('/api/topic/handle/reqpermissions', requestVo).then(res => {
        if (res.code === '10000') {
          // this.$Message.success('选择成功')
        }
      }).catch(error => {
        console.log('error===>', error)
      });

    },

    // 新建题目
    addTopic() {
      this.$router.push({
        path: '/content/topic/TopicAddOrEdit'
      });
    },

    // 批量上架
    handlePublish() {
      //  没有选择学员时点击
      if (this.topicIds.length === 0) {
        this.$Message.warning('请选择题目')
      } else {

        this.$Modal.confirm({
          title: '提示',
          content: '<p>您确定上架这些题目吗？</p>',
          onOk: () => {
            this.publishTopic();
          },

          onCancel: () => {
            this.topicIds = [];
            this.selectArrData = [];
            this.query(this.pageData.page, this.pageData.size, this.queryDataForm);
            this.$Message.warning("您已经取消了上架");
          }
        })
      }

    },

    // 上架题目
    publishTopic() {
      let url = '/api/topic/publish';
      let requestVo = {
        isPublish: 1,
        topicIds: this.topicIds,
      };
      this.$axios.post(url, requestVo, {})
          .then(res => {
            console.log('res ==>', res)
            if (res.code === '10000') {
              this.$Message.success('上架成功');

              this.query(this.pageData.page, this.pageData.size, this.queryDataForm);

              this.topicIds = [];
              this.selectArrData = [];
            }
          })
          .catch(error => {
            this.$Message.error('上架失败');
          })
    },

    // 提交修改的正确率
    handleAccuracySave() {
      console.log('this.accuracyForm====>', this.accuracyForm);
      let params = {
        id: this.currentAccuracyId,
        topicId: this.currentTopicId,
        doTopicUserNumber: parseInt(this.accuracyForm.valueUserCount),
        doTopicTotalTime: parseInt(this.accuracyForm.valueTotalTime),
        doTopicCount: parseInt(this.accuracyForm.valueCount),
        correct: parseInt(this.accuracyForm.valueCorrect),
        optionASelectivity: parseInt(this.accuracyForm.valueA),
        optionBSelectivity: parseInt(this.accuracyForm.valueB),
        optionCSelectivity: parseInt(this.accuracyForm.valueC),
        optionDSelectivity: parseInt(this.accuracyForm.valueD),
        optionESelectivity: parseInt(this.accuracyForm.valueE),
      };
      this.$axios.post('/api/topic/data/handle', params, {}).then(res => {
        this.$Message.success('保存成功');
        this.accuracyModal = false;
      }).catch(error => {
        console.log('error ===>', error);
      })
    },

    // 关闭正确率的弹框
    handleAccuracyCancel(name) {
      this.$refs[name].resetFields();
      this.accuracyModal = false;

      this.accuracyForm.valueA = null;
      this.accuracyForm.valueB = null;
      this.accuracyForm.valueC = null;
      this.accuracyForm.valueD = null;
      this.accuracyForm.valueE = null;
      this.accuracyForm.valueUserCount = null;
      this.accuracyForm.valueTotalTime = null;
      this.accuracyForm.valueCount = null;
      this.accuracyForm.valueCorrect = null;

      this.valueAccuracyA = null;
      this.valueAccuracyB = null;
      this.valueAccuracyC = null;
      this.valueAccuracyD = null;
      this.valueAccuracyE = null;
    },

    // 关键词搜索
    search() {
      this.handleChangeQueryData(1)
    },
    // 科目搜索
    selectSubjectChange(param) {
      console.log('param====>', param);
      this.queryDataForm.subject = param;
      this.source = `${param.toLowerCase()}Source`;
      this.changeExaminationSite = `${param.toLowerCase()}ExaminationSite`;
      this.queryDataForm.topicSource = null;
      this.queryDataForm.examinationSite = null;

      this.handleChangeQueryData(1);

    },
    // 考点搜索
    selectExaminationSiteChange(param) {

      this.examinationSite = []
      this.examinationSite = param;
      this.queryDataForm.examinationSite = param.toString();
      this.handleChangeQueryData(1);
    },
    // 来源搜索
    selectSourceChange(param) {
      this.queryDataForm.topicSource = param;
      this.handleChangeQueryData(1);
    },
    // 难度搜索
    selectTopicDifficultyChange(param) {
      this.queryDataForm.difficulty = param;
      this.handleChangeQueryData(1);
    },

    // 重置
    reset() {
      this.changeExaminationSite = null;
      this.examinationSite = [];
      this.queryDataForm = {
        keywords: null,
        subject: null,
        examinationSite: null,
        topicSource: null,
        difficulty: null,
      }
      this.handleChangeQueryData(1);
    },

    // 列表选择
    onSelectionChangeTable(selection) {
      let selectArrData = this.$refs.selection.getSelection();
      this.selectArrData = selectArrData;

      let selectStdId = [];
      selectArrData.forEach(item => {
        selectStdId.push(item.id)
      })
      this.topicIds = selectStdId;


      this.selectArr = selection
    },

    // 排序
    onSortChangeTable() {
      this.handleChangeQueryData(this.pageData.page);
    },

    /*分页相关函数*/
    pageOnChange(pageNumber) {
      this.pageData.page = pageNumber
      this.handleChangeQueryData(pageNumber)
    },
    pageSizeChange(pageSize) {
      this.pageData.size = pageSize;
      this.handleChangeQueryData(1);
    },

    // 判断科目subject是否为Q和V，列表参数相应改变
    handleChangeQueryData(page) {
      let subject = this.queryDataForm.subject;
      let requestVo = {
        type: subject === 'Verbal' ? 1 : 2,
        keywords: this.queryDataForm.keywords,
        topicSource: this.queryDataForm.topicSource,
        difficulty: this.queryDataForm.difficulty,
        examinationSite: this.queryDataForm.examinationSite,
      };
      this.query(page, this.pageData.size, subject === 'Verbal' || subject === 'Quant' ? requestVo : this.queryDataForm);

    },
  },
  mounted() {

    let page = this.$storage.get('TopicList', 'page') ? this.$storage.get('TopicList', 'page') : this.pageData.page;
    let queryDataForm = this.$storage.get('TopicList', 'queryDataForm') ? this.$storage.get('TopicList', 'queryDataForm') : this.queryDataForm;
    this.queryDataForm = queryDataForm;

    let subject = null;
    if (queryDataForm) {
      if (queryDataForm.subject) {
        subject = queryDataForm.subject
      } else if (queryDataForm.type) {
        if (queryDataForm.type === 1) {
          subject = 'Verbal'
        } else if (queryDataForm.type === 2) {
          subject = 'Quant'
        }
      }
    }
    this.queryDataForm.subject = subject;
    console.log('mounted ====>queryDataForm =====>', this.queryDataForm);
    console.log('mounted ====>subject =====>', this.queryDataForm.subject);
    this.handleChangeQueryData(page);
    this.queryTopicTypeData();
    this.$storage.remove('TopicList', 'page')
    this.$storage.remove('TopicList', 'queryDataForm')

  }
}
</script>

<style lang="css" scoped>
.books-list-top {
  display: flex;
  justify-content: space-between;
}

.topicListTable {
  overflow: initial;
}

.publishModal {
  .ivu-poptip {
    width: 100%;
  }
}

.publishModal .content {
  margin-left: 40px;
}

.publishModal .content p {
  margin-bottom: 20px;
}

.topic-content{
  position: relative;
  padding: 5px 10px 5px;
}
.topic-content .topic-title{
  text-align: left;
  white-space: pre-wrap; /* 只对中文起作用，强制换行。 */
  word-wrap: break-word; /* 只对英文起作用，以单词作为换行依据。 */
  line-height: 1.6;
  text-align: justify; /* css英文语句的两端对齐： */
  font-family: FontAwesome;
}
.topic-content .topic-title >>> div {
  white-space: normal!important;
}
.topic-content .topic-title >>> div p {
  margin: 20px 0;
}
.topic-content .topic-title >>> table.table-sorter{
  font-family: FontAwesome;
}
.topic-content .topic-title >>> table.table-sorter thead tr th{
  position: relative;
  text-align: center;
}
.topic-content .topic-title >>> table.table-sorter thead tr th:before{
  position: absolute;
  right: 5px;
}
.topic-content .topic-title >>> table.table-sorter thead tr th.ascending:before{
  font-family: FontAwesome;
  content: "\f0de";
}
.topic-content .topic-title >>> table.table-sorter thead tr th.descending:before{
  font-family: FontAwesome;
  content: "\f0dd";
}
.topic-content .topic-title >>> table.table-sorter tbody tr td {
  text-align: center!important;
}

.topic-content .topic-title >>> table.question-table{
  line-height: 1!important;
}

.topic-content .topic-title >>> table.question-table thead tr td{
  text-align: center;
}
.topic-content .topic-title >>> table.question-table td{
  height: auto;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  /*border-left: 1px solid #D2D2D2;*/
  /*border-top: 1px solid #D2D2D2;*/
}
.topic-content .topic-title >>> .vertical-choice{
  display: flex;
  justify-content: center;
}
.topic-content .topic-title >>> .vertical-choice:before{
  font-family: FontAwesome;
  background: #f6f6f6;
  border: 1px solid #d2d2d2;
  color: #353535;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
  padding: 4px;
}
.topic-content .topic-title >>> table .choice-content,
.topic-content .topic-title >>> ul.ul-options li div.choice-content
{
  margin-left: 8px;
  white-space: pre-wrap;
}
.topic-content .topic-title >>> ul.ul-options{
  white-space: nowrap;
}
.topic-content .topic-title >>> ul.ul-options li {
  width: 100%;
  display: block;
  min-height: 10px;
  list-style: disc outside;
  margin-top: 10px;
  margin-bottom: 10px;
  zoom: 1;
  margin-left: 0;
}
.topic-content .topic-title >>> ul.ul-options li:before,
.topic-content .topic-title >>> ul.ul-options li:after{
  display: table;
  content: "";
}
.topic-content .topic-title >>> ul.ul-options li:after{
  clear: both;
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice,
.topic-content .topic-title >>> ul.ul-options li div.li-choice
{
  background: #f6f6f6;;
  border: 1px solid #d2d2d2;;
  color: #353535;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 10px 4px 0;
}

.topic-content .topic-title >>> .vertical-choice.correct:before{
  background-color: var(--green-20);
  content: "\f058";
  border: 1px solid var(--green-30);
  color: var(--green-60);
}
.topic-content .topic-title >>> .vertical-choice.incorrect:before{
  background: var(--red-20);
  content: "\f057";
  border: 1px solid var(--red-30);
  color: var(--red-70);
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.correct,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.correct{
  background-color: var(--green-20);
  border: 1px solid var(--green-30);
  color: var(--green-60);
}

.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.incorrect,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.incorrect{
  background: var(--red-20);
  border: 1px solid var(--red-30);
  color: var(--red);
}
.topic-content .topic-title >>> .vertical-choice.corrected:before,
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.corrected:before,
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.correct:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.corrected:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.correct:before{
  content: "\f058";
  color: var(--green-60);
}
.topic-content .topic-title >>> .vertical-choice.incorrect:before,
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice.incorrect:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice.incorrect:before{
  content: "\f057";
  color: var(--red-70);
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice:first-of-type,
.topic-content .topic-title >>> ul.ul-options li div.li-choice:first-of-type{
  margin-left: 0;
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice:before,
.topic-content .topic-title >>> ul.ul-options li div.li-choice:before
{
  font-size: 16px;
  font-family: FontAwesome;
  padding: 0 10px;
}
.topic-content .topic-title >>> ul.ul-options li div.li-multi-choice,
.topic-content .topic-title >>> ul.ul-options li div.li-choice,
.topic-content .topic-title >>> ul.ul-options li div.choice-content
{
  min-height: 10px;
  margin: 8px 8px 0 8px;
  display: inline-block;
  vertical-align: top;
  float: left;
}
.topic-content .topic-title >>> ul.ul-options li div.choice-content{
  min-width: 65%;
  max-width: 72%;
}
.topic-content .topic-title >>> ul.ul-options li div.choice-content.correct{
  color: var(--green);
}
.topic-content .topic-title >>> ul.ul-options li div.choice-content.incorrect{
  color: var(--red);
}
.topic-content .topic-title >>> ul.ul-options li div.li-accuracy-choice{
  background-color: var(--red);
  color: #333;
  text-align: center;
  width: 32px;
  height: 18px;
  margin-top: 14px;
  border-radius: 4px;
  line-height: 18px;
  display: inline-block;
  vertical-align: top;
  float: left;
  font-size: 14px!important;
}
.topic-content .topic-title >>> div.inline-result {
  display: inline;
  border: 1px solid #9e9e9e;
  border-radius: 3px;
  padding: 2px 10px 2px 4px;
  white-space: nowrap;
  line-height: 2em;
}
.topic-content .topic-title >>> div.inline-result+div.inline-result {
  margin-left: 3px;
}
.topic-content .topic-title >>> div.inline-result.corrected {
  background: #f6f6f6;
  border: 1px solid #d2d2d2;
  color: #353535;
}
.topic-content .topic-title >>> div.inline-result.correct {
  background: var(--green-10);
  border: 1px solid var(--green-20);
  color: var(--green-60);
}
.topic-content .topic-title >>> div.inline-result.correct:before {
  content: "\f058";
  font-family: FontAwesome;
}
.topic-content .topic-title >>> div.inline-result.incorrect {
  background: #f8d6d7;
  border: 1px solid #f1adae;
  color: #DC3235;
}
.topic-content .topic-title >>> ul.ul-options li div.li-choice.incorrect:before,
.topic-content .topic-title >>> div.inline-result.incorrect:before {
  content: "\f057";
}
.topic-content .topic-title >>> div.inline-result:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "";
  margin-right: 8px;
}
.topic-content .topic-title >>> ul.ul-options li div.li-choice.corrected:before,
.topic-content .topic-title >>> div.inline-result.corrected:before {
  content: "\f05d";
  color: var(--green-60);
}
.topic-content .topic-title >>> div.inline-result:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "";
  margin-right: 8px;
}

/* 多材料题目 */
.topic-content .topic-title >>> .tabs-group{

}
.topic-content .topic-title >>> .tabs-group .tabs-btn{
  border-radius: 3px;
  margin-left: 5px;
  padding: 5px 20px;
  font-size: 13px;
  background: none;
  color: #333;
  border: 1px solid #ddd;
}
.topic-content .topic-title >>> .tabs-group .tabs-btn.active{
  background: #909090;
}
.topic-content .topic-title >>> .tabs-content{
  white-space: normal;
}
.topic-content .topic-title >>> .content-tab{
  background: #f7f7f7;
  padding: 20px;
  color: #3c3737 !important;
  display: none;
}
.topic-content .topic-title >>> .content-tab.active{
  display: block;
}
.topic-content .topic-title >>> .tabs-content .content-tab ul {
  margin: 20px 0;
}
.topic-content .topic-title >>> .tabs-content .content-tab ul li {
  margin-left: 30px;
}


.topic-content .options{
  text-align: left;
  padding-top: 20px;
  padding-bottom: 10px;
  word-break: break-all;
  line-height: 1.6;
}
</style>

<style>
.topicListTable .ivu-poptip {
  display: block;
}

.topicListTable .ivu-poptip-rel {
  display: block;
}

.topic-content .topic-title-special p{
  display: flex;
  align-items: center;
}
</style>
