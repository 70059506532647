let storage = {
    // 存储数据
    set(name, param, value) {
        let key = name + '-' + param;
        localStorage.setItem(key, JSON.stringify(value));
    },
    // 取出数据
    get(name, param) {
        let key = name + '-' + param;
        return JSON.parse(localStorage.getItem(key));
    },
    // 移除数据
    remove(name, param) {
        let key = name + '-' + param;
        localStorage.removeItem(key);
    }
}

export default storage;