<template>
<!-- 学员详情 -->
  <main>
    <div class="student-self-detail-info">
      <div class="title">
        <Divider type="vertical" style="" />
        基础信息
      </div>
      <div class="avator" style="display: flex;">
        <img src="../../../../static/image/avatar_default.png" alt="头像">
        <div class="name-sex">
          <span style="font-weight: 700;">{{ stdDetail.nickName }}</span>
          <p>{{ stdDetail.sex ? stdDetail.sex === 0 ? '女' : '男' : '未知' }}</p>
        </div>
      </div>
      <div class="student-self-detail-info-base">
        <p>
          <span>手机号：</span>
          <span>{{ stdDetail.phone }}</span>
        </p>
        <p>
          <span>目标分数：</span>
          <span>{{ stdDetail.targetScores }}</span>
        </p>
        <p>
          <span>目标考试时间：</span>
          <span>{{ stdDetail.testTime ? $moment(stdDetail.testTime).format('YYYY-MM-DD') : null }}</span>
        </p>
        <p>
          <span>备注：</span>
          <span>{{ stdDetail.remarks }}</span>
        </p>
<!--        <p>-->
<!--          <span>等级：</span>-->
<!--          <span>青铜</span>-->
<!--        </p>-->
      </div>
    </div>

    <div class="student-self-detail-label">
      <div class="title">
        <Divider type="vertical" style="" />
        标签
      </div>
      <div class="label-list">
        <span v-for="item in stdDetail.labelVoList" >{{ item.labelName }}</span>
      </div>
    </div>

    <div class="student-self-detail-goback">
      <Button type="primary" ghost @click="goBack">返回</Button>
    </div>

  </main>
</template>

<script>
export default {

  name: "StudentDetail",
  data() {
    return {
      // 学员id
      id: this.$storage.get('StudentDetail', 'id'),
      showLabelArr: ['标签1', '标签2', '标签3',],
      stdDetail: {},
    }
  },
  methods: {
    queryStdDetail(id) {


      let url = '/api/student/details/' + id;

      this.$axios.post(url, {}, {})
      .then(res => {
        if (res.code === '10000') {
          this.stdDetail = res.body;
        }
      })
      .catch(error => {
        console.log(error)
      })

    },
    goBack() {

      localStorage.setItem('isRemove', JSON.stringify(true));
      this.$router.push({ path:`/student/self/StudentInfo` });
    },
  },
  mounted() {
    let id = this.id;
    this.queryStdDetail(id);
  },

  destroyed() {
    this.$storage.remove('StudentDetail', 'id');
  }
}
</script>

<style scoped>
 .title{
   display: flex;
   align-items: center;
   font-size: 16px;
   color: #282828;
   font-weight: 700;
   margin-top: 20px;
   margin-bottom: 20px;
 }

 .avator{
   margin-left: 20px;
 }

 .avator img{
   width: 80px;
   height: 80px;
   border-radius: 50%;
   margin-right: 20px;
 }
 .avator .name-sex{
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
 .student-self-detail-info-base{
   display: flex;
   flex-wrap: wrap;
   align-content: flex-start;
   margin: 20px 0 20px 20px;
 }
 .student-self-detail-info-base p{
   color: #808695;
   width: 300px;
   margin: 20px 0 0;
 }
 .student-self-detail-info-base p span:nth-child(2){
   color: #17233d;
 }

 .student-self-detail-label{
   padding: 10px 0 20px 0;
 }
 .student-self-detail-label .label-list{
   display: flex;
   align-items: center;
   margin-left: 20px;
 }
 .student-self-detail-label .label-list span{
   border: 1px solid #dcdee2;
   border-radius: 4px;
   height: 32px;
   line-height: 32px;
   padding: 0 15px;
   transition: border .2s ease-in-out;
   font-size: 14px;
   margin-right: 10px;
 }

 .student-self-detail-goback{
   margin-top: 10px;
 }



</style>